<template lang="pug">
  include /mixins
  +b.card-wrap
    ui-loader(v-if='stateLoader')
    +b.card(v-if='arrayOrders && arrayOrders.length && !infoAboutOrderAfterPay.created_at_repr')
      +b.wrap-navigation-product.--without-margin_2.--ml-left
        button-back(
          :link='lang === "eng" ? "/catalog/" : "/fr/catalog/"'
        )
      +b.wrap-title-blog
        +b.H1.ds-caption.--variant_uppercase.--size_3xl.--weight_regular {{ _('Cart')}}
      +b.orders
        +b.order-title
          +b.g-row.--justify_end.--space_none
            +b.g-cell.--align_center.g-cols.g-cols--2
              +b.av_space_pl--5-fhd
                +b.fix-padding
                  +b.wrap-price-card
                    +e.H3.price.ds-caption.--size_sm.--demi-bold.--appearance_center {{ _('Quantity')}}
            +b.g-cell.g-cols.g-cols--4-2lg.g-cols--4-xl
              +b.g-row.--justify_between.--space_none
                +b.g-cell.--align_center.g-cols--3
                  +b.wrap-price-card
                    +e.SPAN.price.ds-caption.--size_sm.--demi-bold.--appearance_center {{ _('Unit price')}}
                +b.g-cell.--align_center.g-cols--4.g-cols--2-xl
                  +b.wrap-price-card.--padding
                    +e.SPAN.price.ds-caption.--size_sm.--demi-bold.--appearance_center {{ _('Tax')}}
                //- +b.g-cell.--align_center.g-cols--3
                //-   +b.wrap-price-card
                //-     +e.SPAN.price.ds-caption.--size_sm.--demi-bold.--appearance_center {{ _('Estimated GST/HST')}}
                +b.g-cell.--align_center
                  +b.wrap-price-card
                    +e.SPAN.price.ds-caption.--size_sm.--demi-bold.--appearance_center {{ _('Total')}}
        // ---------------------- adapt ord.
        +b.order-wrap-adaptiver
          +b.order(v-for='(order,index) in arrayOrders' :key='index')
            +b.count-and-removing
              +b.g-row.--justify_between.--space_none
                +b.g-cell.g-cols
                  +b.delete-product-in-card
                    +e.SPAN.text.ds-caption.--hover.--pointer.--size_2md(@click='deleteProduct(order.id, order.count)') &times;
                +b.g-cell.g-cols
                  row
                    cell(align='center')
                      +e.H3.price.ds-caption.--size_sm.--demi-bold.--appearance_center {{ _('Quantity')}}
                    cell
                      +b.count-products-in-card
                        +b.COUNT-CARD(
                          @activatePreloader='launchPreloader'
                          :max-quantity='order.product_variant_quantity'
                          :quantity='order.count'
                          :id='order.id'
                          @getOrders='getInfoAboutCard()'
                          :key="`order_${index}_${order.id}`",
                        )
            +b.g-row.--justify_between.--space_none
              +b.g-cell.g-cols--5
                +b.info-products-in-card
                  +e.A.text(:href='order.link')
                    +e.IMG.img(:src='order.img')
              +b.g-cell.g-cols--6
                +b.g-row.--appearance_column.--align_start-xl.--space_none.max-height
                  +b.g-cell.g-cols
                    +b.del-margins
                      +e.A.text(:href='order.link')
                        +e.H3.title.ds-caption.--demi-bold.--size_3xs.--size_xs-sm.--size_sm-2sm.--hover.--pointer.adaptive-size {{order.title}}
                        +e.H3.title.ds-caption.--demi-bold.--size_3xs.--hover.--pointer.adaptive-size(style='margin-top: 4px')
                          +e.SPAN.text.ds-caption.--color_light.--size_4xs.--size_2xs-sm {{ _('Vendor code: ')}}
                          +e.SPAN.text.ds-caption.--color_light.--size_4xs.--size_2xs-sm {{order.code}}
                  +b.g-cell.g-cols
                    +b.del-margins
                      +e.title(v-for='item in order.main_group_attrs.group_attributes')
                        +e.SPAN.title(v-for='it,inx in item.attrs')
                          +e.SPAN.title(v-for='el in it.choices')
                            +e.SPAN.title.ds-caption.--size_3xs.--size_sm-2sm.--size_xs-sm.--appearance_center.--demi-bold {{el.title}}
                            +e.SPAN.title.ds-caption.--size_3xs.--size_sm-2sm.--size_xs-sm.--appearance_center.--demi-bold(v-if='inx !== item.attrs.length-1') &nbsp;|&nbsp;
            +b.information-about-order
              +b.g-row.--flex_end-2sm.--space_none
                +b.g-cell.g-cols--6
                  +b.del-margins
                    +e.SPAN.title.ds-caption.--size_3xs.--size_xs-sm.--appearance_center.--demi-bold {{order.available_quantity}}
                    +e.SPAN.title.ds-caption.--size_3xs.--size_sm-xl.--appearance_center(:style='{color: "red"}')(v-if='order.product_notification') {{order.product_notification}}
              +b.g-row.--flex_end-2sm.g-row--space_none
                +b.g-cell.g-cols--12.g-cols--6-2sm
                  +b.g-row
                    +b.g-cell.g-cols--12
                      row(:justify='["between"]')
                        cell
                          +b.wrap-price-card
                            +e.SPAN.price.ds-caption.--size_sm.--size_sm-2sm.--demi-bold.--appearance_center {{ _('Unit price')}}
                        cell
                          +b.ds-caption.--appearance_center
                            +e.SPAN.price.ds-caption.--hover.--pointer.--size_3xs.--size_sm-2sm.--size_xs-sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.prices.discounted_price }}
                    +b.g-cell.g-cols--12
                      row(:justify='["between"]')
                        cell
                          +b.wrap-price-card
                            +e.SPAN.price.ds-caption.--size_sm.--size_sm-2sm.--demi-bold.--appearance_center {{ _('Tax')}}
                        cell
                          +b.ds-caption.--appearance_center
                            +e.SPAN.price.ds-caption.--hover.--pointer.--size_3xs.--size_xs-sm.--size_sm-2sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.prices.sum_tax_price }}
                    //- +b.g-cell.g-cols--12
                    //-   row(:justify='["between"]')
                    //-     cell
                    //-       +b.wrap-price-card
                    //-         +e.SPAN.price.ds-caption.--size_sm.--size_sm-2sm.--demi-bold.--appearance_center {{ _('Estimated GST/HST')}}
                    //-     cell
                    //-       +b.ds-caption.--appearance_center
                    //-         +e.SPAN.price.ds-caption.--hover.--pointer.--size_3xs.--size_xs-sm.--size_sm-2sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.prices.federal_tax_price }}
                    +b.g-cell.g-cols--12
                      row(:justify='["between"]')
                        cell
                          +b.wrap-price-card
                            +e.SPAN.price.ds-caption.--size_sm.--demi-bold.--size_sm-2sm.--appearance_center {{ _('Total')}}
                        cell
                          +b.ds-caption.--appearance_center
                            +e.SPAN.price.ds-caption.--hover.--pointer.--size_3xs.--size_xs-sm.--size_sm-2sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.total_price }}
            +b.av_space_mt--5(v-if='arrayOrders.length-1 !== index')
              +b.block-line-gray.--second
              +b.block-line-gray.--second
            +b.av_space_mt--5(v-else)
              +b.block-line-gray(style='background: black').--second
        // ---------------------- 960px ord.

        +b.order-wrap
          +b.order(v-for='(order,index) in arrayOrders' :key='index')
            +b.g-row.--justify_between.--space_none
              +b.g-cell.g-cols
                +b.g-row.--justify_around.--space_none
                  +b.g-cell.--align_center.g-cols
                    +b.av_space_mr--5.relative
                      +b.form-count.relative
                        +b.error-for-button(v-if='error') {{error}}
                      +b.delete-product-in-card
                        +e.SPAN.text.ds-caption.--hover.--pointer.--size_2md(@click='deleteProduct(order.id, order.count)') &times;
                  +b.g-cell.g-cols
                    +b.info-products-in-card
                      +e.A.text(:href='order.link')
                        +e.IMG.img(:src='order.img')
              +b.g-cell.g-cols.g-cols--2-2lg.g-cols--2-mac.g-cols--2-fhd
                +b.g-row.--appearance_column.--align_center.--align_start-xl.--space_none.max-height
                  +b.g-cell.g-cols
                    +b.av_space_mt--5.del-margins
                      +e.A.text(:href='order.link')
                        +e.H3.title.ds-caption.--demi-bold.--size_3xs.--size_sm-xl.--hover.--pointer.adaptive-size {{order.title}}
                        +e.H3.title.ds-caption.--demi-bold.--size_3xs.--hover.--pointer.adaptive-size(style='margin-top: 4px')
                          +e.SPAN.text.ds-caption.--color_light.--size_4xs.--size_2xs-xl {{ _('Vendor code: ')}}
                          +e.SPAN.text.ds-caption.--color_light.--size_4xs.--size_2xs-xl {{order.code}}
                  +b.g-cell.g-cols
                    +b.av_space_mt--5.del-margins
                      +e.H3.title(v-for='item in order.main_group_attrs.group_attributes')
                        +e.SPAN.title(v-for='it,inx in item.attrs')
                          +e.SPAN.title(v-for='el in it.choices')
                            +e.SPAN.title.ds-caption.--size_3xs.--size_sm-xl.--appearance_center {{el.title}}
                            +e.SPAN.title.ds-caption.--size_3xs.--size_sm-xl.--appearance_center(v-if='inx !== item.attrs.length-1') &nbsp;|&nbsp;
              +b.g-cell.--align_center.g-cols
                +b.available-products-in-card
                  +b.av_space_mt--5.del-margins
                    +e.SPAN.title.ds-caption.--size_3xs.--size_sm-xl.--appearance_center {{order.available_quantity}}
                    br
                    +e.SPAN.title.ds-caption.--size_3xs.--size_sm-xl.--appearance_center(:style='{color: "red"}')(v-if='order.product_notification') {{order.product_notification}}
              +b.g-cell.--align_center.g-cols
                +b.av_space_mt--5.del-margins
                  +b.ds-caption.--appearance_center
                    +e.H5.title-price.ds-caption.--hover.--pointer.--size_sm.--demi-bold {{ _('Unit price')}}
                  +b.count-products-in-card
                    +b.COUNT-CARD(
                      @activatePreloader='launchPreloader'
                      :max-quantity='order.product_variant_quantity'
                      :quantity='order.count'
                      :id='order.id'
                      :current-quantity='order.product_variant_quantity'
                      @getOrders='getInfoAboutCard()'
                      :key="`order_${index}_${order.id}`",
                    )
              +b.g-cell.--align_center.g-cols.g-cols--4-2lg.g-cols--4-xl
                +b.prices
                  +b.g-row.--justify_between.--space_none
                    +b.g-cell.--align_center.g-cols
                      +b.ds-caption.--appearance_center
                        +e.SPAN.price.ds-caption.--hover.--pointer.--size-sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.prices.discounted_price }}
                    +b.g-cell.--align_center.g-cols
                      +b.ds-caption.--appearance_center
                        +e.SPAN.price.ds-caption.--hover.--pointer.--size-sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.prices.sum_tax_price }}
                    //- +b.g-cell.--align_center.g-cols
                      +b.ds-caption.--appearance_center
                        +e.SPAN.price.ds-caption.--hover.--pointer.--size-sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.prices.federal_tax_price }}
                    +b.g-cell.--align_center.g-cols
                      +b.ds-caption.--appearance_center
                        +e.SPAN.price.ds-caption.--hover.--pointer.--size-sm.--demi-bold.--variant_uppercase {{ _('ca$')}} {{ order.total_price }}
              +b.g-cell.g-cols--12(v-if='arrayOrders.length-1 !== index')
                +b.av_space_mt--5
                  +b.block-line-gray.--second
                  +b.block-line-gray.--second
              +b.g-cell.g-cols--12(v-else)
                +b.av_space_mt--5
                  +b.block-line-gray(style='background: black').--second
        +b.total-price
          +b.g-row.--justify_center.--justify_end-md
            +b.g-cell.g-cols
              +b.g-row.--space_none
                +b.g-cell.g-cols
                  +b.av_space_mr--3.av_space_mr--9--sm
                    +e.title.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_right {{ _('Sub-total') }}
                    +b.av_space_mt--7
                      +e.title.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_right {{ _('Tax') }}
                    +b.av_space_mt--7
                      +e.title.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_right {{ _('Total') }}
            +b.g-cell.g-cols
              +b.g-row.--space_none
                +b.g-cell.g-cols
                  +b.av_space_mr--3.av_space_ml--9-sm
                    +e.price.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_left {{ _('ca$') }} {{finalPrice}}
                    +b.av_space_mt--7
                      +e.price.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_left {{ _('ca$') }} {{finalTaxPrice}}
                    +b.av_space_mt--7
                      +e.price.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_left {{ _('ca$') }} {{fullPrice}}
        +b.payment
          +b.av_space_mt--10
            +b.av_space_mb--5
              +e.H3.title.ds-caption.--variant_uppercase.--size_2md.--demi-bold {{ _('Shipping address')}}
          +b.ds-caption.--appearance_uppercase(
            style='color: red'
            v-if='nonFieldErrors'
          ) {{nonFieldErrors}}
          +b.block-type-payment(
            v-if='item.value !== "now"'
            v-for='item in payment.choices'
          )
            +b.g-row.--justify_between
              +b.g-cell.g-cols--12
                +b.g-row
                  +b.g-cell.--align_center.g-cols
                    //- +b.radio-payment.relative
                    //-   +e.element.checkmark-input.INPUT(
                    //-     type='radio'
                    //-     :value='item.value',
                    //-     name='category'
                    //-     :input-label='item.value',
                    //-     :id='item.value'
                    //-     :checked='typePayment'
                    //-   )
                    //-   +e.LABEL.filter-label.ds-caption.--pointer.--hover.--size_sm.checkmark(
                    //-     :for='item.value'
                    //-     @click='saveState(item.value, payment.default)'
                    //-   )
                  +b.g-cell.g-cols--12
                    +b.information-about-payment
                      //- +e.LABEL(
                      //-   :for='item.value'
                      //-   @click='saveState(item.value, payment.default)'
                      //- )
                        //- +e.H3.title.ds-caption.--demi-bold.--size_sm {{item.display_name}}
                      //- +e.P.title.ds-caption.--size_sm {{item.description}}
                      +e.SPAN.title.ds-caption.--size_sm {{_('After you place the order, we review it, apply promos and discounts, and email you the confirmation within 24 hours. Once the order is confirmed , we ship it next day and email the invoice to you. We accept payments by e-transfer at ')}}
                      +e.A.title.ds-caption.--size_sm(href='mailto: hello@aspirevision.com') hello@aspirevision.com
                      +e.SPAN.title.ds-caption.--size_sm {{ _(',by cheque, and by credit card. Please make sure to pay your invoice before the due date. 3% late payment fee acrrues monthly on unpaid balance. Regular prices apply if the payment is not received on the due date. You have ten calendar days to report any issues with the product. Warranty replacement is provided for paid products.') }}
              +b.g-cell.--align_center.g-cols(v-if='item.value === "now"')
                +b.av_space_pl--5.av_space_pl--0-md
                  +b.total-payment
                    +e.SPAN.decoration.ds-caption.--demi-bold Total payment
                    +e.SPAN.text.ds-caption.--demi-bold CA$ {{onlinePrice}}
        +b.g-row.--justify_center.--justify_end-md.--space_none
          +b.g-cell.g-cols
            +b.av_space_mt--5
              +b.av_space_mt--10
                +b.BUTTON.av_button--main.--size-fourth.ds-caption.--variant_uppercase.--color_white(@click.prevent='openShippingConfirmation') {{ _('place order')}}
    div(
      v-else-if='infoAboutOrderAfterPay.created_at_repr'
    )
      div {{infoAboutOrderAfterPay.created_at_repr}}
      //- orderLK(
      //-   @loader='stateLoader = false'
      //-   :dataOrder='infoAboutOrderAfterPay'

      //- )
      //- +b.ROUTER-LINK.ds-caption.--size_sm.--appearance_underline-blue.--color_blue.--pointer.--hover(
      //-   :to="{name: link, params:{ id: infoAboutOrderAfterPay.id }}"
      //- ) link
    +b.card-empty(v-else)
      +b.H1.ds-caption.--variant_uppercase.--size_3xl.--weight_regular(v-if='!stateLoader') {{ _('You cart is empty')}}
</template>

<script>
import {
  buyProduct,
  getCard,
  getCardChange,
  getContext,
  sendOrder,
  // getOrders,
} from '@api/communication.service'
// import { debounce } from 'vue-debounce'
import { mapActions } from 'vuex'

import FormConfirmOrderShippingFee from '../Forms/FormConfirmOrderShippingFee.vue';
import {
  // MY_ORDER,
  MY_ORDERS,
} from '../Routes/routes'

export default {
  data() {
    return {
      order: {
        method_order: '',
      },
      stateLoader: true,
      lang: window.language,
      infoAboutOrderAfterPay: [],
      idOrder: 0,
      error: null,
      nonFieldErrors: '',
      typePayment: '',
      payment: [],
      arrayOrders: [],
      finalPrice: 0,
      finalTaxPrice: 0,
      onlinePrice: 0,
      fullPrice: 0,
      minOrderForFreeShipping: 350,
      dataProduct: {
        entities: [{
          element: {
            type: 'products.productvariant',
            id: 0,
          },
          parameters: {},
          quantity: 0,
        }],
      },
    }
  },
  mounted() {
    this.$emit('state', false)
    this.getInfoAboutCard()
    this.saveState()
    // this.stateLoader = false
  },
  methods: {
    ...mapActions('cartQuantity', [
      'getQuantityOfCart',
    ]),
    launchPreloader(state) {
      this.stateLoader = state
    },
    payOrder() {
      this.infoAboutOrderAfterPay = []
      this.arrayOrders = []
    },
    openShippingConfirmation() {
      if (parseFloat(this.fullPrice) < this.minOrderForFreeShipping) {
        this.$modal.show(FormConfirmOrderShippingFee,
          {
            confirmFunc: this.makeOrder,
          },
          {
            height: 'auto',
            minWidth: 300,
            adaptive: true,
          });
      } else {
        this.makeOrder();
      }
    },

    makeOrder() {
      this.stateLoader = true
      this.order.method_order = this.typePayment
      // this.$router.push({ name: MY_ORDERS })
      return sendOrder.execute({}, this.order)
        .then(res => {
          this.$router.push({ name: MY_ORDERS })
          this.getInfoAboutCardChange()
          // this.$emit('link', true)
          this.idOrder = res.data.item.order_id
          // getOrders.execute({ id: this.idOrder }).then(result => {
          //   this.infoAboutOrderAfterPay = result.data.item
          //   this.getQuantityOfCart()
          //   this.$router.push({ name: MY_ORDER, params: { id: this.infoAboutOrderAfterPay.id } })
          //   this.stateLoader = false
          // })
        })
        .catch(res => {
          const error = res.json()
          error.then(e => {
            this.stateLoader = false
            this.nonFieldErrors = e.errors[0].state.non_field_errors.message
          })
        })
    },
    saveState(type) {
      if ('undefined' === typeof type) {
        this.typePayment = 'later'
      } else {
        this.typePayment = type
      }
    },
    getInfoAboutCard() {
      this.stateLoader = true
      getContext.execute({})
        .then(result => {
          this.payment = result.common.method_order_types
          this.getQuantityOfCart()
        })
      getCard.execute({}).then(res => {
        this.arrayOrders = res.data.products
        this.finalPrice = res.data.final_price
        this.fullPrice = res.data.full_price
        this.finalTaxPrice = res.data.final_tax_price
        this.onlinePrice = res.data.online_full_price
        this.stateLoader = false
      })
    },
    getInfoAboutCardChange() {
      this.stateLoader = true
      getContext.execute({})
        .then(result => {
          this.payment = result.common.method_order_types
          this.getQuantityOfCart()
        })
      getCardChange.execute({}).then(res => {
        this.arrayOrders = res.data.products
        this.finalPrice = res.data.final_price
        this.fullPrice = res.data.full_price
        this.finalTaxPrice = res.data.final_tax_price
        this.onlinePrice = res.data.online_full_price
        this.stateLoader = false
      })
    },
    deleteProduct(id, count) {
      this.stateLoader = true
      this.dataProduct.entities[0].element.id = id
      this.dataProduct.entities[0].quantity = -count
      buyProduct.execute({}, this.dataProduct)
        .then(() => {
          this.getInfoAboutCard()
        })
        .catch(res => {
          this.stateLoader = false
          const error = res.json()
          error.then(er => {
            this.error = er.errors[0].state[0].message
            setTimeout(() => {
              this.error = null
            }, 3000)
          })
        })
    },
  },
}
</script>
