<template lang="pug">
  include /mixins
  +b.d-control-input.--appearance_flexible
    d-control-input(
      v-model='checked'
      :name='filter.label'
      label='caption'
      :id='`${filter.label}`'
      :input-label='filter.title',
      @input='submit'
    )
</template>
<script>
import {
  isEmpty,
} from '@aspectus/vue-utils'

export default {
  name: 'SimpleInput',
  props: ['value', 'filter', 'available'],
  data() {
    return {
      checked: '',
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },
  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },
  methods: {
    submit() {
      let val = [this.checked]
      if (!val || !val.length) {
        val = null
      }
      this.$emit('input', val)
    },
    normalizeTo(value) {
      if (!value) return ''
      const checked = this.filter.values
        .filter(el => value.props.label === el.props.label)
        .map(el => el.props.label)
      return checked
    },
    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      const checked = value[0]
      return checked
    },
  },
}
</script>
