<template>
  <row appearance="spaced" :space="['md', 'none-sm']" class="control-stack">
    <cell cols="12 7-sm">
      <control-checker-select
        class="control-stack__item control-stack__item--position_first-sm"
        :value="element"
        @input="handleElementChange"
        trackBy="id"
        label="caption"
        :options="options"
        :placeholder="$options.NBSP"
        :searchable="false"
        :size="size"
        v-bind="$attrs"
      />
    </cell>
    <cell cols="auto">
      <row space="none">
        <cell cols="auto">
          <control-input
            class="control-stack__item control-stack__item--position_inner-sm control-stack__item--position_first"
            v-model="search"
            :size="size"
            :placeholder="placeholder || filter.caption"
          />
        </cell>
        <cell cols="narrow">
          <control-button
            class="control-stack__item control-stack__item--position_last"
            @click="submit"
            :size="size"
            :disabled="disabled"
          >
            <icon name="plus" />
          </control-button>
        </cell>
      </row>
    </cell>
  </row>
</template>

<script>

import { NBSP } from '../const'

export default {
  name: 'filter-combined-input-widget',
  props: ['value', 'filter', 'available', 'multiple', 'placeholder', 'size'],
  NBSP,

  data() {
    return {
      element: {},
      search: '',
    }
  },

  watch: {
    options: {
      immediate: true,
      handler(options) {
        const eid = this.element && this.element.id
        const value = options.find(({ id }) => id === eid)

        if (value && value.id) {
          return
        }

        this.element = value || options[0] || undefined
      },
    },
  },

  computed: {
    disabled() {
      return !this.search || !(this.element && this.element.id)
    },
    options() {
      const available = this.available &&
        this.available.props && this.available.props.id || []

      return this.filter.props.items
        .filter(({ id }) => available.includes(id))
    },
  },

  methods: {
    handleElementChange(value) {
      this.element = value || this.options[0]
    },
    submit() {
      if (this.disabled) {
        return
      }

      this.$emit('input', (this.value || []).concat({
        element: this.element, search: this.search,
      }))
      this.search = ''
    },
  },
}

</script>
