<template lang="pug">
  include /mixins
  div
    div(
      appearance="spaced"
    )
      slot(
        name="prepend",
        cols="12"
      )
      portal(to='seller-info')
        ui-slider.relative.seller-categories(
          :options='options'
        )
          template(#prev='')
            +b.controls--variant_tiny.controls-left.mdi.mdi-chevron-left.SPAN
          template(#next='')
            +b.controls--variant_tiny.controls-right.mdi.mdi-chevron-right.SPAN
          template(#slide)
            +b.category-slim-card(
              v-for='category in categories'
            )
              +e.title.A(
                :href='category.props.url'
              ) {{ category.props.title }}
</template>

<script>
import UiMixin from '@app/Filters/mixins/UiMixin'

import { getCategoriesByShopResource } from '@api/catalog.service'

export default {
  mixins: [UiMixin],
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'label',
  ],
  data() {
    return {
      search: '',
      search_filter: {
        label: 'search',
        title: this._('Поиск'),
        id: 'search_id',
      },
      categories: [],
      options: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        preload: true,
        focusOnSelect: false,
        autoplay: false,
        arrows: true,
        loop: false,
        fade: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 1599,
            settings: {
              slidesToShow: 8,
            },
          },
        ],
      },
    }
  },
  created() {
    this.getCategories()
  },
  methods: {
    async getCategories() {
      const {
        data: { items },
      } = await getCategoriesByShopResource.execute({ shop: this.label })
      this.categories = items
      this.categories.push(...items)
    },
  },
}
</script>
