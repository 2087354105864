<template lang="pug">
  include /mixins
  +b.el-accordion
    row(
      :style="{cursor: 'pointer'}"
      :justify="'between'"
      @click='active'
    )
      cell(cols="10")
        +b.P.ds-caption.--size-sm.--variant_uppercase.--bold {{ _('Personal Cabinet')}}
      cell(cols="1")
        +b.I.icon-open(
          :class='{"arrow-is-active": isExpand}'
        )
      cell(cols='12')
    vue-accordion(
      :expanded='isExpand'
    )
      +b.link-menu-profile.ds-caption(v-for='item in menus')
        +b.SPAN.av_space_mr--2
          +e.IMG(
            :src='item.src'
          )
        +b.A.link-menu-profile.ds-caption.--size-sm.--variant_uppercase(:href='item.link') {{item.label}}
      +b.av_space_mt--5
        +b.block-line-gray.--second
        +b.block-line-gray.--second
      +b.av_space_mt--5
        logout-button
</template>
<script>
import VueAccordion from '@ztrehagem/vue-accordion'

export default {
  components: {
    VueAccordion,
  },
  props: {
    language: String,
  },
  data() {
    return {
      isExpand: true,
      menus: [
        {
          src: '/static/img/orders.png',
          label: this._('My orders'),
          link: `${window.location.origin}${this.language}profile/orders/`,
        },
        {
          src: '/static/img/profile-data.png',
          label: this._('Profile data'),
          link: `${window.location.origin}${this.language}profile/personal-info/`,
        },
        {
          src: '/static/img/empl.png',
          label: this._('Employees'),
          link: `${window.location.origin}${this.language}profile/employees/`,
        },
      ],
    }
  },
  methods: {
    active() {
      this.isExpand = !this.isExpand
    },
  },
}
</script>
