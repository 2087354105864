<template lang="pug">
  include /mixins
  +b.block-wrap
    +b.g-row.--justify_center.--justify_between-xl
      +b.g-cell.g-cols--12.g-cols.g-cols--5-xl.g-cols--4-fhd
        +b.FORM-FIND-RETAILER(
          @subm='getRetailers'
          city=''
        )
        +b.SHOW-RETAILERS(
          v-if='retailersInfo'
          :id='idMarker'
          :key='count'
          :array='retailersInfo'
          @choseRetailer='getIdRetailer'
        )
      +b.g-cell.g-cols--12.g-cols--7-xl.g-cols--8-fhd
        +b.MAP-RETAILERS(
          @chooseMarker='sendIdMarkers'
          :numberRetailer='idRetailer'
          :array='retailersInfo'
          :coordinates='coordinates'
        )
</template>

<script>

export default {
  props: ['city'],
  name: 'FindRetailer',
  data() {
    return {
      idMarker: 0,
      idRetailer: 0,
      count: 0,
      retailersInfo: [],
      coordinates: null,
    }
  },
  mounted() {

  },
  methods: {
    sendIdMarkers(id) {
      this.idMarker = id.id
    },
    getRetailers(data) {
      console.log(data)
      this.retailersInfo = data
      if (data.coordinates) {
        this.coordinates = data.coordinates
      }
    },
    getIdRetailer(id) {
      this.idRetailer = id.id
    },
  },
}
</script>
