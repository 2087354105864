<template lang="pug">
  include /mixins
  +b.form-in-img
    +b.block-close
      //- +b.g-row.--justify_end
      //-   +b.g-cell.g-cols
          //- +e.SPAN.text.ds-caption.--size_sm.--letter-space-small Close
    +b.ds-caption.--appearance_uppercase.--letter-space-small.--size_3xl.--weight-regular {{ _('log in')}}
    +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
    +b.VALIDATION-OBSERVER(
      @submit.prevent='send',
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      +b.D-CONTROL-INPUT.follow-input(v-for='field in fields'
        v-bem:d-control-input="{'variant': 'black'}"
        :key='field.name'
        :name='field.name'
        :rules='field.rules'
        :type='field.type || "text"'
        :placeholder='field.placeholder'
        v-model='form[field.name]')
      +b.av_space_mt--9
        +b.activate-forgot-password
          +b.g-row.--justify_end
            +b.g-cell.g-cols
              modal-trigger(
                name='khkh',
                url='Forms/FormResetPassword',
              )
                template(#default='{ open }')
                  +e.SPAN.text.ds-caption.--color_blue.--appearance_right.--pointer.--hover(
                    @click.prevent='open'
                  ) {{ _('Forgot password?') }}
      +b.wrap-button-form.--second
        +b.g-row.--justify_center
          +b.g-cell.g-cols--12
            +b.BUTTON.av_button--main.--size-second.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('Sign in') }}
      +b.av_space_mt--9
        +b.g-row.--justify_center
          +b.g-cell.g-cols
            +e.SPAN.text.ds-caption.--bold {{ _('Not a partner yet?') }}
              modal-trigger(
                name='khkh',
                url='Forms/FormSubmitModal',
                modal='true'
              )
                template(#default='{ open }')
                  +e.A.tex.ds-caption.--color_blue.--pointer.--hover.--regular(@click.prevent='open') {{ _('Click here') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  login,
} from '@api/communication.service'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          name: 'username',
          placeholder: 'Email *',
          rules: 'required',
        },
        {
          name: 'password',
          placeholder: this._('Input your password'),
          rules: 'required',
          type: 'password',
        },
      ],
      form: {
        username: '',
        password: '',
        // recaptcha: 'dummy_value',
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.$refs.validator.reset()
      return login.execute({}, data).then(res => {
        if (200 === res.code) {
          window.location.pathname = res.data.item.redirect
        }
      })
    },
  },
}
</script>
