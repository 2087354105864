// import {
//   createReceiver,
//   baseResource,
// } from '@resource/resource'
import {
  createReceiver,
  // baseResourcey,
  getResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

import { prefixLanguage } from '@utils/urls';

import {
  friendlyUrlGenerator,
} from '../../../packages/friendlyUrlGenerator/friendluUrlGenerator'

const ONLY_ATTRIBUTES_URL = prefixAPI('attributes/filter-list/')
export const onlyAttributesResource = createReceiver(ONLY_ATTRIBUTES_URL)

const PRICES_FILTERS_URL = prefixAPI('store/price-filter/list/')
export const pricesResource = createReceiver(PRICES_FILTERS_URL)

const BRANDS_ATTRIBUTES_URL = prefixAPI('store/brand-filters/')
export const brandsAttributesResource = createReceiver(BRANDS_ATTRIBUTES_URL)

const CATEGORIES_URL = prefixAPI('store/categories/list/')
export const categoriesResource = createReceiver(CATEGORIES_URL)

const BRANDS_URL = prefixAPI('store/brands/list/')
export const brandsResource = createReceiver(BRANDS_URL)

const ATTRIBUTE_VALUES_URL = prefixAPI('/store/catalog-attributes/search/{id}/{?search,category}')

const ATTRIBUTES_URL_PREFIX = prefixAPI('/catalog/filter/')
const ATTRIBUTES_URL_POSTFIX = '/retrieve'
const AVAILABLE_ATTRIBUTES_URL_PREFIX = prefixAPI('/catalog/available-attr-values/')

// const AVAILABLE_ATTRIBUTES_URL_PREFIX = '/catalog/filter/'
const AVAILABLE_ATTRIBUTES_URL_POSTFIX = '/retrieve'

const order = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

export const availableAttributesResource =
  // createReceiver(AVAILABLE_ATTRIBUTES_URL)
  friendlyUrlGenerator([AVAILABLE_ATTRIBUTES_URL_PREFIX, AVAILABLE_ATTRIBUTES_URL_POSTFIX], getResource, order)

export const attributesResource =
/* eslint-disable no-trailing-spaces */
  friendlyUrlGenerator([ATTRIBUTES_URL_PREFIX, ATTRIBUTES_URL_POSTFIX], getResource, order)

// const orderSeller = {
//   path: [
//     'prefix',
//     'label',
//     'category',
//     'postfix',
//     'filters',
//   ],
//   query: [
//     'pagination_offset',
//   ],
// }

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/catalog/')
const FILTERS_LIST_URL_POSTFIX = '/filters/'
export const productsListUrl =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], getResource, order)

const FILTERS_LIST_URL_PREFIX_SECOND = '/pro/catalog/'
export const profileProductsListUrl =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX_SECOND, FILTERS_LIST_URL_POSTFIX], getResource, order)

// const SHOP_FILTERS_LIST_URL_PREFIX = '/shop/'
// export const shopProductListUrl =
//   friendlyUrlGenerator([SHOP_FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], getResource, orderSeller)

const SHOP_CATERORIES_URL = prefixAPI('/store/categories/list/{?shop}')
export const getCategoriesByShopResource = createReceiver(SHOP_CATERORIES_URL)

export const getAttributeValuesResource = createReceiver(ATTRIBUTE_VALUES_URL)
