<template lang="pug">
  include /mixins
  +b.form-sumbit-modal
    +b.g-row.--justify_end
      +b.ds-caption.--hover.--pointer.--size_2md(@click='closeForm') &#10006;
    +b.FORM-SUBMIT(@subm='closeModal()')
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    closeForm() {
      this.$emit('close')
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
