<template lang="pug">
  include /mixins
  +b.button-logout(@click='logout()')
    +e.SPAN.t.av_space_mr--2
      +e.IMG(src='/static/img/logout.png')
    +e.SPAN.title.ds-caption.--demi-bold.--size_2md--hover.--pointer {{ _('Logout') }}
</template>

<script>

import {
  logoutUser,
} from '@api/communication.service'

export default {
  // name: 'SearchForm',
  data() {
    return {}
  },
  methods: {
    logout() {
      return logoutUser.execute({}).then(res => {
        window.location.pathname = res.data.item.redirect
      })
    },
  },
}
</script>
