<template lang="pug">
  include /mixins
  +b.block
    +b.VUE-RENDERER(
      :result='items'
    )
    p {{this.type}}
</template>

<script>
// import { getProdcuts } from '@/js/services/getItems.service'

export default {
  props: {
    type: String,
  },
  data() {
    return {
      slug: '',
      items: [],
    }
  },
}
</script>
