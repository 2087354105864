<template lang="pug">
  include /mixins
  +b.block
    +b.error(v-if='error') {{ _('input correct price')}}
    +b.block(v-for='filt in filter')
      +b.block
        +b.av_space_mb--6
          row(
            justify='start'
          )
            cell
              d-control-input(
                v-bem:d-control-input="{'variant': 'price'}"
                v-model='minValue'
                type='number'
                :min='filt.min_value'
                :max='filt.max_value'
                @input='debounceActionMinimalPrice($event, filt.min_value, filt.max_value)'
              )
            cell(
              align='center'
            )
              +e.ds-caption.--size_sm -
            cell
              d-control-input(
                v-bem:d-control-input="{'variant': 'price'}"
                v-model='maxValue'
                :min='filt.min_value'
                :max='filt.max_value'
                type='number'
                @input='debounceActionMaximalPrice($event, filt.min_value, filt.max_value)'
              )
        +b.av_space_mb--8
          +b.av_space_pl--2
            vue-range-slider(
              :height='5'
              v-model='value'
              :min='filt.min_value'
              :max='filt.max_value'
              @drag-end='debounceActionRange($event)'
              @change='debounceActionRange($event)'
            )
              template(#process='{ start, end, style, index }')
                .vue-slider-process.custom-process(
                  :style="[style]"
                )
              template(#dot='')
                +b.custom-dot
</template>

<script>
import VueRangeSlider from 'vue-slider-component'
import debounce from 'lodash.debounce'

export default {
  components: {
    VueRangeSlider,
  },
  name: 'RangeWithVariantsWidget',
  props: ['values', 'filter', 'available', 'parameters'],
  data() {
    return {
      // checked: {},
      debounceActionRange: null,
      debounceActionMinimalPrice: null,
      debounceActionMaximalPrice: null,
      currency: window.currency,
      show: false,
      value: [0, 0],
      helpArray: [],
      minValue: 0,
      maxValue: 0,
      error: false,
    }
  },
  watch: {
    values(nval) {
      if (!nval) {
        this.normalize(nval)
      }
    },
  },
  mounted() {
    const delayRange = 1000
    const delayInput = 3000
    this.debounceActionMinimalPrice = debounce((e, min, max) => {
      this.minimalPricesubmit(Number(e), min, max)
    }, delayInput)
    this.debounceActionMaximalPrice = debounce((e, min, max) => {
      this.maximalPricesubmit(Number(e), min, max)
    }, delayInput)
    this.debounceActionRange = debounce(() => {
      this.setInputPrice()
    }, delayRange)
  },
  created() {
    this.setPrice()
  },
  methods: {
    minimalPricesubmit(priceMin, min, max) {
      if (priceMin >= min && priceMin <= max) {
        this.$set(this.value, 0, priceMin)
      } else {
        this.minValue = min
      }
      this.submit()
    },
    maximalPricesubmit(priceMax, min, max) {
      if (priceMax >= min && priceMax <= max) {
        this.$set(this.value, 1, priceMax)
      } else {
        this.maxValue = max
      }
      this.submit()
    },
    setInputPrice() {
      /* eslint-disable */
      this.minValue = this.value[0]
      this.maxValue = this.value[1]
      this.submit()
    },
    setPrice() {
      if (this.filter) {
        this.minValue = this.filter[0].min_value
        this.maxValue = this.filter[0].max_value
        this.value[0] = this.filter[0].min_value
        this.value[1] = this.filter[0].max_value
      }
      if ('undefined' !== typeof this.values) {
        const arrayForStrings = this.values[0].split('-')
        this.minValue = Number(arrayForStrings[0])
        this.maxValue = Number(arrayForStrings[1])
        this.helpArray[0] = Number(arrayForStrings[0])
        this.helpArray[1] = Number(arrayForStrings[1])
      }
      this.normalize(this.helpArray)
    },
    submit() {
      const stroke = `${this.value[0]}-${this.value[1]}`
      this.$emit('input', stroke)    
    },
    /* eslint-disable */
    normalize(value) {
      if (!value || (value && !value[0] && !value[1])) {
        this.$set(this.value, 0, this.filter[0].min_value)
        this.$set(this.value, 1, this.filter[0].max_value)
        this.minValue = this.filter[0].min_value
        this.maxValue = this.filter[0].max_value
      } else {
        this.$set(this.value, 0, value[0])
        this.$set(this.value, 1, value[1])
        this.minValue = value[0]
        this.maxValue = value[1]
      }
    },
  },
}
</script>
