<template>
  <component :is="layout">
    <user-personal :user='typeUser'/>
  </component>
</template>

<script>
import DefaultLayout from '../Components/Layouts/Default'
import UserPersonal from './Base'

export default {
  props: ['type-user'],
  name: 'Cabinet',
  components: {
    DefaultLayout,
    UserPersonal,
  },
  computed: {
    layout() {
      return 'default-layout'
    },
  },
}
</script>
