<template lang="pug">
  include /mixins
  +b.wrap-find-retailers
    +b.g-row.--justify_end
      +b.g-cell.g-cols
        +b.av_space_mb--5
          +e.t.ds-caption.--size_md.--hover.--pointer(@click='close') &#10006;
    +b.g-row.--justify_center.--justify_between-xl
      +b.g-cell.g-cols--12.find-cols-form
        +b.FORM-FIND-RETAILER(
          :key='place'
          @subm='getRetailers'
          :place='place'
        )
        +b.SHOW-RETAILERS(
          :id='idMarker'
          :array='retailersInfo'
          @choseRetailer='getIdRetailer'
        )
      +b.g-cell.g-cols--12.find-cols-map
        +b.MAP-RETAILERS(
          @chooseMarker='sendIdMarkers'
          :numberRetailer='idRetailer'
          :array='retailersInfo'
        )
</template>

<script>

export default {
  props: {
    info: {
      city: String,
    },
  },
  name: 'FindRetailerInForm',
  data() {
    return {
      idMarker: 0,
      idRetailer: 0,
      retailersInfo: [],
      place: '',
    }
  },
  mounted() {
    this.place = this.info.city
  },
  methods: {
    close() {
      this.$emit('close')
    },
    sendIdMarkers(id) {
      this.idMarker = id.id
    },
    getRetailers(data) {
      this.retailersInfo = data
    },
    getIdRetailer(id) {
      this.idRetailer = id.id
    },
  },
}
</script>
