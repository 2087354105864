<template lang="pug">
  include /mixins
  div
    +b.section--shadowed.SECTION
      gmap-map#map(
        :center='center',
        :zoom='zoom',
      )
        gmap-marker(
          :position="markerPosition",
          :clickable="false",
          :icon="markerImage"
        )
</template>

<script>
export default {
  name: 'MapsComponent',
  data() {
    return {
      markerImage: '/static/img/marker.png',
      markerPosition: { lat: 50.438175214249048, lng: 30.519872957267532 },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      center: { lat: 50.438175214249036, lng: 30.519872957267552 },
      zoom: 13,
      // options: {
      //   styles: mapStyle,
      //   fullscreenControl: false,
      //   streetViewControl: false,
      //   zoomControl: true,
      //   mapTypeControl: false,
      //   scaleControl: false,
      //   rotateControl: false,
      // },
      // markers: [],
      // infoWinOpen: false,
      // infoContent: {},
      // categories: [],
    }
  },

}
</script>
