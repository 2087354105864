let value = 0
// import debounce from 'lodash.debounce'
function scrollFunction() {
  const standart = document.getElementById('standart')
  const mainElement = document.getElementById('mainElementHeader')
  const stickyElement = document.getElementById('stickyElementHeader')
  const burger = document.getElementById('btnHeader')
  // const buttonGoToTop = document.getElementById('buttonGoToTop')
  const start = 150
  burger.addEventListener('click', () => {
    standart.classList.remove('header-is-moving')
    mainElement.classList.remove('main-element-in-header--isBlocked')
    stickyElement.classList.remove('sticky-element-in-header--isActive')
  })
  if (window.pageYOffset > start) {
    if (value > window.pageYOffset) {
      standart.classList.remove('header-is-moving')
      mainElement.classList.remove('main-element-in-header--isBlocked')
      stickyElement.classList.remove('sticky-element-in-header--isActive')
    } else {
      standart.classList.add('header-is-moving')
      mainElement.classList.add('main-element-in-header--isBlocked')
      stickyElement.classList.add('sticky-element-in-header--isActive')
    }
  } else {
    standart.classList.remove('header-is-moving')
    mainElement.classList.remove('main-element-in-header--isBlocked')
    stickyElement.classList.remove('sticky-element-in-header--isActive')
    // buttonGoToTop.classList.add('go-to-top-btn--disable')
  }
  value = window.pageYOffset
}

export const stickyHeader = () => {
  window.addEventListener('scroll', scrollFunction)
}
