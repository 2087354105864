<template lang="pug">
  include /mixins
  +b.form-in-cabinet.--change_password
    +b.av_space_mb--10
      +b.av_space_pl--8
        +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase(:style='{"opacity": 0.5}') {{ _('change password')}}
    +b.errors-in-change-password
      +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
    +b.VALIDATION-OBSERVER(
      @submit.prevent='send',
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      row(
          :appearance="'spaced'"
          :space="'none'"
          :justify="'around'"
        )
          cell(
            v-for='field in fields'
            :cols='field.cols'
            :key='field.name'
          )
            +b.av_space_pr--5-md
              +b.wrap-input-with-icon.relative
                +b.D-CONTROL-INPUT.follow-input(
                  v-bem:d-control-input="{'variant': 'black-second'}"
                  :key='field.name'
                  :name='field.name'
                  :rules='field.rules'
                  :type='field.type || "text"'
                  :placeholder='field.placeholder'
                  v-model='form[field.name]')
      +b.av_space_mt--9
        +b.wrap-button-form.--second
          +b.g-row.--justify_center.--justify_end-lg.--space_none
            +b.g-cell.g-cols
              +b.BUTTON.av_button--main.--size-fourth.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('Save new password') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  changePassword,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          cols: '12 4-md',
          name: 'old_password',
          placeholder: this._('Enter current password'),
          rules: 'required',
          type: 'password',
        },
        {
          cols: '12 4-md',
          name: 'new_password1',
          placeholder: this._('Enter new password'),
          rules: 'required',
          type: 'password',
        },
        {
          cols: '12 4-md',
          name: 'new_password2',
          placeholder: this._('Repeat your new password'),
          rules: 'required',
          type: 'password',
        },
      ],
      form: {
        old_password: '',
        new_password1: '',
        new_password2: '',
        // recaptcha: 'dummy_value',
      },
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.$refs.validator.reset()
      return changePassword.execute({}, data).then(res => {
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        AnswerMessage(message, this)
      })
    },
  },
}
</script>
