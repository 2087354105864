<template lang="pug">
  include /mixins
  +b.A.link.ds-caption.--variant_uppercase.--variant_strong.--appearance_center.--demi-bold.text(
      :href='link'
      :class='{"disabled": !quantity}'
    )
    +b.cart-in-header
      +b.row
        +b.ic.icon-cart()
        +b.ds-caption(v-if='quantity > 0') {{quantity}}
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    link: String,
  },
  data() {
    return {
      quantity: this.counter,
    }
  },
  computed: {
    ...mapState('cartQuantity', [
      'countCart',
    ]),
  },
  watch: {
    countCart: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.quantity = nval
      },
    },
  },
}
</script>
