import smoothscroll from 'smoothscroll-polyfill'

function onMenuLink(e) {
  smoothscroll.polyfill()
  const menuLink = e.target
  const gotoBlock = document.getElementById(menuLink.dataset.goto)
  const gotoBlockValue = gotoBlock.getBoundingClientRect().top - 100
  const scrollTop = window.pageYOffset
  window.scrollTo({
    top: gotoBlockValue + scrollTop - 120,
    behavior: 'smooth',
  })
  e.preventDefault()
}

export const scrollArchon = () => {
  const archons = [...document.getElementsByClassName('link-to-archon')]
  archons.forEach(item => {
    item.addEventListener('click', onMenuLink)
  })
}
