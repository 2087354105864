<template lang="pug">
  include /mixins
  +b.form-count.relative
    +b.av_button.--main.--size_second.--padding_top.ds-caption.--color_white.--appearance_center(
      :data-id='id'
      ref='buttonAddToCard'
      v-text='message'
      :class='{"block-button": currentQuantity < 1}'
    )(@click.prevent='executeAdd($event)')
    +e.SPAN.error(v-if='error') {{error}}
</template>

<script>
import { mapActions } from 'vuex'

import {
  buyProduct,
} from '@api/communication.service'

import {
  changeProduct,
} from '@api/getItems.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  props: ['id', 'quantity'],
  name: 'SearchForm',
  data() {
    return {
      currentQuantity: this.quantity,
      error: null,
      message: this._('add to cart'),
      arrayOrders: [],
      dataProduct: {
        entities: [{
          element: {
            type: 'products.productvariant',
            id: null,
          },
          parameters: {},
          quantity: 1,
        }],
      },
    }
  },
  methods: {
    ...mapActions('cartQuantity', [
      'getQuantityOfCart',
    ]),
    executeAdd() {
      this.dataProduct.entities[0].element.id = Number(this.$refs.buttonAddToCard.dataset.id)
      changeProduct.execute({ id: this.id }).then(({ data: { item } }) => {
        this.currentQuantity = item.quantity
        // quantityOfProductString.innerText = `${item.quantity_string}`
        // if (1 > this.currentQuantity) this.message = this._('out of stock')
        buyProduct.execute({}, this.dataProduct)
          .then(res => {
            this.getQuantityOfCart()
            const message = {
              message: {
                title: res.meta.message.header,
                text: res.meta.message.text,
              },
            }
            AnswerMessage(message, this)
          })
          .catch(res => {
            const error = res.json()
            error.then(e => {
              console.log(e)
              this.error = e.errors[0].state[0].message
              setTimeout(() => {
                this.error = null
              }, 4000)
            })
          })
      })
    },
  },
}
</script>
