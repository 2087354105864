<template lang="pug">
  include /mixins
  +b.wrap(:class='opacity ? "wrap-like-product" : "block"')
    +b.block-favourite(v-if='pageFavorites === -1')
      +e.SPAN.ic(
        ref='like'
        :data-like='state'
        :data-id='id'
        @click.prevent='addToLike($event)'
        :class='active ? "icon-like-active" : "icon-favourite"'
      )
    +b.block-favourite(v-else)
      +e.SPAN.ic(
        ref='like'
        :data-like='state'
        :data-id='id'
        @click.prevent='addToLike($event)'
        :class='active ? "icon-krestik" : "icon-favourite"'
      )
</template>

<script>

import { favouriteProduct } from '@api/communication.service'
// import { getLike } from '@/js/services/getItems.service'
// import { animationLikes } from '@/js/utils/animation-likes'
import { mapActions } from 'vuex'
import { eventBus } from '@/js/components/Helpers/eventBus'

export default {
  props: {
    id: {},
    opacity: {},
    state: {
      default: false,
    },
  },
  data() {
    return {
      pageFavorites: window.location.href.indexOf('favorites'),
      test: null,
      active: this.state,
      infoProduct: {
        id_product: Number(this.id),
        // id_product: this.state,
      },
    }
  },

  watch: {
    state: {
      handler(nval) {
        console.log(nval)
        this.active = nval
      },
    },
  },

  methods: {
    ...mapActions('likeQuantity', ['getQuantityOfLikes']),

    addToLike() {
      if (-1 !== this.pageFavorites && this.$refs.like.classList.contains('icon-krestik')) {
        this.$refs.like.closest('.product-item').style.opacity = '0'
      }
      const id = Number(this.$refs.like.dataset.id)
      this.infoProduct.id_product = id
      favouriteProduct.execute({}, this.infoProduct).then(() => {
        this.getQuantityOfLikes()
        this.active = !this.active
        eventBus.$emit('like:change')
      })
    },
  },
}
</script>
