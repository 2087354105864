import MessageModal from './MessageModal'
import UiSlider from './UiSlider'
import FindRetailer from './FindRetailer'
import ShowRetailers from './ShowRetailers'
import VacanciesAccordion from './VacanciesAccordion'
import ElementLike from './ElementLike'
import CountProduct from './CountProduct'
import ColorsProductDetail from './ColorsProductDetail'
import CharacteristicsProducts from './CharacteristicsProducts'
import UiClickOutside from './UiClickOutside'
import DetailInfoAboutProduct from './DetailInfoAboutProduct'
import CountCard from './CountCard'
import FIndeRetailerInForm from './FIndeRetailerInForm'
import SimilarProductsRender from './SimilarProductsRender'
import AddToCardButton from './AddToCardButton'
import Pagination from './Pagination'
import VideoRender from './VideoRender'
import UiLoader from './UiLoader'
import TestColorProducts from './TestColorProducts'
import AcceptCookie from './AcceptCookie'
import cartQuantity from './cartQuantity'
import UiAccordionTemplate from './UiAccordionTemplate'
import PersonalCabinetMenu from './PersonalCabinetMenu'
import LikeIcon from './LikeIcon'
import buttonBack from './buttonBack'
import elementLikeProductDetail from './elementLikeProductDetail'
import InstagramCards from './InstagramCards'
import MessageModalNew from './MessageModalNew'

export default function install(Vue) {
  Vue.component('AcceptCookie', AcceptCookie)
  Vue.component('UiLoader', UiLoader)
  Vue.component('VideoRender', VideoRender)
  Vue.component('Pagination', Pagination)
  Vue.component('AddToCardButton', AddToCardButton)
  Vue.component('DetailInfoAboutProduct', DetailInfoAboutProduct)
  Vue.component('MessageModal', MessageModal)
  Vue.component('FIndeRetailerInForm', FIndeRetailerInForm)
  Vue.component('UiSlider', UiSlider)
  Vue.component('FindRetailer', FindRetailer)
  Vue.component('ShowRetailers', ShowRetailers)
  Vue.component('VacanciesAccordion', VacanciesAccordion)
  Vue.component('ElementLike', ElementLike)
  Vue.component('CountProduct', CountProduct)
  Vue.component('ColorsProductDetail', ColorsProductDetail)
  Vue.component('CharacteristicsProducts', CharacteristicsProducts)
  Vue.component('UiClickOutside', UiClickOutside)
  Vue.component('CountCard', CountCard)
  Vue.component('SimilarProductsRender', SimilarProductsRender)
  Vue.component('TestColorProducts', TestColorProducts)
  Vue.component('cartQuantity', cartQuantity)
  Vue.component('UiAccordionTemplate', UiAccordionTemplate)
  Vue.component('PersonalCabinetMenu', PersonalCabinetMenu)
  Vue.component('LikeIcon', LikeIcon)
  Vue.component('buttonBack', buttonBack)
  Vue.component('elementLikeProductDetail', elementLikeProductDetail)
  Vue.component('InstagramCards', InstagramCards)
  Vue.component('MessageModalNew', MessageModalNew)
}
