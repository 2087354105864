<template lang='pug'>
  router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :kwargs='kwargs'
    :initial="initial"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    :disableUrlUpdate="disableUrlUpdate"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="productsResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      key='router_parameters_controller'
      :kwargs='kwargs'
      v-slot="paginatedProps"
    )
      controller(
        ref="controller"
        :attributesResource='attributesResource'
        :availableAttributesResource='availableAttributesResource'
        v-bind="paginatedProps",
        :label='label',
        :sortlist='sortlist||default_sort'
        :filterComponent='filterComponent'
        :state='state'
        :select='select'
        loader-key='loader_controller'
        pagination-key='pagination_controller'
      )
        slot
    portal-target(name='button-pagination')
</template>

<script>
import { mapMutations } from 'vuex'

import {
  friendlyUrlParser,
} from '@app/Filters/transformers'

import Controller from '@app/List/Controller'

export default {
  components: { Controller },
  props: {
    select: Boolean,
    state: Boolean,
    initial: {

    },
    baseUrl: {},
    productsResource: {},
    attributesResource: {},
    availableAttributesResource: {},
    label: {},
    sortlist: {
    },
    filterComponent: {},
    kwargs: {},
    disableUrlUpdate: {},
  },
  data() {
    return {
      default_sort: [
        {
          title: this._('By price down'),
          key: 'price-down',
        },
        {
          title: this._('By price up'),
          key: 'price-up',
        },
      ],
    }
  },
  methods: {
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        ...this.kwargs,
      }
    },
    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser()
      return {
        ...rest,
        filters: data,
        ...this.kwargs,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
