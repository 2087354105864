import urlLib from 'x-url';

export const DEFAULT_LANGUAGE = 'ru';

export function clean(url) {
  return url.replace(/\/+/img, '/');
}

export function getRelative(url = '') {
  if (!url.startsWith('http')) {
    return url;
  }

  const object = urlLib.parse(url);

  return object.path;
}

export function getAbsolute(url = '', { origin }) {
  return origin.replace(/\/$/img, '') + getRelative(url);
}

export function getPrefixed(url, prefix = null) {
  if (!prefix) {
    return url;
  }

  return clean(prefix + getRelative(url));
}

export function getLanguageCode(url = '') {
  const [lang] = getRelative(url).replace(/^[\/]+|[\/]+$/g, '').split('/', 1);

  if (!lang || 2 < lang.length) {
    return DEFAULT_LANGUAGE;
  }

  return lang;
}

export function getNonDefaultLanguage(language = '') {
  return DEFAULT_LANGUAGE !== language ? language : undefined;
}

export function removeLanguage(path = '') {
  const currentLanguageCode = getLanguageCode(path);

  if (currentLanguageCode === DEFAULT_LANGUAGE) {
    return path;
  }

  return path.slice(currentLanguageCode.length + 1);
}

export const CURRENT_LANGUAGE = (
  window &&
  window.location &&
  getLanguageCode(window.location.href) ||
  DEFAULT_LANGUAGE
)

export function prefixLanguage(url = '', {
  language = CURRENT_LANGUAGE,
} = {}) {
  const path = removeLanguage(getRelative(url));

  if (!language || language === DEFAULT_LANGUAGE) {
    return path;
  }

  return getPrefixed(path, `/${language}/`);
}
