<template lang='pug'>
  include /mixins
  +b.d-control-input--variant_select
    +e.element--fixed.SELECT(
      name='sorting',
      v-model='checked',
      :placeholder='_("Сортировать по")'
      id='sorting'
      @change="$emit('change', checked)",
    )
      template(
        v-for='val in values',
      )
        option.option-select(
          :key='val.slug'
          :value='val.slug'
        ) {{ val.title }}
</template>

<script>
export default {
  name: 'NativeSorting',
  props: {
    initValue: {},
    values: {},
  },
  data() {
    return {
      checked: this.initValue,
    }
  },
  watch: {
    initValue(nval) {
      this.checked = nval
    },
  },
}
</script>
