import UiMessageModal from '@app/UiElements/MessageModal'
import MessageModalNew from '@app/UiElements/MessageModalNew'

export const AnswerMessage = (meta, $this) => {
  $this.$modal.show(
    UiMessageModal,
    {
      meta,
    }, {
      height: 'auto',
      minWidth: 300,
      maxWidth: 370,
      adaptive: true,
    }
  )
  // if (meta.message && (meta.message.title || meta.message.text)) {
  // }
}

export const NewMessageModal = (meta, $this) => {
  $this.$modal.show(
    MessageModalNew,
    {
      meta,
    }, {
      height: 'auto',
      minWidth: 300,
      maxWidth: 370,
      adaptive: true,
      classes: 'vm--modal--lg',
    }
  )
  // if (meta.message && (meta.message.title || meta.message.text)) {
  // }
}
