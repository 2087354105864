<template lang="pug">
  include /mixins
  +b.VALIDATION-OBSERVER(
    @submit.prevent='send',
    ref="validator"
    v-slot="{ valid }",
    tag="form")
    +b.form-wrap.--second
      +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
      +b.D-CONTROL-INPUT.follow-input(v-for='field in fields'
        :key='field.name'
        :name='field.name'
        :rules='field.rules'
        :type='field.type || "text"'
        :placeholder='field.placeholder'
        v-model='form[field.name]')
    +b.wrap-button-form
      +b.g-row.--justify_center
        +b.g-cell.g-cols
          +b.BUTTON.av_button--main.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('subscribe') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  AnswerMessage,
} from '@utils/message-modal'

import {
  subscribe,
} from '@api/communication.service'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          name: 'email',
          placeholder: this._('Your email'),
        },
      ],
      form: {
        email: '',
        recaptcha: 'dummy_value',
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.$refs.validator.reset()
      return subscribe.execute({}, data).then(res => {
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        Object.entries(this.form).forEach(([key]) => {
          this.form[key] = ''
        })
        AnswerMessage(message, this)
      })
    },
  },
}
</script>
