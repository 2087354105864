<template lang='pug'>
  include /mixins
  div.filter(:class='{small: widthState}')
    portal(to='place-for-loader')
      ui-loader(v-if='loading')
    +b.g-row.relative
      +b.g-cell.g-cols--12.absolute-element
        +b.info-row
          +e.cell--2
            portal(to='plaсe-for-result')
              +e.SPAN.title #[strong {{ totalCount }}{{ _(" Results") }}]
            portal(to='place-for-search')
              sorting(
                v-if='filters && filters.item'
                :filters='filters'
                :values='sortlist'
                :value="prepared.order || [filters.item.filter_attrs.order.default_value]"
                @input="handleInputFilter({ ...value.filters, 'order': $event })"
              )
          +e.cell
            +e.select
      +b.g-cell.g-cols--12
        +e.content--no-pa
          filter-ui(
            :disabled="loading"
            :value="prepared"
            :available="availableMap"
            :filters="filters"
            :pagination='pagination'
            :prices='prices'
            :sortlist='sortlist'
            :show_all='show_all'
            @input="handleInputFilter"
            :label='label'
          )
</template>

<script>
import VueAccordion from '@ztrehagem/vue-accordion'
import ControllerMixin from '@app/Filters/mixins/ControllerMixin'
import { mapActions, mapState } from 'vuex'
import RadioWidget from '@app/Filters/Widgets/RadioWidget'

export default {
  components: {
    RadioWidget,
    VueAccordion,
  },
  props: ['state', 'select'],
  data() {
    return {
      widthState: true,
      isExpanded: true,
    }
  },
  computed: {
    ...mapState('catalog', [
      'chipsChangedFilters',
    ]),
  },
  /*
  * this watch need for render chips in catalog controller
  */
  watch: {
    // loading() {
    //   this.$body.dataset.blocked = this.loading
    // },
    state() {
      if ('undefined' !== typeof this.state) {
        this.widthState = this.state
      }
    },
    chipsChangedFilters: {
      deep: true,
      handler(nval) {
        this.handleInputFilter(nval)
      },
    },

    prepared: {
      deep: true,
      immediate: true,
      handler() {
        this.setChipsData({ filters: this.prepared, all: this.all })
      },
    },
    all: {
      deep: true,
      immediate: true,
      handler() {
        this.setChipsData({ filters: this.prepared, all: this.all })
      },
    },
  },
  methods: {
    ...mapActions('catalog', [
      'setChipsData',
    ]),
  },
  mixins: [ControllerMixin],
}

</script>
