<template lang="pug">
  include /mixins
  +b.color-block.row
    +b.row
      template.sadasd(
        v-for='(val, i) in filter'
      )
        +b.asd.relative(:class='{"block-filter": !isAvailable(val.id)}',)
          +b.d-control-input
            +b.element.checkbox-color.INPUT(
              type='checkbox'
              :value='val.slug'
              :name='val.slug'
              v-model='checked'
              :input-label='val.title',
              :id='val.slug'
              @change='submit()'
            )
            +b.wrap.border-label
              +b.LABEL.title.label-for-color.shape(
                :for='val.slug'
                :style="{background: `url(${val.texture})`}"
              )
          +b.hint-for-colors-catalog.absolute {{val.title}}
</template>
<script>
export default {
  name: 'ColorRenderer',
  props: ['value', 'filter', 'available', 'is-excluded', 'retard'],
  data() {
    return {
      checked: [],
      arrayForSlugs: [],
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },

  methods: {
    /* eslint-disable no-else-return */
    isAvailable(id) {
      if (this.available.item && -1 === this.available.item.available_filter_attr_values_ids.indexOf(id)) {
        return false
      } else {
        return true
      }
    },
    submit() {
      if (-1 === window.location.href.indexOf('profile')) {
        this.$emit('input', this.checked)
      } else {
        let val = this.checked
        if (!val || !val.length) {
          val = null
        }
        this.$emit('input', val)
      }
    },
    // notAvailable(id) {
    //   if (!this.available.attributes) return true
    //   const customFilter = this.available[this.filter.props.attribute.props.label]
    //   if (customFilter) {
    //     return customFilter.find(el => el === id)
    //   }
    //   // eslint-disable-next-line eqeqeq
    //   return this.available.attributes.find(el => el == id)
    // },
    normalize(value) {
      if (!value) return []
      const checked = this.filter
        .filter(el => value.find(v => v === el.slug)).map(el => el.slug)
      return checked
    },
  },
}
</script>
