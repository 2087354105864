<template lang='pug'>
  include /mixins
  div#content-parent
    infinite-template-results-collector(
      v-if='initialLimit'
      :pagination="pagination",
      ref='controller'
      :items="items",
    )
      template(#ssr='{ isSSR }')
        template(v-if="isSSR")
          div.row.row-for-help
            slot
      template(#default='{ items }')
        renderer(
          ref="renderer"
          :result='items',
        )
          slot
      template(slot="initialContent")
        slot
    portal(to='button-pagination')
      +b.info-about-pagination
        +b.g-row.--justify_center
          +b.g-cell.g-cols
            +b.av_space_mb--9
              +b.P.ds-caption.--appearance_center
                +e.SPAN.text.ds-caption.--demi-bold(v-if='pagination.limit + pagination.offset < pagination.total') {{ pagination.limit + pagination.offset }}
                +e.SPAN.text.ds-caption.--demi-bold(v-else) {{ pagination.total }}
                +e.SPAN.text.ds-caption &nbsp; {{ _('of')}} &nbsp;
                +e.SPAN.text.ds-caption {{ pagination.total }}
                +e.SPAN.text.ds-caption &nbsp; {{ _('products')}}
            +b.relativ(v-if='hasMore')
              +b.av_button.--border.ds-caption.--appearance_center(
                @click="handleScroll"
              ) {{ _('load more')}}
</template>

<script>
import Renderer from '../Helpers/Renderer'

export default {
  components: {
    Renderer,
  },
  props: ['loading', 'items', 'pagination'],
  computed: {
    hasMore() {
      // console.log(this.items)
      return this.pagination.offset + this.pagination.limit < this.pagination.total;
    },
  },
  data() {
    return {
      quantityOfItems: 0,
      isFirstRequest: true,
      initialLimit: this.pagination.limit,
      // initialLimit: JSON.parse(JSON.stringify(this.pagination.limit)),
    }
  },
  methods: {
    // handleScroll(isVisible) {
    //   if (this.loading || !isVisible) {
    //     return;
    //   }
    //   const offset = this.pagination.offset + this.pagination.limit;
    //   this.handlePaginate({ offset });
    // },
    // handlePaginate({ offset }) {
    //   if (this.loading) {
    //     return;
    //   }
    //   const init = 0;
    //   const { limit = init } = this.pagination;
    //   this.$emit('update:pagination', { limit, offset });
    // },
    handleScroll(isVisible) {
      const fakeCatalog = document.getElementById('fakeCatalogProducts')
      if (fakeCatalog) {
        fakeCatalog.style.display = 'none'
      }
      if (this.loading || !isVisible) {
        return;
      }
      const { limit, offset } = this.pagination

      let pageOffset = offset + limit;
      let pageLimit

      if (this.isFirstRequest) {
        pageLimit = limit + limit
        pageOffset = 0
      } else {
        pageLimit = this.initialLimit
      }
      this.handlePaginate({ limit: pageLimit, offset: pageOffset });
      this.isFirstRequest = false
    },
    handlePaginate({ limit, offset }) {
      if (this.loading) {
        return;
      }
      this.$emit('update:pagination', { limit, offset });
    },
  },
};

</script>
