<template lang="pug">
  include /mixins
  +b.UL.accordion-wrap(v-if='accordionsArray')
    +e.LI.item(v-for='item,index in accordionsArray')
      +e.LABEL.title.ds-caption.--size_2md.--letter-space-small.--demi-bold(:id='"label"+ index') {{item.title}}
        +e.INPUT.accordion(
          type='radio'
          :value='item.title'
          :id='index'
          @click='close($event, item.title), changeMargin(index, $event)'
          name='accordion'
          data-status='false'
        )
        +b.block-line-accordion
        +b.icon(:id='"icon"+ index')
        +e.DIV.content
          +e.SPAN.text.ds-caption.--line_height-second.--size_normal {{item.description}}
          +e.wrap.row
            modal-trigger(
              name='khkh',
              url='Forms/FormVacancies',
              :id='item'
            )
              template(#default='{ open }')
                +e.BUTTON.btn.av_button--main.--size-third.ds-caption.--variant_uppercase.--color_white(@click.prevent='open') {{ _('Send resume')}}
</template>

<script>

import {
  getAccordions,
} from '@api/getItems.service'

export default {
  name: 'SearchForm',
  data() {
    return {
      accordionsArray: [],
      count: 0,
      name: '',
      nameLabel: 0,
    }
  },
  mounted() {
    return getAccordions.execute({}).then(res => {
      this.accordionsArray = res.data.items
    })
  },
  methods: {
    changeMargin(name, event) {
      const ourElement = document.getElementById(`label${name}`);
      const ourIcon = document.getElementById(`icon${name}`);
      if (event.path[0].checked) {
        ourElement.style.marginTop = '50px'
        ourElement.style.display = 'block'
        ourIcon.style.top = '55px'
      } else {
        ourIcon.style.top = '5px'
        ourElement.style.marginTop = '0'
        ourElement.style.display = 'block'
      }
    },
    close(event, vacancies) {
      if (this.name === vacancies) {
        this.name = ''
        event.target.checked = false
      } else {
        this.name = vacancies
      }
    },
  },
}
</script>
