<template lang="pug">
  include /mixins
  div
    +b.VUE-RENDERER(
      v-if="isResponse"
      :result='items'
      @renderer-event="getCard($event)"
    )
    div(@click="changeColor($event)")
      slot(v-if="!isResponse")
</template>

<script>
import {
  createResource,
} from '@/js/resource/resource'

export default {
  name: 'product',
  data() {
    return {
      items: [],
      isResponse: false,
    }
  },

  methods: {
    changeColor(event) {
      if (event.path[0].classList.contains('block-color-product')) {
        const linkLabel = event.path[0].attributes.href.value
        this.getCard(linkLabel)
      }
    },
    getCard(link) {
      let fullLink = ''
      if (-1 !== window.location.href.indexOf('favorites')) {
        fullLink = window.location.origin + link
      } else {
        fullLink = window.location.origin + link.replace('product', 'product-card')
      }
      const getCurrentFavoriteProduct = createResource(fullLink)
      return getCurrentFavoriteProduct.execute().then(res => {
        this.items = res.data
        this.isResponse = true
      })
    },
  },
}
</script>
