<template lang="pug">
  include /mixins
  div.modal-lk
    +b.g-row.--justify_end
      +b.g-cell.g-cols
        +b.av_space_mb--5
          +e.H3.title.ds-caption.--size_2md.--hover.--pointer(@click='close') &#10006;
    +b.form-in-cabinet
      +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
      +b.VALIDATION-OBSERVER(
        @submit.prevent='send',
        ref="validator"
        v-slot="{ valid }",
        tag="form")
        row(
            appearance='spaced'
            space='xl'
            justify='around'
          )
            cell(
              v-for='field in fields'
              :cols='field.cols'
              :key='field.name'
            )
              +b.wrap-input-with-icon.relative
                +b.D-CONTROL-INPUT.follow-input(
                  v-bem:d-control-input="{'variant': 'black-second'}"
                  :key='field.name'
                  :name='field.name'
                  :rules='field.rules'
                  :type='field.type || "text"'
                  :placeholder='field.placeholder'
                  v-model='form[field.name]')
        +b.av_space_mt--9
          +b.wrap-button-form.--second
            +b.g-row.--justify_center.--justify_end-lg
              +b.g-cell.g-cols
                +b.BUTTON.av_button--main.--size-second.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('Add employee') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  addEmployee,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  props: {
    promise: Function,
  },
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          cols: '12',
          name: 'first_name',
          placeholder: this._('Enter first name'),
          rules: 'required',
          type: 'text',
        },
        {
          cols: '12',
          name: 'email',
          placeholder: this._('Enter email'),
          rules: 'required',
          type: 'text',
        },
      ],
      form: {
        first_name: '',
        email: '',
        // recaptcha: 'dummy_value',
      },
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.$refs.validator.reset()
      return addEmployee.execute({}, data).then(res => {
        this.promise(true)
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        this.$emit('close')
        AnswerMessage(message, this)
      })
    },
  },
}
</script>
