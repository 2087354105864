<template lang="pug">
  include /mixins
  +b.wrapper-for-modal-thx(v-if='meta')
    +e.element--offset_full
      +b.ds-caption--variant_primary.--appearance_center.H3 {{ meta.message.title }}
      +b.av_space_mt--1
        +b.ds-caption--variant_secondary.--size_xs.--appearance_center.P {{ meta.message.text }}
</template>
<script>

export default {
  name: 'UiMessageModal',

  props: ['meta', 'promise', 'timeout'],
  data() {
    return {
      redirectTime: 5000,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.promise !== undefined) {
        this.promise('true')
      }
      if (this.meta.location) {
        window.location.pathname = this.meta.location
      }
      this.$emit('close')
    }, this.redirectTime)
  },
}
</script>
