<template lang="pug">
  include /mixins
  +b.preloader-without-opacity
    +b.wrp.relative
      +b.sk-double-bounce
        +b.sk-child.sk-double-bounce-1
        +b.sk-child.sk-double-bounce-2
</template>

<script>
export default {
  name: 'UiLoader',
}
</script>
