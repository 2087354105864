import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import Vue from 'vue'

import './rules'

// import
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
