<template lang="pug">
  include /mixins
  +b.av_space_mt--7
    .relative.search-form
      +b.search-wrap.--fixed-width
        +e.ic.icon-Search
      +b.d-control-input.--appearance_flexible
        input.search-catalog(
          v-model='checked'
          autocomplete='nddddo'
          label='caption'
          type='text'
          @input='debounceAction()'
          :placeholder='labels.search'
        )
      +b.ds-caption.--hover.--pointer(
          v-if='checked && checked.length > 0'
          :style='{position: "absolute", right: "10px", top: "0"}'
          @click.prevent='handlerSubmit()'
        ) &#10006;
      +b.av_space_mt
        +b.block-line-gray.--second
        +b.block-line-gray.--second.--without_margin
</template>
<script>
import debounce from 'lodash.debounce'
// import {
//   isEmpty,
// } from '@aspectus/vue-utils'

export default {

  name: 'SimpleSearch',
  props: ['value', 'filter', 'available', 'parameters'],
  data() {
    return {
      debounceAction: null,
      checked: '',
      labels: {
        search: this._('Search'),
      },
    }
  },
  watch: {
    // value(nval) {
    //   this.checked = this.normalizeFrom(nval)
    // },
    parameters(nval) {
      console.log(nval);
      if (0 === nval.values.search && nval.values.search.length || 0 === Object.keys(nval.values).length) {
        this.checked = ''
      } else {
        this.checked = nval.values.search
      }
    },
  },
  mounted() {
    const delay = 2000
    this.debounceAction = debounce(() => {
      this.submit()
    }, delay)
  },
  methods: {
    handlerSubmit() {
      this.checked = ''
      this.submit()
    },
    submit() {
      const length = 2
      const zero = 0
      console.log(this.checked.length === zero)
      if (this.checked && this.checked.length >= length) {
        this.$emit('input', [this.checked])
        return
      }
      if (this.checked.length === zero) {
        this.$emit('input', [])
      }
    },
  },
}
</script>
