<template lang="pug">
  include /mixins
  +b.d-control-input.--appearance_flexible
    d-control-multiselect(
      :multiple='false',
      :options='filter.values'
      v-model='checked'
      :name='filter.label'
      label='caption'
      :id='`${filter.label}`'
      :input-label='filter.title',
      @input='submit'
    )
</template>
<script>
import {
  isEmpty,
} from '@aspectus/vue-utils'

export default {
  name: 'SimpleSelect',
  props: ['value', 'filter', 'available'],
  data() {
    return {
      checked: '',
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },
  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },
  methods: {
    submit() {
      this.$emit('input', this.normalizeTo(this.checked))
    },
    normalizeTo(value) {
      if (!value) return []
      const checked = this.filter.values
        .filter(el => value.props.label === el.props.label)
        .map(el => el.props.label)
      return checked
    },
    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      const checked = this.filter.values
        .find(el => value.find(v => v === el.props.label))
      return checked
    },
  },
}
</script>
