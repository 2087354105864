<template lang="pug">
  include /mixins
  div
    +b.g-row--appearance_spaced.--space_lg(v-if='kind !== "sizes"')
      template.sadasd(
        v-for='(val, i) in filter'
        )
        +b.g-cell.g-cols--12(
        :class='{"block-filter": !isAvailable(val.id)}'
        )
          //- +b.d-control-input
          +b.g-row.--justify_between
            +b.g-cell.g-cols--7.grow
              +b.LABEL.filter-label.ds-caption.--pointer.--hover.--size_sm.--weight-regular(
                :for='val.slug'
                v-if='title'
              ) {{ title }}
              +b.LABEL.filter-label.ds-caption.--pointer.--hover.--size_sm.--weight-regular(
                v-else
                :for='val.slug'
              ) {{ val.title }}
            +b.g-cell.g-cols.relative
              +e.element.checkmark-input.INPUT(
                type='checkbox'
                :value='val.slug',
                :name='val.slug'
                v-model='checked'
                :input-label='val.title',
                :id='val.slug'
                @change='submit()'
              ) {{val.id}}
              +e.LABEL.filter-label.ds-caption.--pointer.--hover.--size_sm.checkmark(
                :for='val.slug'
              )
    +b.row(v-else)
      template.sadasd(
        v-for='(val, i) in filter'
      )
        +b.asd.relative(
          :class='{"block-filter": !isAvailable(val.id)}'
        )
          +b.d-control-input
            +b.element.checkbox-color.INPUT(
              type='checkbox'
              :value='val.slug',
              :name='val.slug'
              v-model='checked'
              :input-label='val.title',
              :id='val.slug'
              @change='submit()'
            ) {{val.id}}
            +b.wrap.border-label
              +b.LABEL.title.label-for-color(
                :for='val.slug'
              )
                +b.SPAN.ds-caption.--bold.--size_3xs {{val.title}}
</template>
<script>
export default {
  name: 'CheckboxRenderer',
  props: ['value', 'filter', 'available', 'is-excluded', 'title', 'kind'],
  data() {
    return {
      checked: [],
      arrayForSlugs: [],
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },
  methods: {
    isAvailable(id) {
      /* eslint-disable no-else-return */
      if (this.available.item && -1 === this.available.item.available_filter_attr_values_ids.indexOf(id) && id) {
        return false
      } else {
        return true
      }
    },
    submit() {
      if (-1 === window.location.href.indexOf('profile')) {
        this.$emit('input', this.checked)
      } else {
        let val = this.checked
        if (!val || !val.length) {
          val = null
        }
        this.$emit('input', val)
      }
    },
    normalize(value) {
      if (!value) return []
      const checked = this.filter
        .filter(el => value.find(v => v === el.slug)).map(el => el.slug)
      return checked
    },
  },
}
</script>
