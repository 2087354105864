import MapsElements from './Maps'
import FormsElements from './Forms'
import ControlsComponents from './Controls'
import Catalog from './Catalog'
import TriggersElements from './Triggers'
import UiElements from './UiElements'
import PersonalCabinet from './LK'
import Helpers from './Helpers'
import ListComponents from './List'
import ComponentsRouting from './ComponentsRouting'
import Routing from './Routing'
import Resources from './Resources'

export function install(Vue) {
  Vue.use(ControlsComponents)
  Vue.use(TriggersElements)
  Vue.use(MapsElements)
  Vue.use(FormsElements)
  Vue.use(UiElements)
  Vue.use(Helpers)
  Vue.use(ListComponents)
  Vue.use(Catalog)
  Vue.use(PersonalCabinet)
  Vue.use(Routing)
  Vue.use(ComponentsRouting)
  Vue.use(Resources)
}

export default { install }
