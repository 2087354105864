<template lang="pug">
  include /mixins
  +b.block-wrap
    div
      gmap-map#mapRetailer(
        :center='center',
        :zoom='zoom',
        ref='gmap'
      )
        gmap-cluster(
          :styles='clusterOptions',
          :minimumClusterSize='cluster',
          :zoomOnClick='true'
        )
          +b.markers(v-for='marker, i in markers')
            gmap-marker(
              :key='i'
              :icon='infoWindowActive === marker.retailer ? markerCurrent : markerImage',
              :position="{lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude)}",
              @click='toggleInfoWindow(marker, marker.retailer)'
            )
        gmap-info-window(
          :options='infoOptions',
          :opened="infoWinOpen",
          @closeclick="infoWinOpen=false"
          :z-index='999',
          :position='{lat: parseFloat(infoContent.latitude), lng: parseFloat(infoContent.longitude)}'
        )
          +b.info-window
            +b.block {{infoContent.retailer_title}}
            +b.block {{infoContent.retailer_email}}
            +b.block {{infoContent.retailer_phones}}
            +b.block {{infoContent.address_one}}

  </template>

<script>

import GmapCluster from 'vue2-google-maps/dist/components/cluster'

// const clusterImg = '/static/img/cluster.png'

export default {
  props: [
    'array',
    'numberRetailer',
    'coordinates',
  ],
  components: {
    GmapCluster,
  },
  name: 'MapRetailers',
  data() {
    return {
      markers: null,
      infoWindowActive: -1,
      counter: 0,
      activeMap: 0,
      markerCurrent: '/static/img/markerActive.png',
      markerImage: '/static/img/marker.png',
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      center: { lat: 51.3522, lng: -110.4739 },
      zoom: 3,
      cluster: 3,
      clusterOptions: [{
        width: 60,
        height: 55,
        background: 'white',
        border: '2px solid black',
        borderRadius: '50%',
        fontWeight: 'bold',
        textSize: 16,
      }],
      infoWinOpen: false,
      infoContent: {},
    }
  },
  mounted() {
    console.log(this.array)
    if (this.array.all_retailers) {
      this.markers = this.array.all_retailers
    } else {
      console.log(this.array)
      this.markers = this.array.retailers
    }
  },
  methods: {
    toggleInfoWindow(marker, idx) {
      this.infoWinOpen = false
      this.infoWindowActive = idx
      this.infoContent = marker
      if (this.infoWindowActive === idx) {
        this.infoWinOpen = true
      } else {
        this.infoWinOpen = !this.infoWinOpen
        this.currentMidx = idx
      }
      this.$emit('chooseMarker', {
        id: marker.retailer,
      })
    },
  },
  watch: {
    numberRetailer(nval) {
      console.log(nval)
      this.array.retailers.forEach(element => {
        if (this.numberRetailer === element.retailer) {
          this.center.lat = Number(element.latitude)
          this.center.lng = Number(element.longitude)
          this.infoContent = element
        }
      });
      this.zoom = 10
      this.infoWinOpen = true
      this.infoWindowActive = this.numberRetailer
    },
    coordinates(nval) {
      if (nval) {
        this.zoom = 7
        this.center.lat = nval.lat
        this.center.lng = nval.lon
      }
      this.infoWinOpen = false
      if (0 === this.array.length) {
        this.infoWinOpen = true
      }
    },
    array(nval) {
      console.log(nval);
      if (nval.allRetailers) {
        this.markers = nval.allRetailers
      } else {
        this.markers = nval.retailers
      }
    },
  },
}
</script>
