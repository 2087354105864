<template lang="pug">
  include /mixins
  div
    +b.g-row.--justify_end
      +b.g-cell.g-cols
        +e.H3.title.ds-caption.--size_2md.--pointer.--hover(@click='close') &#10006;
    +b.av_space_pb--4
      +b.H3.ds-caption.--size-xl.--appearance_center {{ _('Shipping fee 12$+Tax will apply to orders under 350$ once your order is confirmed.') }}
      +b.av_space_mt--4
        +b.g-row.--justify_center.--justify_end-sm
          +b.g-cell.g-cols
            +b.BUTTON.av_button--main(@click.prevent='confirm()')
              +e.IMG.img(src='/static/svg/shipping.svg' style='height: 30;')
</template>
<script>
import FormMixin from '@app/Forms/FormMixin'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  props: {
    confirmFunc: {
      type: Function,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.confirmFunc();
      this.close()
    },
    /* eslint-disable no-empty-pattern */
  },
}
</script>
