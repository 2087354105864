import Base from './Base'
import PersonalArea from './PersonalArea'
import SideBar from './SideBar'
import PersonalInfo from './PersonalInfo'

export default function install(Vue) {
  Vue.component('Base', Base)
  Vue.component('PersonalArea', PersonalArea)
  Vue.component('SideBar', SideBar)
  Vue.component('PersonalInfo', PersonalInfo)
}
