<template lang="pug">
  include /mixins
  +b.g-row.--justify_center
    +b.g-cell.g-cols
      +b.relative
        ui-loader(v-if='loading')
        +b.title-form
          +e.H1.text.ds-caption.--variant_uppercase.--size_2md.--bold.--weight_regular {{ _('find a retailer')}}
        +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors }}
        +b.VALIDATION-OBSERVER.find-retailer-form(
          @submit.prevent='send',
          ref="validator"
          v-slot="{ valid }",
          tag="form")
          +b.form-wrap.--third
            +b.block-icon-search
              +e.IMG.ic(src='/static/img/search.png')
            row(
              appearance='spaced'
              space='xl'
              justify='start'
            )
              cell(
                v-for='field in fields'
                :cols='field.cols'
                :key='field.name'
                :align='["center"]'
              )
                +b.TAG.follow-input(
                  v-bem:d-control-input='field.class'
                  :key='field.name'
                  :name='field.name'
                  :tag='field.tag || "d-control-input"'
                  :rules='field.rules'
                  :prepend-icon='field.icon'
                  :type='field.type || "text"'
                  :placeholder='field.placeholder'
                  v-bind='[field.props]'
                  v-on='field.event'
                  v-model='form[field.name]')
            +b.BUTTON.av_button--main.ds-caption.--variant_uppercase.--color_white.button-find-retailer(@click.prevent='prepareData(valid)') {{ _('find') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  findRetailers,
  geoSearch,
} from '@api/communication.service'
// import debounce from 'lodash.debounce'

export default {
  name: 'SearchReatilerForm',
  props: ['place'],
  mixins: [FormMixin],
  data() {
    return {
      loading: false,
      arrayOfRetailers: null,
      nonFieldErrors: null,
      googleError: this._('No locations found, please try another search.'),
      fields: [
        {
          name: 'find',
          placeholder: this._('Search by address, city or postal code'),
          cols: '12 9-xl helper-mt',
          event: {},
          /* eslint-disable  quote-props */
          class: { 'variant': 'white' },
        },
        {
          name: 'location',
          placeholder: 'Within',
          cols: '12 3-xl',
          event: {
            input: () => this.send(),
          },
          tag: 'd-control-multiselect',
          icon: 'icon-people d-control-input__icon--size_xxs',
          props: {
            options: ['10km', '25km', '50km', '100km'],
          },
        },
      ],
      form: {
        find: '',
        location: '10km',
      },
    }
  },
  /* eslint-disable consistent-return */
  mounted() {
    /* eslint-disable no-else-return */
    if ('undefined' !== typeof this.place && '' !== this.place) {
      this.form.find = this.place
      return findRetailers.execute({ find: this.form.find }).then(res => {
        this.arrayOfRetailers = res.data.items
        this.$emit('subm', {
          retailers: res.data.items,
          showRetailers: false,
        })
        this.send()
      })
    } else {
      this.loading = true
      return findRetailers.execute({ find: this.form.find }).then(res => {
        this.arrayOfRetailers = res.data.items
        this.loading = false
        this.$emit('subm', {
          retailers: res.data.items,
          showRetailers: false,
        })
      })
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if (!this.nonFieldErrors) {
        this.nonFieldErrors = parsed.non_field_errors
      }
      console.log(errors)
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send() {
      this.loading = true
      this.$refs.validator.reset()
      /* eslint-disable no-undef */
      const geocoder = new google.maps.Geocoder()
      const address = { address: `${this.form.find} Canada` }
      return geocoder
        .geocode(address)
        .then(result => {
          this.nonFieldErrors = null
          const { results } = result;
          const dataAboutPlace = {
            lat: results[0].geometry.location.lat(),
            lon: results[0].geometry.location.lng(),
            rad: this.form.location.replace('km', ''),
          }
          geoSearch.execute({ ...dataAboutPlace })
            .then(res => {
              this.loading = false
              console.log({ ...dataAboutPlace })
              this.nonFieldErrors = null
              console.log(res.data.items)
              this.$emit('subm', {
                allRetailers: res.data.all_items,
                retailers: res.data.items,
                coordinates: {
                  lat: dataAboutPlace.lat,
                  lon: dataAboutPlace.lon,
                },
                showRetailers: true,
              })
            })
            .catch(err => {
              const error = err.json()
              error.then(() => {
                this.loading = false
                this.$emit('subm', {
                  retailers: this.arrayOfRetailers,
                  coordinates: {
                    lat: dataAboutPlace.lat,
                    lon: dataAboutPlace.lon,
                  },
                  showRetailers: false,
                })
              })
            })
        })
        .catch(() => {
          if (!this.nonFieldErrors) {
            this.nonFieldErrors = this.googleError
          }
          this.loading = false
          this.$emit('subm', {
            retailers: this.arrayOfRetailers,
            showRetailers: false,
          })
        })
    },
  },
}
</script>
