<template lang="pug">
  include /mixins
  +b.av_space_pa--8
    +b.g-row.--justify_end
      +b.g-cell.g-cols
        +e.H3.title.ds-caption.--size_2md.--hover.--pointer(@click='close') &#10006;
    +b.VALIDATION-OBSERVER(
      @submit.prevent='debounceAction',
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      +b.form-wrap
        +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
        +b.D-CONTROL-INPUT.follow-input(v-for='field in fields'
          :key='field.name'
          :name='field.name'
          :rules='field.rules'
          :type='field.type || "text"'
          :placeholder='field.placeholder'
          v-model='form[field.name]'
          v-on='field.event'
          :autofocus='field.focus'
        )
      +b.ds-caption.--appearance_uppercase.--demi-bold.--size_md {{resultText}} {{quantityProudcts}}
        //- +b.BUTTON.btn--dark( :disabled='isLoad' type='submit' aria-label='add btn' @click.prevent='prepareData(valid)') {{ _('Подписаться') }}
      +b.product-search(v-for='item in products')
        +b.g-row
          +b.g-cell.g-cols
            +b.A.link-img(:href='item.link')
              +b.IMG.img-product-in-search(:src='item.image')
          +b.g-cell.--align_center.g-cols
            +b.A.ds-caption.--size_sm.--demi-bold.--hover.--pointer(:href='item.link') {{item.title}}
      +b.av_space_mt--7
        +b.ds-caption.--size_2md.--demi-bold.--appearance_uppercase(v-if='categories.length') {{ _('categories:')}}
        +b.search-categories(v-for='item in categories')
          +b.av_space_mt--3
            +b.A.ds-caption.--demi-bold.--size_md.--hover.--pointer(:href='item.url') {{item.title}}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  searchHeader,
} from '@api/communication.service'
import debounce from 'lodash.debounce'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      resultText: '',
      quantityProudcts: '',
      nonFieldErrors: [],
      products: [],
      debounceAction: null,
      categories: [],
      fields: [
        {
          name: 'search',
          focus: true,
          placeholder: this._('Input your request'),
          event: {
            keyup: $event => this.debounceAction($event),
          },
        },
      ],
      form: {
        search: '',
      },
    }
  },
  mounted() {
    const delay = 1000
    this.debounceAction = debounce(() => {
      this.send()
    }, delay)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send() {
      this.$refs.validator.reset()
      if (1 < this.form.search.length) {
        return searchHeader.execute({}, this.form).then(res => {
          this.categories = res.data.categories
          this.products = res.data.products
          this.resultText = res.data.result_text
          this.quantityProudcts = res.data.products.length
        })
      }
      return ''
    },
  },
}
</script>
