import {
  // postResource,
  createResource,
  getResource,
  // patchResource,
} from '@/js/resource/resource'
import PATH from './path'

export const favoriteListResource = createResource(
  PATH.CABINET.FAVORITE_LIST, getResource
)

export const favoriteProduct = createResource(
  PATH.CABINET.FAVORITE_PRODUCT, getResource
)

export const catalogListResource = createResource(
  PATH.CATALOG.CATALOG_LIST, getResource
)

export const catalogProduct = createResource(
  PATH.CATALOG.CATALOG_PRODUCT, getResource
)
