<template lang="pug">
  include /mixins
  +b.g-row--appearance_spaced.--space_lg
    template.sadasd(v-for='(val, i) in filter')
      +b.g-cell.g-cols--12
        //- +b.d-control-input
        +b.g-row.--justify_between
          +b.g-cell.g-cols--7
            +e.LABEL.filter-label.ds-caption.--pointer.--hover.--size_sm(
              :for='val.slug'
            )
          +b.g-cell.g-cols.relative
            +e.element.checkmark-input.INPUT(
              type='checkbox'
              :value='val.slug',
              :name='val.slug'
              v-model='checked'
              :input-label='val.title',
              :id='val.slug'
              @change='submit()'
            )
            +e.LABEL.filter-label.ds-caption.--pointer.--hover.--size_sm.checkmark(
              :for='val.slug'
            )
</template>
<script>
export default {
  name: 'CheckboxRenderer',
  props: ['value', 'filter', 'available', 'is-excluded', 'title'],
  data() {
    return {
      checked: [],
      arrayForSlugs: [],
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },

  methods: {
    submit() {
      this.$emit('input', this.checked)
    },
    normalize(value) {
      if (!value) return []
      const checked = this.filter
        .filter(el => value.find(v => v === el.slug)).map(el => el.slug)
      return checked
    },
  },
}
</script>
