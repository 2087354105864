<template lang="pug">
  include /mixins
  +b.sorting
    d-control-multiselect(
      :options='authUser === "true" ? filters.item.filter_attrs.order.choices : notAuthSorting'
      v-model='checked'
      label='title'
      track-by='slug'
      @input='submit'
    )
    //- native-sorting(
    //-   :values='values',
    //-   :init-value='checked',
    //-   @change='checked = $event; submit()'
    //- )
</template>

<script>
import NativeSorting from '@app/Filters/NativeSorting'

export default {
  name: 'Sorting',
  components: {
    NativeSorting,
  },
  props: [
    'disabled',
    'value',
    'filters',
    'available',
  ],
  data() {
    return {
      values: [],
      checked: {},
      show: false,
      authUser: window.isAuthenticated,
      notAuthSorting: [
        {
          slug: 'recommended-up',
          title: 'By recommended up',
        },
        {
          slug: 'novelty-up',
          title: 'By new arrivals up',
        },
      ],
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    if ('true' !== this.authUser) {
      this.values = this.notAuthSorting
    } else {
      this.values = this.filters.item.filter_attrs.order.choices
    }
    let arr = []
    arr.push(this.value[0])
    this.checked = this.normalize(arr)
    arr = []
  },
  methods: {
    submit() {
      this.$emit('input', [this.checked.slug])
    },
    normalize(value) {
      if (!value) return []
      const checked = this.values.find(el => {
        if (el.slug === value[0] || el.slug === value[0].slug) {
          return el
        }
        return false
      })
      return checked
    },
  },
}
</script>
