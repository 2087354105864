import Inputmask from 'inputmask'

export function mask(inputObject, number) {
  if (inputObject.selectionStart) {
    inputObject.setSelectionRange(number, number)
    inputObject.focus()
  }
  const phonemask = new Inputmask({ mask: '+1(999)999-9999', greedy: false })
  phonemask.mask(inputObject)
}

export function masking(name, $this) {
  //  console.log($this.$refs)
  const zero = 0
  const reg = /\d+/
  const events = ['click', 'focus', 'keyup']
  events.forEach(event => {
    let root = $this.$refs[name]
    //  console.log(root);
    if (!root) return
    if (root[0]) {
      /* eslint-disable prefer-destructuring */
      root = root[0]
    }
    root.$el.addEventListener(event, e => {
      const numb = e.target.value.match(reg)
      if (numb) {
        mask(e.target, numb[zero].length)
      } else {
        mask(e.target, zero)
      }
    })
  })
}
