import {
  pipe,
  partialRight,
} from 'ramda'

import {
  receiveResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import {
  fetcher,
  defaultDataTransformer,
  transformDataFetcher,
  raiseHttpErrorTransformer,
} from '@resource/transformer'

import { HEADERS_MIDDLEWERE } from '@resource/middleware'

export const getResource = receiveResource
  .middleware(jsonRequestMiddleware)
  .middleware(HEADERS_MIDDLEWERE)

export const postResource = getResource
  .config('method', 'POST')

export const patchResource = postResource
  .config('method', 'PATCH')

export const deleteResource = getResource
  .config('method', 'DELETE')

export function createResource(
  template,
  resource = getResource,
  transformer = defaultDataTransformer
) {
  return resource
    .url(makeTemplateGetter(template))
    .fetcher(transformDataFetcher(fetcher,
      pipe(raiseHttpErrorTransformer, transformer)))
}

export const createReceiver = partialRight(createResource, [receiveResource])
