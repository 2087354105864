import * as rules from 'vee-validate/dist/rules'
import {
  extend,
} from 'vee-validate'

// loop over all rules
/* eslint-disable no-restricted-syntax, guard-for-in */
for (const rule in rules) {
  // add the rule
  extend(rule, rules[rule])
}
/* eslint-disable no-unused-expressions */
extend('password', {
  validate: (value, { other }) => { value === other },
  message: 'The password confirmation does not match.',
  params: [{ name: 'other', isTarget: true }],
})
