<script>
export default {
  name: 'ModalTrigger',
  props: {
    url: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
    },
    classes: {
      type: Array,
    },
  },
  data() {
    return {
      cls: this.classes,
    }
  },
  methods: {
    openPopup() {
      console.log(this.classes);
      if (!this.cls) {
        this.cls = ['']
      }
      this.$modal.show(this.componentLoader,
        {
          info: this.$attrs,
        },
        {
          height: 'auto',
          name: this.name,
          minWidth: 300,
          adaptive: true,
          classes: this.cls.concat(''),
          scrollable: true,
          transition: 'false',
        })
    },
  },
  computed: {
    componentLoader() {
      return () => import(`@app/${this.url}`)
    },
  },
  render() {
    return this.$scopedSlots.default({
      open: () => {
        this.openPopup()
      },
    })
  },
}
</script>
