<template lang="pug">
  include /mixins
  +b.block-colors-product
    +b.wrap-colors-component(
      v-for='(item, index) in arrayWithSortingAttributes'
    )
      +b.block-type-color(
        v-if='type_attr.attrs'
        v-for='type_attr, key in item.groups'
      )
        +b.next-level-colors(
          v-if='type'
          v-for='type_deeper in type_attr.attrs'
        )
          div.helper-for-colors(
            style='border-radius: 50%; padding: 2px;'
            v-if='type_deeper.values'
            :class='{"border": item.is_selected}'
          )
            +b.block-color-product(
              v-if='type_deeper.values.length === 1 && type_deeper.values[0].texture'
              @touchstart='stopPropagation($event, item.id)'
              @mouseenter="debounceAction($event, item.id)"
              :style="{background: `url(${type_deeper.values[0].texture})`}"
              :class='{"current": item.is_selected}'
              ref='color'
              @click='goToCard($event, item.url, item.id)'
            )
            +b.block-color-product.--images(
              v-if='type_deeper.values.length === 2 && type_deeper.values[0].texture'
              @touchstart='stopPropagation($event, item.id)'
              @mouseenter="debounceAction($event, item.id)"
              ref='color'
              @click='goToCard($event, item.url, item.id)'
              :class='{"current": item.is_selected}'
            )
              +b.color-bg(:style="{background: `url(${type_deeper.values[0].texture})`}")
              +b.color-bg(:style="{background: `url(${type_deeper.values[1].texture})`}")
        div.helper-for-colors(
          style='border-radius: 50%; padding: 2px;'
          :class='{border: item.is_selected}'
          v-if='type_attr.color_0 && type_attr.color_1 && key < 1'
        )
          +b.A.block-color-product(
            :href='item.url'
            :class='{"current": item.is_selected}'
            @mouseenter="debounceAction($event, item.id)"
            :style='{background: `linear-gradient(45deg, ${type_attr.color_0}  49.99%, ${type_attr.color_1} 50%, ${type_attr.color_1} 100%)`}'
          )
        div.helper-for-colors(
          style='border-radius: 50%; padding: 2px;'
          :class='{border: item.is_selected}'
          v-if='type_attr.color_0 && !type_attr.color_1 && key < 1'
        )
          +b.A.block-color-product(
            :href='item.url'
            :class='{"current": item.is_selected}'
            @mouseenter="debounceAction($event, item.id)"
            :style='{background: type_attr.color_0}'
          )
</template>

<script>

import { mapActions, mapState } from 'vuex'

import {
  changeProduct,
  // getLike,
} from '@api/getItems.service'
import debounce from 'lodash.debounce'

import resizeWindow from '@utils/resize-window'

export default ({
  props: {
    type: String,
    id: Number,
  },
  data() {
    return {
      activeLink: null,
      debounceAction: null,
      infoAboutProduct: {
        card: null,
        url: null,
        is_selected: null,
      },
      arrayWithSortingAttributes: [],
      arrayWithChangingValue: {
        colorValue: [],
      },
      labels: {
        model: this._('Model'),
      },
    }
  },
  created() {
    if (!this.colorsOfProduct[this.id]) {
      this.getAndCheckColors({ id: Number(this.id) })
    }
  },
  mounted() {
    // console.log('card' === this.type && !this.activeLink)
    const delay = 0
    this.debounceAction = debounce((event, id) => {
      this.checkingEventOfColors(event, id)
    }, delay)
  },
  watch: {
    colorsOfProduct: {
      deep: true,
      immediate: true,
      isUpdated: false,
      handler(nval) {
        this.setColorsData(nval)
      },
    },
  },
  computed: {
    ...mapState('colors', [
      'colorsOfProduct',
    ]),
  },
  methods: {
    goToCard(e, url, id) {
      this.activeLink = resizeWindow()
      if ('card' !== this.type) {
        window.location.href = url
      } else if (!this.activeLink && 'card' === this.type) {
        window.location.href = url
      } else {
        e.preventDefault()
        this.checkingEventOfColors(e, id)
      }
    },
    stopPropagation(e, id) {
      this.checkingEventOfColors(e, id)
    },
    ...mapActions('colors', ['getAndCheckColors']),
    setColorsData(nval) {
      this.$nextTick(() => {
        if (nval[this.id] && !this.isUpdated) {
          this.collectionAttributes(nval[this.id].selection_attrs)
          this.isUpdated = true
        }
      })
    },
    checkingEventOfColors(event, id) {
      if ('card' === this.type) {
        changeProduct.execute({ id }).then(({ data: { item } }) => {
          event.preventDefault()
          const parent = event.target.closest('.product-item')
          const currentLast = parent.querySelector('.border')
          if (currentLast) {
            currentLast.classList.remove('border')
          }
          event.target.closest('.helper-for-colors').classList.add('border')
          // if (event.path[0].classList.contains('color-bg')) {
          //   event.path[2].classList.add('border')
          // } else {
          //   event.path[1].classList.add('border')
          // }
          const titleForChange = parent.querySelector('.title-for-change')
          const imgForChange = parent.querySelector('.block-product__img')
          const btnAddToCard = parent.querySelector('.av_button')
          const btnLink = parent.querySelector('.btn-link')
          if (btnLink) {
            btnLink.setAttribute('href', item.url)
          }
          const linkImage = parent.querySelector('.block-product__img-wr')
          // const likes = parent.querySelector('.block-favourite__ic')
          titleForChange.innerHTML = `${this.labels.model}: ${item.title}`
          btnAddToCard.dataset.id = id
          // likes.dataset.like = item.is_like
          // likes.dataset.id = id
          linkImage.setAttribute('href', item.url)
          imgForChange.setAttribute('src', item.image)
          this.$emit('change-color', { isLiked: item.is_like, id })
        })
      }
    },
    /* eslint-disable no-restricted-syntax */
    collectionAttributes(selectionAttributes) {
      selectionAttributes.forEach(element => {
        // here we set colors and textures
        element.groups.forEach((el, index) => {
          el.attrs.forEach((nameOfGroup, idx) => {
            for (const [key, value] of Object.entries(element.attrs)) {
              if (key === nameOfGroup && element.groups[index] && value.values) {
                element.groups[index].attrs[idx] = value
                if (1 === value.values.length) {
                  if (value.values[0].color) {
                    element.groups[index][`color_${idx}`] = value.values[0].color
                    element.groups[index][`title_${idx}`] = value.values[0].title
                  } else {
                    element.groups[index].texture = value.values[0].texture
                    element.groups[index].title_0 = value.values[0].title
                  }
                } else if (1 < value.values.length) {
                  /* eslint-disable no-prototype-builtins */
                  const array = value.values.filter(elem => elem.hasOwnProperty('color'))
                  array.forEach((e, i) => {
                    if (e.color) {
                      // console.log(`color_${i}`, e.color);
                      element.groups[index][`color_${i}`] = e.color
                      element.groups[index][`title_${i}`] = value.values[0].title
                    }
                  })
                }
              }
            }
          })
        })
      })
      this.arrayWithSortingAttributes = selectionAttributes
    },
  },
})
</script>
