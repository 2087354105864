import './public-path'
import Router from 'vue-router'
import Vuex from 'vuex'
import Vue from 'vue'
import I18n from 'vue-i18n'
import * as VueGoogleMaps from 'vue2-google-maps'

import {
  extend,
} from 'vee-validate'
import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
  ext, size,
} from 'vee-validate/dist/rules'
import { install } from '@/install'
import { createApp } from '@/app'

import '@utils/events'
import './styles/index.sass'
import './js/validate'
import './js/directives'

import appComponents from './js/components'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC8pe3psITtMuZ9jTA8JCNamfdgfFQkH9o',
    libraries: 'places',
  },
})

Vue.config.productionTip = false
// Vue.forceUpdate();
Vue.use(install)
Vue.use(appComponents)

const { app } = createApp({
  Vue,
  I18n,
  Router,
  Vuex,
})

app.$mount('#app')

extend('required', { ...required, message: app._('This field is required.') });
extend('confirmed', { ...confirmed, message: app._('Passwords must match.') });
extend('min', { ...min, message: app._('Value is too short.') });
extend('min_value', { ...minValue, message: app._('Minimum value is {min}.') });
extend('max', { ...max, message: app._('Value is too long.') });
extend('max_value', { ...maxValue, message: app._('Maximum value is {max}.') });
extend('ext', { ...ext, message: app._('Invalid file format') });
extend('email', { ...email, message: app._('Email is invalid.') });
extend('size', { ...size, message: (e, a) => app._('The file size must be less than ') + a.size / 1024 + app._('Mb') });

const MOBILEREG = /^[+()-\d]+$/

extend('phone', {
  validate(value) {
    // Regex for a international phone number
    return MOBILEREG.test(value)
  },
  message: app._('Phone is not valid'),
})
