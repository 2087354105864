// const { language } = window

export function checkLogo() {
}

const hoverFn = (child, timeout) => {
  window.mounseover_timeout = setTimeout(() => {
    const overlay = document.getElementById('menu-overlay')
    const menu = document.getElementById('fat-menu')
    const triggers = Array.from(document.getElementsByClassName('catalog-btn'))
    triggers.forEach(b => b.classList.add('is-opened'))
    menu.classList.add('is-active')
    menu.classList.add('is-hovered')
    overlay.classList.remove('is-hidden')
  }, timeout)
}

export function imitateHover() {
  const parent = document.getElementById('desktop-hover')
  const timeout = 700
  if (!parent) return
  parent.addEventListener('mouseenter', () => {
    hoverFn(parent, timeout)
  })
  parent.addEventListener('mouseleave', () => {
    clearTimeout(window.mounseover_timeout)
  })
}

export function mobileFlipper() {
  const cards = Array.from(document.getElementsByClassName('flip-container'))
  cards.forEach(el => {
    el.addEventListener('click', () => {
      cards.forEach(card => card.classList.remove('hover'))
      el.classList.add('hover')
    })
  })
}

export function prettyMobHeader() {
  const header = document.getElementById('header-wrap')
  const offset = 60
  if (!header) return
  const scrollHeader = () => {
    if (document.body.scrollTop > offset ||
      document.documentElement.scrollTop > offset) {
      header.classList.add('is-active')
      return false
    }
    header.classList.remove('is-active')
    return false
  }
  window.addEventListener('scroll', scrollHeader, { passive: true })
}

function scrollFunction() {
  const btnScroll = document.getElementById('scroll-top')
  const offset = 60
  if (document.body.scrollTop > offset ||
    document.documentElement.scrollTop > offset) {
    btnScroll.classList.add('is-active')
  } else {
    btnScroll.classList.remove('is-active')
  }
}

export const scrolly = () => {
  // Scroll to Up
  scrollFunction()
  window.addEventListener('scroll', scrollFunction, { passive: true })
}
