<template lang="pug">
  include /mixins
  +b.ds-section.--space_none.--bg-color_main-extra-light
    +b.preloader#preloader
      +b.wrp.relative
        +b.sk-double-bounce
          +b.sk-child.sk-double-bounce-1
          +b.sk-child.sk-double-bounce-2
    +b.g-row.--space_none
      +b.g-cell.g-cols--12.g-cols--3-2lg.g-cols--2-xl.g-cols--auto-xl.change-width-g-cols(v-if='watcherForLink')
        +b.wrap-router-menu(
          v-if='watcherForLink'
          :class='width ? "big" : "small"'
        )
          +b.g-row.--justify_center.relative
            +b.wrap
              +b.catalog-sidebar(v-if='watcherForLink')
                +b.row.--justify_center
                  side-bar.side-bar.side-bar--style_main(
                      :fields="sidebar"
                      :type="'side-bar'"
                    )
      +b.g-cell.g-cols--12.g-cols--8-2lg.g-cols--8-2xl.g-cols--9-b-fhd.grow(
        :class='{"another-size": !watcherForLink}'
      )
        +b.wrap-router-component
          router-view(
            :state='width'
            @state='checkRouter'
            :type='user'
            @link='checkLink'
          )
</template>
<script>
import {
  CHIPPING_REQUEST_NAME,
  PRODUCT_SEARCH_NAME,

  MY_ORDERS,
} from '../Routes/routes'

export default {
  props: ['user'],
  name: 'UserPersonal',
  data() {
    return {
      watcherForLink: null,
      width: true,
      states: false,
      show: false,
      sidebar: [
        {
          src: '/static/img/orders.png',
          icon: 'icon-my-orders',
          link: MY_ORDERS,
          name: this._('My orders'),
        },
        {
          src: '/static/img/profile-data.png',
          icon: 'icon-profile-data',
          link: PRODUCT_SEARCH_NAME,
          name: this._('Profile data'),
        },
        {
          src: '/static/img/empl.png',
          icon: 'icon-employees',
          link: CHIPPING_REQUEST_NAME,
          name: this._('Employees'),
        },
        // {
        //   path: '/:catchAll(.*)',
        //   compoent: ''
        // },
      ],
    }
  },
  mounted() {
    this.watcherForLink = -1 === window.location.href.indexOf('/profile/cart/')
  },
  /* eslint-disable object-shorthand */
  watch: {
    '$route.path'() {
      window.scrollTo(0, 0)
      this.watcherForLink = -1 === window.location.href.indexOf('/profile/cart/')
    },
  },

  methods: {
    testMethod() {
      this.$refs.menuTrigger.close()
    },

    checkLink() {
      console.log(window.location.href, '@@@@@@@@@@@@@@@@@@@@')
      this.watcherForLink = -1 === window.location.href.indexOf('/profile/cart/')
    },

    checkRouter(state) {
      this.states = state
    },
  },
}
</script>
