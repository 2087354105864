<template lang="pug">
  include /mixins
  +b.main(
    :class="(minimized)? 'side-bar--minimized' : ''"
  )
    +b.ds-section.--space_none.--relative
      +e.element
        +b.g-container--fit_2xl.g-container--space_none
          +b.g-row.--justify_center-xl
            +b.g-cell.g-cols
              +b.line-navigation
            +b.g-cell.g-cols
              +b.ROUTER-LINK.ds-panel--space_md(
                v-for="(element, index) in fields"
                :key="element.name"
                :to="{name: element.link}"
              )
                +e.SPAN.t.av_space_mr--2
                  +e.IMG(
                    :src='element.src'
                  )
                +e.SPAN.title.ds-caption.--demi-bold.--size_2md.--hover.--pointer(
                  @click='closeMenu'
                ) {{element.name}}
              +b.av_space_ml--5
                +b.block-line-gray.--second
                +b.block-line-gray.--second
              +b.LOGOUT-BUTTON
</template>
<script>
export default {
  props: ['type', 'fields'],
  name: 'SideBar',
  data() {
    return {
      minimized: false,
    }
  },
  methods: {
    closeMenu() {
      this.$emit('close')
    },
    minimizeSidebar() {
      this.minimized = !this.minimized
    },
  },
}
</script>
