<template lang="pug">
  include /mixins
  +b.form-in-img
    +b.block-close.--second
      +b.g-row.--justify_end
        +b.g-cell.g-cols
          +e.SPAN.text.ds-caption.--size_sm.--letter-space-small.--pointer.--hover(@click='closeForm') &#10006;
    +b.wrap-title-form
      +b.H1.ds-caption.--appearance_uppercase.--letter-space-small.--size_3xl.--weight-regular {{ _('Forgot Your Password?')}}
    +b.H4.ds-caption.--letter-space-small.--bold {{ _('We will send you an email to reset your password.')}}
    +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
    +b.VALIDATION-OBSERVER(
      @submit.prevent='send',
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      +b.D-CONTROL-INPUT.follow-input(v-for='field in fields'
        v-bem:d-control-input="{'variant': 'black'}"
        :key='field.name'
        :name='field.name'
        :rules='field.rules'
        :type='field.type || "text"'
        :placeholder='field.placeholder'
        v-model='form[field.name]')
      +b.wrap-button-form.--second
        +b.g-row.--justify_center
          +b.g-cell.g-cols--12
            +b.BUTTON.av_button--main.--size-second.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('reset my password') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  passwordReset,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          name: 'email',
          placeholder: 'Email *',
          rules: 'required',
        },
      ],
      form: {
        email: '',
        // recaptcha: 'dummy_value',
      },
    }
  },
  methods: {

    closeForm() {
      this.$emit('close')
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },

    send(data) {
      this.$refs.validator.reset()
      return passwordReset.execute({}, data).then(res => {
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        AnswerMessage(message, this)
      })
    },

  },
}
</script>
