<template lang="pug">
  include /mixins
  +b.VALIDATION-OBSERVER(
    @submit.prevent='send',
    ref="validator"
    v-slot="{ valid }",
    tag="form")
    +b.form-wrap.--second
      +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
      +b.D-CONTROL-INPUT.follow-input(v-for='field in fields'
        :key='field.name'
        :name='field.name'
        :rules='field.rules'
        :type='field.type || "text"'
        :placeholder='field.placeholder'
        v-model='form[field.name]')
    +b.wrap-button-form
      +b.g-row.--justify_center
        +b.g-cell.g-cols
          modal-trigger(
            name='findRetailerModal',
            url='UiElements/FIndeRetailerInForm',
            :city='form.city'
            :classes='["find-retailer-modal"]'
          )
            template(#default='{ open }')
              +b.BUTTON.av_button--main.ds-caption.--variant_uppercase.--color_white(@click.prevent='open') {{ _('find') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

// import {
//   AnswerMessage,
// } from '@utils/message-modal'

// import {
//   subscribe,
// } from '@api/communication.service'

export default {
  name: 'FormFindRetailer',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          name: 'city',
          placeholder: this._('Your city'),
        },
      ],
      form: {
        city: '',
        recaptcha: 'dummy_value',
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      /* eslint-disable no-useless-return */
      if (!valid) return
      // this.submit(valid, this.form)
    },
    send() {},
    open() {},
  },
}
</script>
