export const catalog = {
  namespaced: true,
  state: {
    chips: {
      all: {
        item: { filter_attrs: {} },
      },
      filters: {},
    },
    chipsChangedFilters: {},
  },
  actions: {
    async setChipsData({ commit }, data) {
      const filters = {}
      Object.keys(data.filters).forEach(k => {
        if (data.filters[k].length) {
          filters[k] = data.filters[k]
        }
      })
      const preparedFilters = {
        all: data.all,
        filters,
      }
      commit('CHANGE_CHIPS_DATA', preparedFilters)
    },
    async setChangedChipsData({ commit }, data) {
      commit('CHANGE_CHANGED_CHIPS_DATA', data)
    },
  },
  mutations: {
    CHANGE_CHIPS_DATA(state, result) {
      state.chips = result
    },
    CHANGE_CHANGED_CHIPS_DATA(state, result) {
      state.chipsChangedFilters = result
    },
  },
}
