<template lang="pug">
  include /mixins
  +b.form-count.relative
    //- +b.error-for-button.--another-width-2(v-if='customError') {{_('You can`t buy anymore')}}
    +b.error-for-button.--another-top(v-if='error') {{error}}
    +b.g-row.--justify_center.--justify_between-2lg.--space_none
      +b.g-cell.--align_center.g-cols(
        :class='{"disabled disabled--form": currentQuantity < 1}'
      )
        +b.g-row.--justify_center-till-sm
          +b.g-cell.--align_center-till-sm.g-cols
            +b.BUTTON.av_button--count(@click.prevent='minusCount') –
          +b.g-cell.g-cols
            +b.D-CONTROL-INPUT.follow-input(
              v-for='field in fields'
              v-bem:d-control-input="{'variant': 'count'}"
              :key='field.name'
              :name='field.name'
              type='number'
              min='1'
              :max='quantity'
              v-model='form[field.name]'
              @input='addToCard'
              )
          +b.g-cell.--align_center-till-sm.g-cols
            +b.BUTTON.av_button--count(@click.prevent='plusCount($event)') +
      +b.g-cell.--align_center.g-cols
        +b.BUTTON.av_button.--main.--size-sixth.ds-caption.--variant_uppercase.--color_white(
          ref='buttonOrder'
          v-text='message'
          :class='{"block-button": currentQuantity < 1}'
          @click.prevent='executeAdd($event)'
        )
</template>

<script>
import { mapActions } from 'vuex'

import {
  productAddToCard,
} from '@api/communication.service'

import {
  changeProduct,
} from '@api/getItems.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  props: ['id', 'quantity'],
  name: 'SearchForm',
  data() {
    return {
      customError: true,
      error: null,
      message: this._('add to cart'),
      counterInput: 0,
      currentQuantity: this.quantity,
      nonFieldErrors: [],
      lastNumber: 1,
      fields: [
        {
          name: 'count',
          type: 'number',
        },
      ],
      form: {
        count: 1,
        // recaptcha: 'dummy_value',
      },
      label: {
        error: this._('You can`t order 0 products'),
      },
      arrayOrders: [],
      dataProduct: {
        entities: [{
          element: {
            type: 'products.productvariant',
            id: this.id,
          },
          parameters: {},
          quantity: 1,
        }],
      },
    }
  },
  methods: {
    ...mapActions('cartQuantity', [
      'getQuantityOfCart',
    ]),
    executeAdd() {
      if (this.form.count && 0 < Number(this.form.count)) {
        this.dataProduct.entities[0].quantity = Number(this.form.count)
        changeProduct.execute({ id: this.id }).then(({ data: { item } }) => {
          const quantityOfProductString = document.getElementById('strQuantity')
          quantityOfProductString.innerText = `${item.quantity_string}`
          this.currentQuantity = item.quantity
          if (1 > this.currentQuantity) this.message = this._('out of stock')
          return productAddToCard.execute({}, this.dataProduct)
            .then(res => {
              this.getQuantityOfCart()
              const message = {
                message: {
                  title: res.meta.message.header,
                  text: res.meta.message.text,
                },
              }
              AnswerMessage(message, this)
            })
            .catch(res => {
              const error = res.json()
              error.then(e => {
                this.error = e.errors[0].state[0].message
                setTimeout(() => {
                  this.error = null
                }, 3000)
              })
            })
        })
      }
      return 'e'
    },
    addToCard(event) {
      const quantityFromInput = Number(event)
      if (0 >= quantityFromInput) {
        this.error = this.label.error
      } else {
        this.error = null
      }
      if (quantityFromInput > this.quantity) {
        this.form.count = this.quantity
      }
    },
    plusCount() {
      if (this.form.count < Number(this.quantity)) {
        Number(this.form.count++)
        this.lastNumber = Number(this.form.count) - this.lastNumber
        this.lastNumber = Number(this.form.count)
      } else {
        this.customError = true
        setTimeout(() => {
          this.customError = false
        }, 5000)
      }
    },
    minusCount() {
      if (1 < this.form.count) {
        Number(this.form.count--)
        this.lastNumber = Number(this.form.count) + -this.lastNumber
        this.lastNumber = Number(this.form.count)
      }
    },
  },
}
</script>
