<template lang="pug">
  include /mixins
  +b.av_space_mt--7.av_space_mb--7
    +b.g-row--appearance_spaced.--space_lg(v-if='catalogMain')
      template
        +b.g-cell.g-cols--12(v-for='(val, i) in categories.choices')
          +b.row.--justify_between
            div
              div
                +e.LABEL.filter-label.ds-caption.--pointer.--hover.--weight-regular(:for='val.value') {{ val.display_name }}
            +b.relative
              +e.element.checkmark-input.INPUT(
                type='radio'
                class='radio-input'
                :value='val.value',
                :key='val.value'
                v-model='checked'
                name='gender'
                :id='val.value'
                :input-label='val.title',
                @change='submit(val.redirect_url)'
                :checked='checked[0] === category'
              )
              +e.LABEL.filter-label.ds-caption.--pointer.--hover.--size_sm.checkmark(
                :for='val.value'
              )

</template>
<script>
import {
  getContext,
} from '@api/communication.service'

export default {
  name: 'RadioRenderer',
  props: ['value', 'filter', 'available', 'is-excluded', 'deflt', 'label'],
  data() {
    return {
      arrayForHelp: [],
      checked: [],
      arrayForSlugs: [],
      catalogMain: true,
      categories: [],
      category: this.label,
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    if (-1 !== window.location.href.indexOf('profile')) {
      this.catalogMain = false
    }
    getContext.execute({}).then(res => {
      this.categories = res.static.category
      this.category = this.categories.default
    })
    if (this.value) {
      this.checked = this.normalize(this.category)
    } else {
      this.checked = this.normalize(this.category)
      this.submit()
    }
  },
  methods: {
    submit(link) {
      this.arrayForSlugs.push(this.checked)
      this.arrayForSlugs = []
      window.location.href = link
    },
    normalize(value) {
      if (!value) return []
      let checked = ''
      this.filter.forEach(el => {
        if (el.slug === value) {
          checked = value
        }
      })
      return checked
    },
  },
}
</script>
