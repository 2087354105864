<template lang="pug">
  include /mixins
  div
    slot(
      :change-color="changeColor"
      :like="like"
    )
    //- +b.VUE-RENDERER(
    //-   v-if="isResponse"
    //-   :result='items'
    //- )
    //- div(@mouseover="debounceAction($event)")
    //-   slot(v-if="!isResponse")
</template>

<script>
// import {
//   createResource,
// } from '@/js/resource/resource'
// import debounce from 'lodash.debounce'

export default {
  name: 'product',
  props: {
    initialIsLiked: {},
    initialProductId: {},
  },
  data() {
    return {
      // debounceAction: null,
      // items: [],
      // isResponse: false,
      like: {
        key: 1,
        isLiked: this.initialIsLiked,
        productId: this.initialProductId,
      },
    }
  },
  mounted() {
    // const delay = 250
    // this.debounceAction = debounce((event, id) => {
    //   this.changeColor(event, id)
    // }, delay)
  },
  methods: {
    changeColor({ isLiked, id }) {
      this.like.key++
      this.like.isLiked = isLiked
      this.like.productId = id
      // if (event.path[0].classList.contains('block-color-product') && !event.path[0].classList.contains('current') && event.path[0].attributes.href.value) {
      //   const linkLabel = event.path[0].attributes.href.value
      //   this.getCard(linkLabel)
      // } else if (event.path[1].classList.contains('block-color-product') && !event.path[1].classList.contains('current') && event.path[1].attributes.href.value) {
      //   const linkLabel = event.path[1].attributes.href.value
      //   this.getCard(linkLabel)
      // }
    },
    // getCard(link) {
    //   let fullLink = ''
    //   fullLink = window.location.origin + link.replace('product', 'product-card')
    //   // if (-1 !== window.location.href.indexOf('favorites')) {
    //   //   fullLink = window.location.origin + link
    //   // } else {
    //   //   fullLink = window.location.origin + link.replace('product', 'product-card')
    //   // }
    //   const getCurrentFavoriteProduct = createResource(fullLink)
    //   return getCurrentFavoriteProduct.execute().then(res => {
    //     this.items = res.data
    //     this.isResponse = true
    //   })
    // },
  },
}
</script>
