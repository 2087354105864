<template lang="pug">
  include /mixins
  div
    row.ma--bottom_lg(justify="center" space="sm-xl")
      cell(cols="9 4-sm 3-lg 2-xxl" v-if="isAddMore")
        +b.BUTTON.av_button.--main.ds-caption.--color_white.--blair.--variant_uppercase(
          appearance="fill-accent-1"
          rounded="sm"
          width="full"
          space="sm-adaptive"
          v-if="hasNext() && isAddMore"
          @event="changePage(innerCurrent+1, true); if (start === '') start = innerCurrent"
        ) {{ _('Подгрузить еще')}}
      cell(cols="12 9-xl 10-xxl")
        +b.pagination__wrapper(:class="classWrapper")
          +e.arrow-left
            template(v-if="hasPrev()")
              +e.arrow--left.de-icon--type_chevron-left.BUTTON(@click.prevent="changePage(prevPage, false); start = ''" aria-label="prev")
            template(v-else)
              +e.arrow--left.de-icon--type_chevron-left.is-disabled.A(href="#" aria-label="prev")
          +e.pages
            +e.button.A(v-if="hasFirst()", @click.prevent="changePage(1, false); start = ''" :class="{ 'is-active': active(1) }") 1
            +e.button.events-none.P(
              v-if="hasFirst() && current >= 5"
              :class="{ 'is-active': start < current && !pages.includes(start) && this.start !== ''}"
            ) ...
            template(v-for="page in pages")
              +e.button.P(@click.prevent="changePage(page, false); start = ''", :class="{ 'is-active': active(page) }") {{ page }}
            +e.button.events-none.P(v-if="hasLast() && current + 4 <= totalPages") ...
            +e.button.P(v-if="hasLast()", @click.prevent="changePage(totalPages, false); start = ''") {{ totalPages }}
          +e.arrow-right
            template(v-if="hasNext()")
              +e.arrow--right.de-icon--type_chevron-right.BUTTON(@click.prevent="changePage(nextPage, false); start = ''" aria-label="next")
            template(v-else)
              +e.arrow--right.de-icon--type_chevron-right.is-disabled.A(href="#" aria-label="next")
</template>

<script>
const one = 1
const zero = 0
export default {
  name: 'pagination',
  data() {
    return {
      innerCurrent: this.current,
      start: '',
    }
  },
  props: {
    classWrapper: String,
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 9,
    },
    pageRange: {
      type: Number,
      default: 2,
    },
    isAddMore: Boolean,
  },
  watch: {
    current(nval) {
      this.innerCurrent = nval
    },
  },
  computed: {
    pages() {
      const pages = []

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }

      return pages
    },
    rangeStart() {
      const start = this.current - this.pageRange
      return (start > zero) ? start : one
    },
    rangeEnd() {
      const end = this.current + this.pageRange
      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage() {
      return this.current + one
    },
    prevPage() {
      return this.current - one
    },
    active() {
      return function (page) {
        return '' !== this.start ? (page === this.start ||
          page > this.start && page <= this.current || page === this.current) : page === this.current
      }
    },
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== one
    },
    hasLast() {
      return this.rangeEnd < this.totalPages
    },
    hasPrev() {
      return '' !== this.start ? this.start !== one : this.current > one
    },
    hasNext() {
      return this.current < this.totalPages
    },
    changePage(page, boolean) {
      this.$emit('add', boolean)
      this.$emit('page-changed', page)
    },
  },
}

</script>
