<template lang="pug">
  include /mixins
  +b.g-container.--variant_tiny(v-if='dataOrder')
    +b.info-about-order
      +b.H1.ds-caption.--variant_uppercase.--size_3xl.--weight_regular {{ _('Order №')}} {{dataOrder.identifier}}
      //- +b.A.block-link-back(href='/profile/orders/')
        +e.SPAN.text.ds-caption.--size_sm {{ _('Back to orders') }}
      +b.order-profile
        +b.ds-table.--styling_default.TABLE
          +e.head.THEAD
            +e.TR
              - var tableHeadData = [{string: "Vendor code"}, {string: "Product Name"}, {string: "Price"}, {string: "Retailer Discount  price"}, {string: "Quantity"}, {string: "Total cost"}]
              each i in tableHeadData
                +e.cell.--head.th-without-padding.TH
                  +b.ds-caption.--size_md.--demi-bold.--dark-third.SPAN=i.string
            +e.TR
              - var tableHeadData = [{string: "Vendor code"}, {string: "Product Name"}, {string: "Price"}, {string: "Retailer Discount  price"}, {string: "Quantity"}, {string: "Total cost"}]
              each i in tableHeadData
                +e.cell.--head.th-with-padding.TH
                  +b.block-line-black
          +e.body.TBODY
            +e.row.TR(v-for='(item, index) in dataOrder.products')
              +e.TD.cell.tc-without-padding
                +b.caption-help
                  +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Vendor code') }}
                div
                  +b.ds-caption.--size_sm.--color_1.SPAN {{ item.vendor_code }}
              +e.TD.cell.tc-without-padding
                +b.caption-help
                  +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Product Name') }}
                div
                  +b.ds-caption.--size_sm.--color_1.SPAN {{ item.product_title }}
              +e.TD.cell.tc-without-padding
                +b.caption-help
                  +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Price') }}
                div
                  +b.ds-caption.--size_sm.--color_1.SPAN {{ item.price }}
              +e.TD.cell.tc-without-padding
                +b.caption-help
                  +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Retailer Discount  price') }}
                div
                  +b.ds-caption.--size_sm.--color_1.SPAN {{ item.discounted_price }}
              +e.TD.cell.tc-without-padding
                +b.caption-help
                  +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Quantity') }}
                div
                  +b.ds-caption.--size_sm.--color_1.SPAN {{ item.quantity }}
              +e.TD.cell.tc-without-padding
                +b.caption-help
                  +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Total cost') }}
                div
                  +b.ds-caption.--size_sm.--color_1.SPAN {{ item.total_price }}
        +b.total-price
          +b.g-row.--justify_center.--justify_end-md
            +b.g-cell.g-cols
              +b.g-row.--space_none
                +b.g-cell.g-cols
                  +b.av_space_mr--3.av_space_mr--9--sm
                    +e.title.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_right {{ _('Sub-total') }}
                    +b.av_space_mt--7
                      +e.title.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_right {{ _('Tax') }}
                    +b.av_space_mt--7
                      +e.title.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_right {{ _('Shipment') }}
                    +b.av_space_mt--7
                      +e.title.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_right {{ _('Total') }}
            +b.g-cell.g-cols
              +b.g-row.--space_none
                +b.g-cell.g-cols
                  +b.av_space_mr--3.av_space_ml--9-sm
                    +e.price.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_left {{ _('ca$') }} {{dataOrder.final_price}}
                    +b.av_space_mt--7
                      +e.price.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_left {{ _('ca$') }} {{dataOrder.final_tax_price}}
                    +b.av_space_mt--7
                      +e.price.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_left {{ _('ca$') }} 0
                    +b.av_space_mt--7
                      +e.price.ds-caption.--size_3xs.--size_sm-sm.--demi-bold.--variant_uppercase.--appearance_left {{ _('ca$') }} {{dataOrder.full_price}}
              //- +b.info-price-card.row.--justify_between
              //-   +e.title.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase Sub-total
              //-   +e.price.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase ca$ {{ dataOrder.final_price }}
              //- +b.info-price-card.row.--justify_between
              //-   +e.title.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase Shipment
              //-   +e.price.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase ca$ 0
              //- +b.info-price-card.row.--justify_between
              //-   +e.title.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase Tax
              //-   +e.price.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase ca$ {{ dataOrder.final_tax_price }}
              //- +b.info-price-card.row.--justify_between
              //-   +e.title.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase Total
              //-   +e.price.ds-caption.--hover.--pointer.--size_sm.--demi-bold.--variant_uppercase ca$ {{ dataOrder.full_price }}
        +b.pay-order(v-if='dataOrder.is_allow_pay')
          +b.g-row.--justify_between
            +b.g-cell.--align_center.g-cols.--12-xs.--3-md.--5-lg
              +b.av_space_mb--5.av_space_mb--0-md
                +e.text.H3.ds-caption.--size_2xs.--demi-bold {{ _('Online payment') }}
                +e.text.SPAN.ds-caption.--size_2xs {{ _('When paying online, there is an additional discount') }}
            +b.g-cell.g-cols--3.g-cols--2-lg
              +b.av_button.--main.--size-fives.ds-caption.--color_white.--variant_uppercase.--appearance_center(
                @click.prevent='payOrder(dataOrder.id)'
              ) {{ _('pay') }}
            +b.g-cell.g-cols.--12-xs.--7-sm.--4-md.--3-lg.--4-xl
              +b.total-payment
                +e.SPAN.decoration.ds-caption.--demi-bold.--size_2xs {{ _('Total payment') }}
                +e.SPAN.text.ds-caption.--demi-bold.--size_2xs {{ _('CA$') }} {{ dataOrder.online_full_price }}
</template>

<script>
import {
  payOrder,
  getOrders,
} from '@api/communication.service'

export default {
  data() {
    return {
      id: this.$route.params.id,
      dataOrder: null,
      labels: [
        { string: this._('Vendor code') },
        { string: this._('Product Name') },
        { string: this._('Price') },
        { string: this._('Retailer Discount  price') },
        { string: this._('Quantity') },
        { string: this._('Total cost') },
      ],
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$emit('loader', false)
    this.getInfoAboutOrder()
  },
  methods: {
    getInfoAboutOrder() {
      getOrders.execute({ id: this.id }).then(res => {
        this.dataOrder = res.data.item
      })
    },
    payOrder(id) {
      payOrder.execute({ id }).then(result => {
        window.location.href = result.redirect_url
      })
    },
  },
}
</script>
