import Sorting from '@app/Filters/Sorting'
import SelectWidget from '@app/Filters/Widgets/SelectWidget'
import MultiselectWidget from '@app/Filters/Widgets/MultiselectWidget'
import CheckboxWidget from '@app/Filters/Widgets/CheckboxWidget'
import DatePickerWidget from '@app/Filters/Widgets/DatePickerWidget'
import DateRangeWidget from '@app/Filters/Widgets/DateRangeWidget'
import CombinedInputWidget from '@app/Filters/Widgets/CombinedInputWidget'
import ColorsWidget from '@app/Filters/Widgets/ColorsWidget'
import AutocompleteMultiselectWidget from '@app/Filters/Widgets/AutocompleteMultiselectWidget'
import RangeWithVariantsWidget from '@app/Filters/Widgets/RangeWithVariantsWidget'
import SimpleMultiselectWidget from '@app/Filters/Widgets/SimpleMultiselectWidget'
import InputWidget from '@app/Filters/Widgets/InputWidget'
import SearchWidget from '@app/Filters/Widgets/SearchWidget'
import RadioWidget from '@app/Filters/Widgets/RadioWidget'
import BooleanWidget from '@app/Filters/Widgets/BooleanWidget'
import TextureWidget from '@app/Filters/Widgets/TextureWidget'
import HybridWidget from '@app/Filters/Widgets/HybridWidget'

import {
  getAttributeValuesResource,
} from '@api/catalog.service'

const WIDGETS_MAP = {
  range: RangeWithVariantsWidget,
  'date-range': DateRangeWidget,
  'date-picker': DatePickerWidget,
  boolean: BooleanWidget,
  radio: RadioWidget,
  color: ColorsWidget,
  input: InputWidget,
  search: SearchWidget,
  select: SelectWidget,
  checkbox: CheckboxWidget,
  texture: TextureWidget,
  hybrid: HybridWidget,
  'combined-input': CombinedInputWidget,
  'autocomplete-multiselect': AutocompleteMultiselectWidget,
  'simple-select': MultiselectWidget,
  'simple-multiselect': SimpleMultiselectWidget,
}

export default {
  components: {
    Sorting,
  },
  widgetsMap: WIDGETS_MAP,
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'sortlist',
    'show_all',
    'label',
  ],
  data() {
    return {
      filterAttrs: [],
      filterGroups: [],
      newFilters: [],
      search: '',
      arrayForHelp: [],
      arrayForDeepElement: [],
    }
  },
  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,

      }
    },
    baseFilters() {
      return this.getAvailableFilters(this.filters)
      // .filter(filter => filter.is_base);
    },
    exclusiveFilters() {
      // return this.getAvailableFilters(this.filters)
      // .filter(filter => !filter.is_base);
    },
    scopedFilters() {
      // console.log(this.baseFilters);
      return [
        {
          condition: true,
          filters: this.baseFilters,
        },
        // {
        //   condition: this.show_all,
        //   filters: this.exclusiveFilters,
        // },
      ]
    },
  },
  mounted() {
    if (this.$store) {
      this.$store.subscribe(mutation => {
        if ('search/SET_SEARCH' === mutation.type) {
          this.search = mutation.payload
          this.onlyValue('search', this.search)
        }
      })
    }
  },
  methods: {
    async searchValues({ id, props }, all) {
      props.has_search = true
      const { search } = props
      const { data } = (await getAttributeValuesResource.execute({ id, search, category: this.label }))
      all.values = data
      console.log(data);
    },
    selectAll(label, all) {
      this.updateValue(label, all.map(e => e.props.label))
    },
    getMultiValue(key) {
      return {
        min_value: this.value[`${key}_min`],
        max_value: this.value[`${key}_max`],
      }
    },
    getAvailableFilters(filters = []) {
      this.newFilters = []
      if (!filters) return []
      if (this.filters.item) {
        this.filterGroups = this.filters.item.filter_groups
        this.filterAttrs = this.filters.item.filter_attrs
      }
      /* eslint-disable no-restricted-syntax */
      this.filterGroups.forEach((element, i) => {
        this.newFilters.push(element)
        if (1 === element.attrs.length) {
          element.attrs.forEach(elem => {
            for (const key in this.filterAttrs) {
              if (this.filterAttrs[key].slug === elem) {
                this.newFilters[i].ch = this.filterAttrs[key]
              }
            }
          })
        } else {
          this.arrayForDeepElement = []
          element.attrs.forEach(elem => {
            for (const key in this.filterAttrs) {
              if (this.filterAttrs[key].slug === elem) {
                if (element.is_has_subgroup) {
                  this.arrayForDeepElement.push(this.filterAttrs[key])
                  this.newFilters[i].ch = this.arrayForDeepElement
                } else {
                  this.arrayForDeepElement.push(this.filterAttrs[key])
                  this.newFilters[i].ch = this.arrayForDeepElement
                }
              }
            }
          })
        }
      })
      return this.newFilters
      // .filter(el => !(!el && !el.props.has_search))
      // .filter(filter => filter.ch.input_widget in this.$options.widgetsMap)
    },
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },
    updateValue(key, value) {
      console.log(key, value)
      this.$emit('input', { ...this.value, [key]: value })
    },
    updateMultipleValues(key, values) {
      let res = {}
      Object.keys(values).forEach(k => {
        res = { ...res, [`${key}_${k}`]: values[k] }
      })
      this.$emit('input', Object.assign(this.value, res))
    },
  },
}
