<template lang="pug">
  include /mixins.pug
  +b.ui-uploader
    +e.content
      +e.wrapper(:class="{'is-disabled': files.length >= maxFiles}")
        label
          slot(name="button")
          input(
            ref="inputFile"
            style="display:none"
            type="file"
            :id="inputId"
            :name="inputId"
            :accept="acceptedFilesLocal"
            :multiple="multiple"
            @change="onFileChange"
          )
    +e.list
      +b.g-row
        +b.g-cell.g-cols(
          v-for="(file, index) in files"
        )
          +b.i-file
            +e.A.link(
              v-if="!file.isUploaded"
              target="_blank"
              :href="file.src"
            )
            +e.icon
              +b.I.icon-file
            +e.content
              +e.SPAN.title {{ file.name }}
              +e.SPAN.size {{ file.size }}
            +e.close(@click.prevent="removeFile(index)")
              +b.I.icon-close2
          validation-provider(
            tag="div"
            v-slot="{errors}"
            :name="'files_' + index"
          )
            +b.ds-panel.--space_xs
              +e.element.--offset_top
                +b.P.error-label {{ errors[0] }}
    +e.errors
      +b.P.error-label(v-if="fileErorrs.limit") {{ _("Перевищено максимальну кількість файлів для завантаження") }}
      +b.P.error-label(v-if="fileErorrs.size") {{ _("Перевищена максимальна вага файла для завантаження") }}
      +b.P.error-label(v-if="fileErorrs.type") {{ _("Не підтримуваний формат файлу") }}
      slot(name="required")
</template>
<script>
import { getMimeType } from './images'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    inputId: {
      type: String,
      default: 'upload',
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    acceptedFiles: {
      type: Array,
      default: () => (['jpg', 'jpeg', 'png']),
    },
    multiple: {
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      acceptedFilesLocal: '',
      bytes: 1000,
      megabyte: 1048576,
      files: [],
      zero: 0,
      one: 1,
      fileErorrs: {
        limit: false,
        size: false,
        type: false,
      },
      file: {},
    }
  },

  mounted() {
    this.acceptedFilesLocal = this.acceptedFiles.map(f => `.${f}`).join(',')
  },

  methods: {
    onFileChange(e) {
      this.fileErorrs.size = false

      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return

      this.createFile(files)
    },

    emitFiles() {
      const timeout = 100

      setTimeout(() => {
        this.$emit('input', this.files)

        this.$refs.inputFile.value = ''
      }, timeout)
    },

    async createFile(files) {
      await this.validateFile(files)

      const timeout = 300

      setTimeout(() => {
        const isValid = this.checkErrors()

        if (!isValid) return

        Object.keys(files).forEach(el => {
          const file = files[el]
          const reader = new FileReader()

          reader.onload = e => {
            const { result } = e.target
            const fileName = file.name
            const data = `${fileName};${result}`

            this.file = {
              src: result,
              file: result,
              data,
              type: getMimeType(result, file.type),
              name: file.name,
            }
            this.files.push(this.file)

            this.emitFiles()
          }

          reader.readAsDataURL(file)
        })
      }, timeout)
    },

    checkErrors() {
      return Object.values(this.fileErorrs).every(el => !el)
    },

    async validateFile(files) {
      this.validateFileFormat(files)
      this.validateFilesLength(files)
      this.validateFileSize(files)
    },

    validateFileFormat(files) {
      this.fileErorrs.type = false

      const isValid = Object.keys(files).every(key => {
        const splittedData = files[key].name.split('.')
        const format = splittedData[splittedData.length - this.one]

        return this.acceptedFiles.includes(format)
      })

      this.fileErorrs.type = !isValid
    },

    validateFilesLength(files) {
      this.fileErorrs.limit = false
      // Check if files limit is valid
      // eslint-disable-next-line
      if (files.length > parseInt(this.maxFiles)) {
        this.fileErorrs.limit = true
      }
    },

    validateFileSize(files) {
      // eslint-disable-next-line
      const maxSize = parseInt(this.maxSize) * this.megabyte
      // Check if files size is valid
      this.fileErorrs.size = Object.keys(files).some(el => files[el].size > maxSize)
    },

    async removeFile(index) {
      this.files.splice(index, this.one)

      this.emitFiles()
    },

    resetFiles() {
      this.files = []
    },
  },
}
</script>
