import CatalogLK from './CatalogLK'
import OrderLK from './OrderLK'
import FavoritesProducts from './FavoritesProducts'
import FavoriteProduct from './FavoriteProduct'
import CardProfile from './CardProfile'

export default function install(Vue) {
  Vue.component('CardProfile', CardProfile)
  Vue.component('CatalogLK', CatalogLK)
  Vue.component('OrderLK', OrderLK)
  Vue.component('FavoritesProducts', FavoritesProducts)
  Vue.component('FavoriteProduct', FavoriteProduct)
}
