import PaginatedResourceController from './PaginatedResourceController';
// import InfiniteResultsCollector from './InfiniteResultsCollector';
import ResourceLoaderController from './ResourceLoaderController';
import PaginationController from './PaginationController';
// import DelayedExecutorController from './DelayedExecutorController';

function install(Vue) {
  Vue.component('PaginatedResourceController', PaginatedResourceController);
  // Vue.component(InfiniteResultsCollector.name, InfiniteResultsCollector);
  Vue.component(ResourceLoaderController.name, ResourceLoaderController);
  Vue.component(PaginationController.name, PaginationController);
  // Vue.component(DelayedExecutorController.name, DelayedExecutorController);
}

export default {
  install,
};
