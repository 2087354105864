import shareon from 'shareon'
import resizeWindow from './resize-window'
import { saveLinkOfCatalog } from './save-link-of-catalog'
import { logoBlocked } from './block-logo'
import { scrollArchon } from './scroll'
import { stickyHeader } from './sticky-header'
import { loader } from './loader'
import { deleteHighLights } from './delete-highlights'
import { blockUser } from './blocking-user'

document.addEventListener('DOMContentLoaded', () => {
  blockUser()
  logoBlocked()
  scrollArchon()
  stickyHeader()
  shareon()
  loader()
  resizeWindow()
  saveLinkOfCatalog()
  deleteHighLights()
})
