<template lang="pug">
  include /mixins
  +b.av_space_pt--5
    +b.form-submit
      +b.H1.ds-caption.--color_white.--variant_uppercase.--weight_regular {{ _('become a partner')}}
      +b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag="form")
        +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
        row(
          appearance='spaced'
          space='xl'
          justify='center'
        )
          cell.relative(
            v-for='field in fields'
            :cols='field.cols'
            :key='field.name'
          )
            +b.av_space_mt--5
              +b.TAG.follow-input(
                v-bem:d-control-input="{'variant': 'dark-second'}"
                :key='field.name'
                :name='field.name'
                :tag='field.tag || "d-control-input"'
                :rules='field.rules'
                :type='field.type || "text"'
                :ref='field.name'
                :input-label='field.label'
                :placeholder='field.placeholder'
                v-model='form[field.name]'
                :class='{"is-textarea": field.tag }'
                v-on='field.events'
                :options='field.options'
                v-bind='field.props'
                :string='field.string'
                autocomplete="off"
                :resize='field.resize',
              )
              //- +b.clear-button.ds-caption.--color_white.--pointer.--hover(@click='clearInput(field.name)')(v-if='field.string == "province" || field.string == "city" || field.string == "zip"') ×
              //- +b.UL.block-results-search(v-if='provinces.length > 1 && field.string == "province" && focusProvince')
              //-   //- +b.LI.block(v-for='items in provinces.slice(0, paginationLocation)')
              //-   +b.LI.block(v-for='items in provinces')
              //-     +e.fdgdf.ds-caption.--color_white.--pointer.--hover(@click='getGeoClick(field.link, field.string, items.title, {  page: 1}, items.id)') {{items.title}}
              //- +b.UL.block-results-search(v-if='cities.length > 1 && field.string == "city" && focusCity')
              //-   //- +b.LI.block(v-for='items in cities.slice(0, paginationLocation)')
              //-   +b.LI.block(v-for='items in cities')
              //-     +e.fdgdf.ds-caption.--color_white.--pointer.--hover(@click='getGeoClick(field.link, field.string, items.title, {  page: 1, subdivision: subdId,}, items.id)') {{items.title}} {{items.id}}
              //- +b.UL.block-results-search(v-if='zips.length && field.string == "zip" && focusZip')
              //-   //- +b.LI.block(v-for='items in zips.slice(0, paginationLocation)')
              //-   +b.LI.block(v-for='items in zips')
              //-     +e.fdgdf.ds-caption.--color_white.--pointer.--hover(@click='getGeoClick(field.link, field.string, items.title, {  page: 1, city: cityId}, items.id)') {{items.title}}
        +b.av_space_mt--5
          +b.wrap-checkboxes-form(v-for='field in checkboxes')
            +b.g-row
              +b.g-cell.--align_center.g-cols--1
                +b.TAG.follow-input(
                  v-bem:d-control-input="{'variant': 'dark-checkbox'}"
                  :id='field.name'
                  :key='field.name'
                  :name='field.name'
                  :tag='field.tag || "d-control-input"'
                  :rules='field.rules'
                  :type='field.type || "text"'
                  :ref='field.name'
                  :input-label='field.label'
                  :placeholder='field.placeholder'
                  v-model='form[field.name]'
                  :class='{"is-textarea": field.tag }'
                  :value='form[field.name]'
                ) {{form[field.name]}}
              +b.g-cell.--align_end.g-cols--10
                +b.LABEL(:for='field.name')
                  +b.A.ds-caption.--hover.--pointer.--color_white.--size.--size_2xs(
                    :href='url'
                    target='_blank'
                    v-if='field.name === "is_accept"'
                  ) {{field.content}}
                  +b.SPAN.ds-caption.--hover.--pointer.--color_white.--size.--size_2xs(v-else) {{field.content}}
        +b.av_space_mt--8
          +b.g-row.--justify_center.--justify_end-sm
            +b.g-cell.g-cols
              +b.BUTTON.av_button--main.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('send') }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  // searchCountry,
  feedback,
  // city,
  // searchZip,
  // searchGeo,
  currentUser,
} from '@api/communication.service'

import {
  masking,
} from '@utils/input-mask'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  // props: {
  //   info: {
  //     close: String,
  //   },
  // },
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      url: null,
      nonFieldErrors: '',
      arrayForItems: [],
      // paginationLocation: 0,
      fal: 'false',
      zips: [],
      id: 0,
      fields: [
        {
          name: 'company_name',
          placeholder: this._('Company Name *'),
          cols: '12 6-sm',
          rules: 'required',
          label: '',
          events: '',
        },
        {
          name: 'contact_person',
          placeholder: this._('Contact Person *'),
          cols: '12 6-sm',
          rules: 'required',
          label: '',
          events: '',
        },
        {
          name: 'address_line_one',
          placeholder: this._('Street Address'),
          cols: '12 6-sm',
          label: '',
          events: '',
        },
        {
          name: 'address_line_two',
          placeholder: this._('Street Address Line 2'),
          cols: '12 6-sm',
          label: '',
          events: '',
        },
        {
          name: 'subdivision',
          placeholder: this._('Province'),
          cols: '12 4-sm',
          type: 'text',
          label: '',
          tag: 'd-control-input',
          string: 'province',
          value: '',
        },
        {
          name: 'city',
          placeholder: this._('City'),
          cols: '12 4-sm',
          label: '',
          type: 'text',
          tag: 'd-control-input',
        },
        {
          name: 'postal_code',
          placeholder: this._('Postal Code *'),
          cols: '12 4-sm',
          label: '',
          type: 'text',
          tag: 'd-control-input',
          rules: 'required',
        },
        // {
        //   link: searchCountry,
        //   name: 'subdivision',
        //   placeholder: this._('Province'),
        //   cols: '12 4-sm',
        //   type: 'text',
        //   label: '',
        //   tag: 'd-control-input',
        //   string: 'province',
        //   options: [],
        //   events: {
        //     click: $event => this.getData($event,
        //       searchCountry,
        //       {
        //         search: this.form.subdivision,
        //         page: 1,
        //       },
        //       'province'),
        //     keyup: $event => this.getGeo($event,
        //       this.provinces,
        //       this.form.subdivision,
        //       searchCountry,
        //       {
        //         search: this.form.subdivision,
        //         page: 1,
        //       },
        //       'province'),
        //     // keyup: $event => this.getCountry($event),
        //   },
        //   value: '',
        // },
        // {
        //   link: city,
        //   name: 'city',
        //   placeholder: this._('City'),
        //   cols: '12 4-sm',
        //   label: '',
        //   type: 'text',
        //   tag: 'd-control-input',
        //   string: 'city',
        //   options: ['label: topic'],
        //   events: {
        //     click: $event => this.getData($event,
        //       city,
        //       {
        //         subdivision: this.subdId,
        //         search: this.form.city,
        //         page: 1,
        //       },
        //       'city'),
        //     keyup: $event => this.getGeo($event,
        //       this.cities,
        //       this.form.city,
        //       city,
        //       {
        //         subdivision: this.subdId,
        //         search: this.form.city,
        //         page: 1,
        //       },
        //       'city'),
        //   },
        // },
        // {
        //   link: searchZip,
        //   name: 'postal_code',
        //   placeholder: this._('Postal Code *'),
        //   cols: '12 4-sm',
        //   label: '',
        //   type: 'text',
        //   tag: 'd-control-input',
        //   string: 'zip',
        //   rules: 'required',
        //   options: [],
        //   events: {
        //     click: $event => this.getData($event,
        //       searchZip,
        //       {
        //         city: this.cityId,
        //         search: this.form.postal_code,
        //         page: 1,
        //       },
        //       'zip'),
        //     keyup: $event => this.getGeo($event,
        //       this.zips,
        //       this.form.postal_code,
        //       searchZip,
        //       {
        //         city: this.cityId,
        //         search: this.form.postal_code,
        //         page: 1,
        //       },
        //       'zip'),
        //   },
        // },
        {
          name: 'phone',
          placeholder: this._('Enter your phone *'),
          cols: '12 6-sm',
          rules: 'required|phone',
          label: '',
          events: '',
        },
        {
          name: 'email',
          placeholder: 'E-mail *',
          cols: '12 6-sm',
          rules: 'required',
          label: '',
          events: '',
        },
        {
          name: 'message',
          placeholder: this._('Enter your message'),
          tag: 'd-control-textarea',
          cols: '12',
          type: 'textarea',
          resize: 'none',
          rules: '',
          label: '',
          events: '',
        },
      ],
      checkboxes: [
        {
          link: '/terms-and-conditions/',
          content: this._('I would like to subscribe to the AspireVision newsletter'),
          name: 'is_subscribe',
          tag: 'd-control-static-input',
          type: 'checkbox',
          // label: 'I would like to subscribe to the AspireVision newsletter',
          value: 'false',
        },
        {
          link: '',
          content: this._('I would like to become a Partner'),
          name: 'is_partner',
          tag: 'd-control-static-input',
          type: 'checkbox',
          // label: 'I would like to become a Partner',
          value: 'false',
        },
        {
          link: '/privacy-policy/',
          content: this._('I have read and accept the privacy policy *'),
          name: 'is_accept',
          tag: 'd-control-static-input',
          type: 'checkbox',
          rules: 'required',
          // label: 'I have read and accept the privacy policy ',
          value: 'false',
        },
      ],
      form: {
        company_name: '',
        contact_person: '',
        address_line_one: '',
        address_line_two: '',
        email: '',
        phone: '',
        message: null,
        subdivision: '',
        postal_code: this.nameInp,
        is_subscribe: 'false',
        city: '',
        is_partner: 'false',
        is_accept: 'false',
      },
    }
  },
  async mounted() {
    const prefix = 'en' === window.language ? '' : '/fr'
    this.url = `${window.location.origin}${prefix}/privacy-policy/`
    // this.focus()
    masking('phone', this) // work with mask
    currentUser.execute({}).then(({ data }) => {
      this.form.company_name = data.retailer_title
      this.form.contact_person = data.fullname
      this.form.email = data.email
    })
  },
  methods: {
    // focus() {
    //   const form = Array.from(document.getElementsByClassName('form-submit'))
    //   form.forEach(el => {
    //     el.addEventListener('click', e => {
    //       const nameInput = e.target.getAttribute('name')
    //       if ('subdivision' === nameInput) {
    //         this.focusProvince = true
    //         this.focusCity = false
    //         this.focusZip = false
    //       } else if ('city' === nameInput) {
    //         this.focusProvince = false
    //         this.focusCity = true
    //         this.focusZip = false
    //       } else if ('postal_code' === nameInput) {
    //         this.focusProvince = false
    //         this.focusCity = false
    //         this.focusZip = true
    //       } else {
    //         this.focusProvince = false
    //         this.focusCity = false
    //         this.focusZip = false
    //       }
    //     })
    //   })
    // },
    clearInput(name) {
      console.log(name);
      // if ('subdivision' === name) {
      //   this.form.subdivision = ''
      //   this.form.city = ''
      //   this.form.postal_code = ''
      // } else if ('city' === name) {
      //   this.form.city = ''
      //   this.form.postal_code = ''
      // } else {
      //   this.form.postal_code = ''
      // }
    },
    // getLocation(value) {
    //   const { data } = (searchGeo.execute({
    //     search: value,
    //   }).then(res => {
    //     console.log(this.cityId)
    //     console.log(res.data)
    //     const currentLocationProvince = res.data.find(el => el.subdivision.id === this.subdId)
    //     const currentLocationCity = res.data.find(el => el.city.id === this.cityId)
    //     this.nameCity = currentLocationCity.city.title
    //     this.nameProvince = currentLocationProvince.subdivision.title
    //     console.log(data)
    //   }))
    // },
    // getData(event, link, toSend, to) {
    //   const { data } = (link.execute({
    //     ...toSend,
    //   }).then(res => {
    //     console.log(data)
    //     if ('province' === to) {
    //       // this.paginationLocation = res.data.pagination.page_size
    //       this.provinces = res.data.items
    //     } else if ('city' === to) {
    //       this.cities = res.data.items
    //     } else {
    //       this.zips = res.data.items
    //       // if (1 === this.zips.length) {
    //       //   this.id = this.zips[0].id
    //       //   this.form.postal_code = this.zips[0].title
    //       //   this.getLocation(this.zips[0].title)
    //       // }
    //     }
    //   }))
    // },
    /* eslint-disable no-constant-condition */
    // getGeo(event, array, input, link, toSend, to) {
    //   if ('ArrowRight' || 'ArrowUp' || 'ArrowLeft' || 'ArrowDown' !== event.code) {
    //     this.arrayForItems = array
    //     if (0 === input.length) {
    //       this.arrayForItems = []
    //     }
    //     if (0 < input.length) {
    //       const { data } = (link.execute({
    //         ...toSend,
    //       }).then(res => {
    //         if ('province' === to) {
    //           console.log(res.data.items);
    //           this.provinces = res.data.items
    //         } else if ('city' === to) {
    //           this.cities = res.data.items
    //         } else {
    //           this.zips = res.data.items
    //         }
    //         console.log(data)
    //         this.arrayForItems = res.data.items
    //         if (1 === this.arrayForItems.length) {
    //           if ('province' === to) {
    //             this.subdId = this.arrayForItems[0].id
    //             this.provinces = res.data.items
    //             // this.form.subdivision = this.arrayForItems[0].title
    //           } else if ('city' === to) {
    //             this.cityId = this.arrayForItems[0].id
    //             this.cities = res.data.items
    //             // this.form.city = this.arrayForItems[0].title
    //           } else {
    //             this.id = this.arrayForItems[0].id
    //             this.zips = res.data.items
    //             // this.form.postal_code = this.arrayForItems[0].title
    //             this.getLocation(this.arrayForItems[0].title)
    //           }
    //         }
    //       }))
    //     }
    //   }
    // },
    // getGeoClick(link, string, name, toSend, id) {
    //   console.log(name, id)
    //   if ('province' === string) {
    //     this.form.subdivision = name
    //     const { data } = (link.execute({
    //       ...toSend,
    //       search: this.form.subdivision,
    //     }).then(res => {
    //       this.provinces = res.data.items
    //       this.subdId = id
    //       console.log(data)
    //     }))
    //   } else if ('city' === string) {
    //     this.form.city = name
    //     const { data } = (link.execute({
    //       ...toSend,
    //       search: this.form.city,
    //     }).then(res => {
    //       this.cities = res.data.items
    //       this.cityId = id
    //       console.log(data)
    //     }))
    //   } else {
    //     this.form.postal_code = name
    //     const { data } = (link.execute({
    //       ...toSend,
    //       search: this.form.city,
    //     }).then(res => {
    //       this.zips = res.data.items
    //       this.id = id
    //       console.log(data)
    //       this.getLocation(name)
    //     }))
    //   }
    // },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      console.log(parsed.non_field_errors);
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(valid) {
      console.log(this.form.city)
      console.log(valid)
      this.$refs.validator.reset()
      return feedback.execute({}, this.form).then(res => {
        console.log(this.form)
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        this.$emit('subm', {
          subm: true,
        })
        Object.entries(this.form).forEach(([key, value]) => {
          if ('message' === key) {
            this.form[key] = null
          } else if ('is_subscribe' === key || 'is_accept' === key || 'is_partner' === key) {
            this.form[key] = false
            this.form[value] = false
            document.querySelector(`input[name=${key}]`).checked = false
          } else {
            this.form[key] = ''
            this.zips = []
            this.citites = []
            this.provinces = []
          }
        })
        AnswerMessage(message, this)
      })
    },
  },
}
</script>
