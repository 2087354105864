import {
  getQuantityOfLike,
} from '@api/getItems.service'

export const likeQuantity = {
  namespaced: true,
  state: {
    countLike: null,
  },
  actions: {
    getQuantityOfLikes({ commit }) {
      getQuantityOfLike.execute({}).then(({ data: { item: { count } } }) => {
        commit('SET_QUANTITY', count)
      })
    },
  },
  mutations: {
    /* eslint-disable no-return-assign */
    SET_QUANTITY: (state, count) => state.countLike = count,
  },
}
