<template lang="pug">
  include /mixins
  +b.form-count.relative(
    :class='{"disabled disabled--form": currentQuantity < 1}'
  )
    +b.error-for-button.--another-top.--another-width(v-if='customError') {{_('You can`t buy anymore')}}
    +b.error-for-button.--another-top(v-if='error') {{error}}
    +b.g-row.--justify_around.--space_none
      +b.g-cell.--align_center.g-cols
        +b.g-row.--space_none
          +b.g-cell.g-cols
            +b.BUTTON.av_button--count-second(@click.prevent='minusCount') -
          +b.g-cell.--align_center.g-cols(v-for='(field,i) in fields')
            +b.D-CONTROL-INPUT.follow-input(
              v-bem:d-control-input="{'variant': 'count-second'}"
              :key='field.name'
              :name='field.name'
              type='number'
              v-model='form[field.name]'
              @input='addToCard($event)'
              )
            +b.block
          +b.g-cell.g-cols
            +b.BUTTON.av_button--count-second(@click.prevent='plusCount') +
    +b.ds-caption.--demi-bold.--size_sm(v-if='messagetrigger') {{ _('You can buy maximum')}} {{maxQuantity}} {{_('products')}}
</template>

<script>

import {
  getCard,
  buyProduct,
  productAddToCard,
} from '@api/communication.service'
import { mapActions } from 'vuex'

export default {
  props: ['id', 'quantity', 'max-quantity', 'current-quantity'],
  name: 'SearchForm',
  data() {
    return {
      customError: false,
      messagetrigger: false,
      nonFieldErrors: [],
      lastNumber: this.quantity,
      fields: [
        {
          name: 'count',
          type: 'text',
        },
      ],
      form: {
        count: this.quantity,
        // recaptcha: 'dummy_value',
      },
      arrayOrders: [],
      error: null,
      test: 0,
      dataProduct: {
        entities: [{
          element: {
            type: 'products.productvariant',
            id: this.id,
          },
          parameters: {},
          quantity: this.quantity,
        }],
      },
    }
  },
  methods: {
    ...mapActions('cartQuantity', [
      'getQuantityOfCart',
    ]),
    executeAdd(count) {
      this.dataProduct.entities[0].quantity = count
      productAddToCard.execute({}, this.dataProduct)
        .then(() => {
          this.$emit('getOrders')
        })
        .catch(res => {
          const error = res.json()
          error.then(er => {
            this.$emit('activatePreloader', false)
            this.error = er.errors[0].state[0].message
            setTimeout(() => {
              this.error = null
            }, 3000)
          })
        })
    },
    deleteProduct() {
      this.$emit('activatePreloader', true)
      getCard.execute({}).then(res => {
        this.arrayOrders = res.data.item.products
        this.arrayOrders.forEach(element => {
          if (element.id === this.id) {
            this.dataProduct.entities[0].quantity = -element.count
            buyProduct.execute({}, this.dataProduct).then(() => {})
            this.$emit('activatePreloader', false)
          }
        });
      })
    },
    addToCard() {
      console.log(this.form.count);
      if (Number(this.form.count) > this.maxQuantity) {
        this.$emit('activatePreloader', true)
        this.form.count = this.maxQuantity
        this.messagetrigger = true
        setTimeout(() => {
          this.messagetrigger = false
        }, 3000)
      }
      if (this.lastNumber < Number(this.form.count) && '' !== this.form.count) {
        this.lastNumber = Number(this.form.count) - this.lastNumber
        this.executeAdd(this.lastNumber)
        this.lastNumber = Number(this.form.count)
      } else if (this.lastNumber > Number(this.form.count) && '' !== this.form.count) {
        this.lastNumber = Number(this.form.count) + -this.lastNumber
        this.executeAdd(this.lastNumber)
        this.lastNumber = Number(this.form.count)
      }
    },
    plusCount() {
      this.$emit('activatePreloader', true)
      if (this.maxQuantity > this.form.count) {
        Number(this.form.count++)
        this.lastNumber = Number(this.form.count) - this.lastNumber
        this.executeAdd(this.lastNumber)
        this.lastNumber = Number(this.form.count)
      } else {
        this.$emit('activatePreloader', false)
        this.customError = true
        setTimeout(() => {
          this.customError = false
        }, 5000)
      }
    },
    minusCount() {
      if (1 < this.form.count) {
        this.$emit('activatePreloader', true)
        Number(this.form.count--)
        this.lastNumber = Number(this.form.count) + -this.lastNumber
        this.executeAdd(this.lastNumber)
        this.lastNumber = Number(this.form.count)
      }
    },
  },
}
</script>
