<template lang="pug">
  include /mixins
  +b.block
    +b.VUE-RENDERER(
      :result='items'
    )
</template>

<script>
import { catalogListResource } from '@/js/services/cabinet'

export default {
  props: ['array', 'label'],
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    return catalogListResource.execute({ label: this.label }).then(res => {
      this.items = res.data
      // this.$emit('pagination', res.pagination.limit, res.pagination.offset, res.pagination.totla)
    })
  },
}
</script>
