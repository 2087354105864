<template lang="pug">
include /mixins
+b.form-submit--lg.bt-styles
  +b.A.warranty-form-title-link(href="https://aspirevision.com/")
    +b.IMG.warranty-img(
      src="https://aspirevision.com/uploads/organization/avg_logo_for_site-1-svg_1fmKgCS.svg"
      width='300px'
      alt="Aspire Vision Group"
    )
  +b.H1.warranty-title {{ _('Warranty Request Form')}}
  +b.VALIDATION-OBSERVER(
    ref="validator"
    v-slot="{ valid }",
    tag="form"
    enctype="multipart/form-data"
  )
    +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
    row(
      appearance='spaced'
      space='xl'
      justify='center'
    )
      cell.relative(
        v-for='field in fields'
        :cols='field.cols'
        :key='field.name'
      )
        +b.av_space_mt--5
          validation-provider(
            tag="div"
            v-slot="{ errors }"
            :rules='field.rules'
            :name='`${field.name}`'
            :id='`provider_id_${field.name}`'
            :key='`input_provider_${field.name}`'
          )
            label.form-label(:for="field.name") {{ field.label }}
            component.form-control(
              @input="check($event, field.name)"
              :is="field.tag || 'input'"
              :name='field.name'
              :type='field.type || "text"'
              v-model='form[field.name]'
              :id='field.name'
              :key='`input_${field.name}`'
              :value.prop="form[field.name]"
              autocomplete="off"
              :placeholder="field.placeholder"
            )
            +b.P.form-control-error(v-if="errors[0]") {{ errors[0] }}
      cell.relative(
        cols='12'
      )
        validation-provider(
          tag="div"
          v-slot="{ errors }"
          :rules="{ required: false }"
        )
          ui-uploader(
            v-model="form.files"
            :accepted-files="['pdf', 'jpg', 'png']"
            :max-size="5"
            :max-files="5"
            :multiple="true"
            :disabled="true"
          )
            template(slot="button")
              label.form-label {{ _("Pictures of frames") }}
              label.form-label--sm {{ _("Please attach pictures of frames showing defect, model and colour.") }}
              +b.control-button
                +e.element
                  +b.SPAN.warranty-btn--files {{ _("Choose files") }}
            template(slot="required" v-if="errors[0]")
              +b.P.error-label {{ errors[0] }}
    +b.av_space_mt--8
      +b.g-row.--justify_center.--justify_end-sm
        +b.g-cell.g-cols.warranty-btn-wrapper
          +b.BUTTON.warranty-btn(@click.prevent='prepareData(valid)') {{ _('Submit') }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import moment from 'moment'

import {
  warrantyCreateResource,
  currentUser,
} from '@api/communication.service'

import {
  masking,
} from '@utils/input-mask'

import {
  NewMessageModal,
} from '@utils/message-modal'

export default {
  name: 'FormWarranty',
  mixins: [FormMixin],
  data() {
    return {
      url: null,
      focusProvince: false,
      focusCity: false,
      focusZip: false,
      nonFieldErrors: '',
      provinces: [],
      arrayForItems: [],
      // paginationLocation: 0,
      cities: [],
      fal: 'false',
      zips: [],
      nameProvince: '',
      nameCity: '',
      nameInp: '',
      subdId: 0,
      cityId: 0,
      id: 0,
      fields: [
        {
          name: 'company',
          cols: '12',
          rules: 'required',
          label: this._('Company *'),
          events: '',
        },
        {
          name: 'contact_name',
          cols: '12',
          rules: 'required',
          label: this._('Contact name *'),
          events: '',
        },
        {
          name: 'invoice_number',
          cols: '12 6-sm',
          label: this._('Invoice number'),
          events: '',
        },
        {
          name: 'invoice_date',
          type: 'datetime-local',
          cols: '12 6-sm',
          label: this._('Invoice date'),
          events: '',
        },
        {
          name: 'model_number',
          cols: '12 6-sm',
          rules: 'required',
          type: 'text',
          label: this._('Model number'),
        },
        {
          name: 'colour',
          // placeholder: '',
          cols: '12 6-sm',
          label: this._('Colour *'),
          type: 'text',
          rules: 'required',
        },
        {
          name: 'email',
          cols: '12 6-sm',
          rules: 'required',
          label: this._('Email *'),
          events: '',
        },
        {
          name: 'phone',
          cols: '12 6-sm',
          rules: 'required|phone',
          label: this._('Phone *'),
          events: '',
        },
        {
          name: 'comments',
          label: this._('Comments'),
          placeholder: this._('Briefly describe the problem'),
          cols: '12',
          tag: 'textarea',
          resize: 'none',
          rules: '',
          events: '',
        },
      ],
      form: {
        company: '',
        contact_name: '',
        invoice_number: '',
        invoice_date: null,
        model_number: '',
        colour: '',
        email: null,
        phone: '',
        comments: '',
        files: [],
      },
    }
  },
  async mounted() {
    masking('phone', this) // work with mask
    currentUser.execute({}).then(({ data }) => {
      console.log(this.form)
      this.form.company = data.retailer_title
      this.form.contact_name = data.fullname
      this.form.email = data.email
      console.log(this.form)
    })
  },
  methods: {
    check(e, name) {
      console.log(e)
      this.form[name] = e.target.value
    },
    // clearInput(name) {
    //   console.log(name);
    // },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      // console.log(parsed.non_field_errors);
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      // console.log(valid)
      if (!valid) return
      if (this.form.invoice_date) {
        this.form.invoice_date = moment(String(this.form.invoice_date)).format('YYYY-MM-DDThh:mm')
      }
      this.submit(valid, this.form)
    },
    send() {
      this.$refs.validator.reset()
      return warrantyCreateResource.execute({}, this.form).then(res => {
        const message = {
          message: {
            title: res.meta.message.header,
            text1: res.meta.message.text1,
            text2: res.meta.message.text2,
          },
        }
        this.$emit('subm', {
          subm: true,
        })
        NewMessageModal(message, this)
      })
    },
  },
}
</script>
