<template lang="pug">
  include /mixins
  +b.form-submit--lg.bt-styles
      +b.A.warranty-form-title-link(href="https://aspirevision.com/")
          +b.IMG.warranty-img(
          src="https://aspirevision.com/uploads/organization/avg_logo_for_site-1-svg_1fmKgCS.svg"
          width='300px'
          alt="Aspire Vision Group"
          )
      +b.H1.warranty-title {{ meta.message.title }}
      +b.P.form-label.--text-center {{ meta.message.text1 }}
      +b.P.form-label.--text-center {{ meta.message.text2 }}
</template>
<script>

export default {
  name: 'MessageModalNew',

  props: ['meta', 'promise', 'timeout'],
  data() {
    return {
      redirectTime: 5000,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.promise !== undefined) {
        this.promise('true')
      }
      if (this.meta.location) {
        window.location.pathname = this.meta.location
      }
      this.$emit('close')
    }, this.redirectTime)
  },
}
</script>
