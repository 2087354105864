<script>
export default {
  props: {
    isExpanded: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: this.isExpanded,
    }
  },

  methods: {
    activateItem() {
      this.isActive = !this.isActive
    },
  },

  render() {
    return this.$scopedSlots.default({
      activateItem: this.activateItem,
      isActive: this.isActive,
    })
  },
}
</script>
