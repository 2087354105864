import { curry } from 'ramda';
import makeTemplateGetter from '@aspectus/resource-template-url-getter';
import {
  defaultDataTransformer,
  jsonTransformer,
} from '../resource-commons';

const filtersGetterBase = makeTemplateGetter('{;filters*}');
const filtersGetter = parameters => filtersGetterBase(parameters).slice(1) || null

// const partGetter = (name, prefix) => {
//   const base = makeTemplateGetter(`${prefix}{/${name}}`);
//   return parameters => {
//     const result = base(parameters);
//     return result === prefix ? null : result;
//   };
// };

// const pageGetter = partGetter('page', 'page');
const labelGetter = makeTemplateGetter('{label}');
const categoryGetter = makeTemplateGetter('{category}');
const getParametersGetter = makeTemplateGetter('{?limit,offset}');

const orderMap = {
  label: labelGetter,
  category: categoryGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  // page: pageGetter,
}

const initOrder = {
  path: [
    'prefix',
    'filters',
  ],
}

export const urlGetter = curry(([prefix, postfix], { path, query = [] }, p) => {
  const base = { prefix, postfix }
  const pathParts = path.reduce((acc, x, i) => {
    acc[i] = orderMap[x] ? orderMap[x](p) : base[x]
    return acc
  }, Array.from({ length: path.length }, () => null))
  const queryParams = query.reduce((acc, x) => {
    acc.push(orderMap[x](p))
    return acc
  }, [])
  return (`${pathParts.filter(x => null !== x).join('/')}/`).replace(/\/\//img, '/').replace(/%2C/g, ',') + queryParams.join('');
});

export const friendlyUrlGenerator = (url, resource, order = initOrder) => resource
  .url(urlGetter(url, order))
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)
