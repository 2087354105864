<template lang="pug">
  include /mixins
  //- +b.wrap
  component(
    :options='options'
    :is='currentTag'
    @init='addScripts'
  )
    slot(
      name='slide',
    )
</template>

<script>
import { scrollArchon } from '@utils/scroll'

export default {
  name: 'UiSlider',
  props: {
    currentTag: {
      default: 'slick',
    },
    options: {},
    currentRef: {
      type: String,
      default: 'slick',
    },
    needAdditionalFns: {
      default: false,
    },
  },
  // beforeUpdate() {
  //   if (this.$refs.slick) {
  //     this.$refs.slick.destroy();
  //   }
  // },
  // updated() {
  //   this.$nextTick(function () {
  //     if (this.$refs.slick) {
  //       this.$refs.slick.create(this.options);
  //     }
  //   });
  // },
  methods: {
    addScripts() {
      // console.log(this.needAdditionalFns);
      if (this.needAdditionalFns) {
        scrollArchon()
      }
    },
  },
}
</script>
