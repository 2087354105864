import Vuex from 'vuex'
import Vue from 'vue'
import { catalog } from './modules/catalog'
/* eslint-disable import/named */
// import { colors } from './modules/colors'
import { colors } from './modules/colors-test'
import { cartQuantity } from './modules/cart'
import { likeQuantity } from './modules/likes'

Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    deep: true,
    modules: {
      catalog,
      colors,
      cartQuantity,
      likeQuantity,
    },
  })
}
