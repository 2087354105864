<template lang="pug">
  include /mixins
  div
    +b.favorites-section(v-if='items.length')
      +b.wrap-navigation-product.--without-margin_2.--ml-left
        button-back(
          :link='lang === "eng" ? "/catalog/" : "/fr/catalog/"'
        )
      +b.wrap-title-blog
        +b.H1.ds-caption.--variant_uppercase.--size_3xl.--weight_regular {{ _('Your favourites') }}
      +b.VUE-RENDERER(
        :result='items'
      )
      +b.g-row.--justify_center
        +b.g-cell.g-cols
          +b.av_space_mt--10
            +b.BUTTON.av_button--main.ds-caption.--color_white.--variant_uppercase(
              @click='getCards'
              v-if='button'
            ) {{ _('show more')}}
    +b.favorites-section(v-else)
      +b.wrap-title-blog
        +b.H1.ds-caption.--variant_uppercase.--size_3xl.--weight_regular {{ _('Your didn`t have any favourites') }}
</template>

<script>
import { favoriteListResource } from '@/js/services/cabinet'
import { eventBus } from '@/js/components/Helpers/eventBus'

export default {
  data() {
    return {
      items: [],
      lang: window.lang,
      page: 0,
      limit: 0,
      total: -1,
      button: true,
    }
  },
  created() {
    console.log('asdsd')
  },
  mounted() {
    this.$emit('state', false)
    /* eslint-disable arrow-body-style */
    eventBus.$on('like:change', () => {
      return favoriteListResource.execute({ offset: this.page }).then(res => {
        this.items = res.data
      })
    })
    this.getCards()
  },
  methods: {
    getCards() {
      /* eslint-disable no-else-return */
      if (this.page !== this.total) {
        return favoriteListResource.execute({ offset: this.page }).then(res => {
          console.log(res);
          this.items += res.data
          this.limit = res.pagination.limit
          this.total = res.pagination.total
          console.log(this.items);
          this.page += this.limit
          if (this.page === this.total || this.page > this.total) {
            this.button = false
          }
        })
      }
      return ''
    },
  },
}
</script>
