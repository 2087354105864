<template lang="pug">
  span.error-label(
    v-bem=''
    :class='classes'
  )
    slot
</template>
<script>
export default {
  name: 'ErrorComponent',
  block: 'error-label',
  props: {
    classes: {},
  },

}
</script>
