<template lang='pug'>
  include /mixins
  +b.g-row.--justify_center.--justify_start-md.--space_none
    portal-target(name='place-for-loader')
    ui-loader(v-if='loading')
    //- +b.g-cell.g-cols--9
    //-   +b.block-toggle-filters
    //-     +b.H3.text.ds-caption.--pointer.--appearance_center.--blair.--color_white.--variant_uppercase(@click='toggleFilters')
    //-       +e.SPAN Open Filters
    +b.g-cell.g-cols.g-cols--10.g-cols--9-md.retainer-width
      +b.row.--justify_center
        +b.block-toggle-filters
          +b.H3.text.ds-caption.--pointer.--appearance_center.--blair.--color_white.--variant_uppercase.link-to-archon(
            @click='toggleFilters'
            data-goto='filtration'
            )
            +e.SPAN(data-goto='filtration') {{ _('Open Filters') }}
      +b.wrapper-for-mobail-filters#filtration
        +b.filters-mobail#filterMobail(v-show='activeFilters && adaptiveCatalog')
          row.delete-touches(
            justify='end'
          )
            cell.adaptive-inf-filters(
              cols='12'
            )
              +b.av_space_mb--5
                row(
                  justify='between'
                )
                  cell
                    portal-target(name='plaсe-for-result')
                  cell
                    +b.ds-caption.--hover.--pointer.--size_2md(@click='toggleFilters') &#10006
            cell(
              cols='12'
            )
              catalog-sidebar.wrapper-filters(
                space="xl"
                :filters="filters"
                @update:filters="changeFiltersHandler"
                :pagination="pagination",
                :attributesResource='attributesResource',
                :availableAttributesResource='availableAttributesResource',
                :sortlist='sortlist'
                :state='state'
                :label='label'
                :activeFilters='activeFilters'
                :filterComponent='filterComponent',
                @change:all="setFilter($event, 'allFiltersChips')"
                @change:prepared="setFilter($event, 'filtersChips')"
              )
              +b.block-toggle-filters
                +b.H3.text.ds-caption.--pointer.--appearance_center.--blair.--color_white.--variant_uppercase(
                  @click='toggleFilters'
                  ) {{ _('apply filters') }}
        //- +b.filters-desctop
          catalog-sidebar(
              space="xl"
              v-if='adaptiveCatalog !=="true" || !adaptiveCatalog'
              :filters="filters"
              @update:filters="changeFiltersHandler"
              :pagination="pagination",
              :attributesResource='attributesResource',
              :availableAttributesResource='availableAttributesResource',
              :sortlist='sortlist'
              :state='state'
              :label='label'
              :filterComponent='filterComponent',
              @change:all="setFilter($event, 'allFiltersChips')"
              @change:prepared="setFilter($event, 'filtersChips')"
            )
    +b.g-cell.g-cols--12.g-cols--6-md.g-cols--7-lg.g-cols--8-b-fhd.g-cols--9-fhd.grow
      +b.catalog-content
        .cell-content.relative()
          +b.av_space_mt--5.deleting-margin
            //- +b.selecet-result.--main
            //-   +b.g-row.--justify_around.--justify_between-md.--space_none(v-if='select')
            //-     +b.g-cell.--align_center.g-cols.g-cols--5-sm.g-cols--4-lg.g-cols--9-xl.gorw
            //-       +b.g-row.--space_none
            //-         +b.g-cell.--align_center.g-cols--12.g-cols--2-xl
            //-           +b.av_space_mb--4-till-lg.av_space_mt--4-till-md
            //-             portal-target(name='plaсe-for-result')
            //-         +b.g-cell.g-cols.--align_center.grow
            //-           search-chips
            //-     +b.g-cell.g-cols--7.g-cols--4-sm.g-cols--3-xl
            //-       portal-target(name='place-for-search')
            +b.selecet-result
              +b.g-row.--justify_between.--space_none(v-if='select')
                +b.g-cell.--align_center.g-cols
                  portal-target(name='plaсe-for-result')
                +b.g-cell.--align_center.g-cols--7.g-cols--4-2lg.g-cols--3-xl
                  portal-target(name='place-for-search')
                +b.g-cell.g-cols--10.--align_center
                  +b.av_space_mt--3
                    search-chips
          content-infinite(
            :label='label'
            :loading="loading"
            ref='content'
            :items="result && result.data"
            :pagination="pagination"
            @update:pagination="changePagination"
          )
            slot
</template>

<script>

import { scrollArchon } from '@utils/scroll'
import { filtrationMobail } from '@utils/filtration-mobail'
import resizeWindow from '@utils/resize-window'
import SearchChips from '@app/List/Chips'
import CatalogSidebar from './Sidebar'
import CatalogContent from './Content'
import ContentInfinite from './ContentInfinite'

export default {
  data() {
    return {
      adaptiveCatalog: false,
      activeFilters: false,
    }
  },
  components: { CatalogSidebar, CatalogContent, SearchChips, ContentInfinite },
  props: [
    'result',
    'loading',
    'select',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'availableAttributesResource',
    'label',
    'sortlist',
    'state',
    'filterComponent',
  ],
  mounted() {
    scrollArchon()
    filtrationMobail()
    this.adaptiveCatalog = resizeWindow(960)
    this.resizer()
  },
  methods: {
    resizer() {
      window.addEventListener('resize', () => {
        this.adaptiveCatalog = resizeWindow(960)
        if (!this.adaptiveCatalog) {
          this.activeFilters = false
          this.$body.dataset.blocked = this.activeFilters
          console.log(this.activeFilters)
        }
      })
    },

    toggleFilters() {
      this.activeFilters = !this.activeFilters
      this.$body.dataset.blocked = this.activeFilters
    },
    changeFiltersHandler(event) {
      this.changeFilters(event)
    },
    search() {
      this.receive(this.parameters)
    },
  },
}

</script>
