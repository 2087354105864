import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import VueBem from '@aspectus/vue-bem'
import Tag from '@aspectus/vue-tag'
import VueGrid from '@aspectus/vue-grid'
import Magnifier from 'vuejs-magnifier'
import PortalVue from 'portal-vue'
import { VueAgile } from 'vue-agile'
import { ObserveVisibility } from 'vue-observe-visibility';
import InnerImageZoom from '@packages/vue-image-zoom';
// import UiClickOutside from '@aspectus/vue-clickaway'
const Slick = () => import('vue-slick')

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(Tag)
  Vue.use(VueGrid)
  Vue.use(Magnifier)
  Vue.use(PortalVue)

  // Vue.use(UiClickOutside)
  Vue.use(VueRouter)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      transition: 'nice-modal-fade',
    },
  })
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(Slick)
  Vue.use(VueAgile)
  Vue.component('InnerImageZoom', InnerImageZoom)
  Vue.component('VueAgile', VueAgile)
  Vue.component('Slick', Slick)
  Vue.directive('observe-visibility', ObserveVisibility);
}

export default { install }
