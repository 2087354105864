<template lang="pug">
  include /mixins
  slim-controller(
    :receive="allProps.receive"
    :all="allProps.result"
    :displayed="displayedFilters"
    :pagination='pagination'
    :loading="allProps.loading"
    :value="value"
    :label="label"
    @input="handleFilter"
    :sortlist='sortlist'
  )
</template>

<script>
import SlimController from '@app/Filters/Slim/Controller'

import ViewMixin from '@app/Filters/mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
  components: {
    SlimController,
  },
}

</script>
