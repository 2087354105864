<template lang="pug">
  include /mixins
  div
    div(
      appearance="spaced"
    )
      slot(
        name="prepend",
        cols="12"
      )
      +b.title-filter
        +e.SPAN.ic.icon-profile-data
        +e.SPAN.text.ds-caption.--size_md.--demi-bold.--variant_uppercase {{ _('filter & sort')}}
      +b.search-item-catalog
        +b.av_space_mb--9
          tag(
            :tag='$options.widgetsMap.search'
            :available="available"
            :value="value['search']"
            :parameters="parameters"
            :disabled="disabled"
            :filter="['search']"
            @input="updateValue('search', $event)"
          )
      //- +b.category(v-if='filters && filters.item && filters.item.filter_categories')
        +b.main-title-filte.ds-caption.--demi-bold.--size_2md.relative {{filters.item.filter_categories.category.title}}
          vue-accordion(
            :expanded='filters.item.filter_categories.category.is_expand'
          )
            portal-target(name='plaсe-for-filter')
            tag(
              :label='label'
              :tag='$options.widgetsMap.radio'
              :available="available"
              :parameters="parameters"
              :disabled="disabled"
              :value="filters.item.filter_categories.category.slug"
              :filter="filters.item.filter_categories.category.choices"
              @input="updateValue(filters.item.filter_categories.category.slug, $event)"
              :deflt='filters.item.filter_categories.category.default_value'
            )
          +b.block-activate-accordion
            +e.SPAN.title.ds-caption.--size_2md.--demi-bold.--hover.--pointer(@click='filters.item.filter_categories.category.is_expand = !filters.item.filter_categories.category.is_expand')
              +e.SPAN(v-if='filters.item.filter_categories.category.is_expand') -
              +e.SPAN(v-else) +
          +b.av_space_mt--7(v-if='!filters.item.filter_categories.category.is_expand')
          +b.block-line-gray.--second
          +b.block-line-gray.--second
      template(
        v-for='scope in scopedFilters'
      )
        div(
          v-for="(filter, i) in scope.filters"
          :key="`filter${filter.id}_scope${i}`"
        )
          +b.line-filter-catalog(v-if='filter.ch && filter.ch[0] && filter.attrs && filter.attrs[0] !== "price"')
            +b.wrap-filters-array.ds-caption.--demi-bold.--size_2md {{filter.title}}
              vue-accordion(
                :expanded='filter.is_expand'
              )
                +b.filters-array.row.--justify_between.mt-helper(v-for='filt in filter.ch')
                  +b.ds-caption.--size_sm(v-if='filt.input_type === "color"') {{filt.title}}
                    tag(
                      :tag="$options.widgetsMap[filt.input_type]"
                      :id='filt.slug'
                      :available="available"
                      :parameters="parameters"
                      :value="value[filt.slug]"
                      :disabled="disabled"
                      :filter="filt.choices"
                      @input='updateValue(filt.slug, $event)'
                      :size="['md', 'lg-lg']"
                      :type='filt.input_widget'
                    )
                  +b.line-filter-catalog.--second.block-max-width(v-else)
                    +b.row
                      +b.av_space_mr--5
                        +b.row.--align_center
                          +b.wrapper-for-message-in-filter
                            +e.img.IMG(:src='filt.image')
                          +b.text.ds-caption.--size_sm {{ filt.title }}
                      +b.ds-caption.--size_sm.--color_light.wrapper-accordion
                          vue-accordion(
                            :expanded='filt.is_expand'
                          )
                            +b.av_space_mt--3
                              tag(
                                kind='sizes'
                                :tag="$options.widgetsMap[filt.input_widget]"
                                :id='filt.slug'
                                :available="available"
                                :parameters="parameters"
                                :value="value[filt.slug]"
                                :disabled="disabled"
                                :filter="filt.choices"
                                @input='updateValue(filt.slug, $event)'
                                :size="['md', 'lg-lg']"
                                :type='filt.input_widget'
                              )
                      +b.block-activate-accordion(@click='filt.is_expand = !filt.is_expand')
                        +e.SPAN.title.ds-caption.--size_2md.--demi-bold.--hover.--pointer
                          +e.SPAN(v-if='filt.is_expand') -
                          +e.SPAN(v-else) +
              +b.av_space_mt--7
                +b.block-line-gray.--second
                +b.block-line-gray.--second
            +b.block-activate-accordion(@click='filter.is_expand = !filter.is_expand')
              +e.SPAN.title.ds-caption.--size_2md.--demi-bold.--hover.--pointer
                +e.SPAN(v-if='filter.is_expand') -
                +e.SPAN(v-else) +
          +b.line-filter-catalog(v-else-if='filter.ch && filter.attrs.length > 0 && filter.ch.input_type === "color"')
            +b.wrap-filters-array.ds-caption.--demi-bold.--size_2md {{filter.title}}
              vue-accordion(
                :expanded='filter.is_expand'
              )
                +b.filters-array.row.--justify_between.mt-helper.colors-array(v-for='filt in filter.ch')
                  +b.ds-caption.--size_sm(v-if='filter.ch.input_type === "color"') {{filter.ch.title}}
                    tag(
                      :tag="$options.widgetsMap[filter.ch.input_type]"
                      :id='filter.ch.slug'
                      :available="available"
                      :parameters="parameters"
                      :value="value[filter.ch.slug]"
                      :disabled="disabled"
                      :filter="filter.ch.choices"
                      @input='updateValue(filter.ch.slug, $event)'
                      :size="['md', 'lg-lg']"
                      :type='filter.ch.input_widget'
                    )
              +b.av_space_mt--7
                +b.block-line-gray.--second
                +b.block-line-gray.--second
            +b.block-activate-accordion(@click='filter.is_expand = !filter.is_expand')
              +e.SPAN.title.ds-caption.--size_2md.--demi-bold.--hover.--pointer
                +e.SPAN(v-if='filter.is_expand') -
                +e.SPAN(v-else) +
          +b.line-filter-catalog(v-else-if='filter.ch && filter.attrs.length > 0 && filter.ch.input_type === "texture"')
            +b.wrap-filters-array.ds-caption.--demi-bold.--size_2md {{filter.title}}
              vue-accordion(
                :expanded='filter.is_expand'
              )
                +b.filters-array.row.--justify_between.mt-helper.colors-array(v-for='filt in filter.ch')
                  +b.ds-caption.--size_sm(v-if='filter.ch.input_type === "texture"') {{filt}}
                    tag(
                      :tag="$options.widgetsMap[filter.ch.input_type]"
                      :id='filter.ch.slug'
                      :available="available"
                      :parameters="parameters"
                      :value="value[filter.ch.slug]"
                      :disabled="disabled"
                      :filter="filter.ch.choices"
                      @input='updateValue(filter.ch.slug, $event)'
                      :size="['md', 'lg-lg']"
                      :type='filter.ch.input_widget'
                    )
              +b.av_space_mt--7
                +b.block-line-gray.--second
                +b.block-line-gray.--second
            +b.block-activate-accordion(@click='filter.is_expand = !filter.is_expand')
              +e.SPAN.title.ds-caption.--size_2md.--demi-bold.--hover.--pointer
                +e.SPAN(v-if='filter.is_expand') -
                +e.SPAN(v-else) +
          +b.line-filter-catalog(v-else-if='filter.ch && filter.attrs.length > 0 && filter.ch.input_type === "hybrid"')
            +b.wrap-filters-array.ds-caption.--demi-bold.--size_2md {{filter.title}}
              vue-accordion(
                :expanded='filter.is_expand'
              )
                +b.filters-array.row.--justify_between.mt-helper.colors-array(v-for='filt in filter.ch')
                  +b.ds-caption.--size_sm(v-if='filter.ch.input_type === "hybrid"') {{filt}}
                    tag(
                      :tag="$options.widgetsMap[filter.ch.input_type]"
                      :id='filter.ch.slug'
                      :available="available"
                      :parameters="parameters"
                      :value="value[filter.ch.slug]"
                      :disabled="disabled"
                      :filter="filter.ch.choices"
                      @input='updateValue(filter.ch.slug, $event)'
                      :size="['md', 'lg-lg']"
                      :type='filter.ch.input_widget'
                    )
              +b.av_space_mt--7
                +b.block-line-gray.--second
                +b.block-line-gray.--second
            +b.block-activate-accordion(@click='filter.is_expand = !filter.is_expand')
              +e.SPAN.title.ds-caption.--size_2md.--demi-bold.--hover.--pointer
                +e.SPAN(v-if='filter.is_expand') -
                +e.SPAN(v-else) +
          +b.line-filter-catalog.row(v-else)
            +b.main-title-filte.ds-caption.--demi-bold.--size_2md {{filter.title}}
              +b.av_space_mb--7
                vue-accordion(
                  :expanded='filter.is_expand'
                )
                  +b.av_space_mt--5
                    +b.first-stage-filters(v-if='filter.ch && filter.ch[0]')
                      +b.last-level-filter(v-for='item in filter.ch')
                        tag(
                            v-if='item.input_type !== "range" && userAuth === "true" && item.slug === "is_promo"'
                            :tag="$options.widgetsMap[item.input_widget]"
                            :id='item.slug'
                            :available="available"
                            :parameters="parameters"
                            :title='item.title'
                            :value="value[item.slug]"
                            :disabled="disabled"
                            :filter="item.choices"
                            @input='updateValue(item.slug, $event)'
                            :size="['md', 'lg-lg']"
                            :type='item.input_widget'
                          )
                        tag(
                            v-if='item.input_type !== "range" && item.slug === "is_novelty"'
                            :tag="$options.widgetsMap[item.input_widget]"
                            :id='item.slug'
                            :available="available"
                            :parameters="parameters"
                            :title='item.title'
                            :value="value[item.slug]"
                            :disabled="disabled"
                            :filter="item.choices"
                            @input='updateValue(item.slug, $event)'
                            :size="['md', 'lg-lg']"
                            :type='item.input_widget'
                          )
                        tag(
                          v-else-if='item.input_type === "range" && userAuth === "true"'
                          :tag='$options.widgetsMap[item.input_type]'
                          :available="available"
                          :parameters="parameters"
                          :disabled="disabled"
                          :values="value[item.slug]"
                          :filter="[item]"
                          @input="updateValue(item.slug, $event)"
                        )
                    +b.first-stage-filters(v-else-if="filter.ch")
                      tag(
                          :tag="$options.widgetsMap[filter.ch.input_widget]"
                          :id='filter.ch.slug'
                          :available="available"
                          :parameters="parameters"
                          :value="value[filter.ch.slug]"
                          :disabled="disabled"
                          :filter="filter.ch.choices"
                          @input='updateValue(filter.ch.slug, $event)'
                          :size="['md', 'lg-lg']"
                          :type='filter.ch.input_widget'
                        )
              +b.block-line-gray.--second
              +b.block-line-gray.--second
            +b.block-activate-accordion(@click='filter.is_expand = !filter.is_expand')
              +e.SPAN.title.ds-caption.--size_2md.--demi-bold.--hover.--pointer
                +e.SPAN(v-if='filter.is_expand') -
                +e.SPAN(v-else) +
</template>

<script>
import VueAccordion from '@ztrehagem/vue-accordion'
import UiMixin from '@app/Filters/mixins/UiMixin'

export default {
  mixins: [UiMixin],
  components: {
    VueAccordion,
  },
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'state',
  ],
  data() {
    return {
      isExpand: false,
      forHelp: [],
      userAuth: window.isAuthenticated,
      counter: 0,
      states: [{ state: false }, { state: false }, { state: false }],
      isNovetly: true,
      search: '',
      arrayForHelp: [],
      search_filter: {
        label: 'search',
        title: this._('Поиск'),
        id: 'search_id',
      },
    }
  },
};
</script>
