import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI, baseAPI } from '@resource/api'

const ACCORDIONS = prefixAPI('/vacancies/list/')
const INFORMATION_ABOUT_PERSON = prefixAPI('/profile/user/personal-data/retrieve/')
const INFORMATION_ABOUT_RETAILER = prefixAPI('/profile/current/user-data/generic/')
const ADDRESS = prefixAPI('/retailer/shipping/location/by-id/list/{?retailer}')
const EMPLOYEES = prefixAPI('/profile/employees/by-retailer/list/{?retailer}')
const BLOCK_PERSONAL = prefixAPI('/profile/block/employee/{id}/generic/')
const COLORS = prefixAPI('/product/product/{id}/group-attrs/')
const PRODUCTS = baseAPI('/catalog/{label}/')
const ORDERS = prefixAPI('/orders/list/')
const LIKE_STATE = prefixAPI('/like/is-like/generic/')
const TEST_COLORS = prefixAPI('/product/detail/{id}/selection-attrs/')
const GET_QUANTITY = prefixAPI('/cart/quantity/')
const LIKE_QUANTITY = prefixAPI('/like/count/')
const GET_DATA_PRODUCT = prefixAPI('/product/variant/{id}/retrieve/')

export const getAccordions = createResource(ACCORDIONS)
export const getProfileInformation = createResource(INFORMATION_ABOUT_PERSON)
export const getRetailerInformation = createResource(INFORMATION_ABOUT_RETAILER)
export const getAddress = createResource(ADDRESS)
export const getEmployees = createResource(EMPLOYEES)
export const doBlockPersonal = createResource(BLOCK_PERSONAL, postResource)
export const getColors = createResource(COLORS)
export const getProdcuts = createResource(PRODUCTS)
export const getListOrders = createResource(ORDERS)
export const getLike = createResource(LIKE_STATE, postResource)
export const getColorsTest = createResource(TEST_COLORS)
export const getQuantityProducts = createResource(GET_QUANTITY)
export const getQuantityOfLike = createResource(LIKE_QUANTITY)
export const changeProduct = createResource(GET_DATA_PRODUCT)
