<template lang="pug">
  include /mixins
  div.counter-like(
    style='display: flex;'

  )
    +b.icon-heart(
      :id='idElement'
      :class='{"icon-like-active": countLike > 0}'
    )
    +b.count-favorites(
      v-if='countLike > 0'
      style='margin-top: -2px; margin-left: 2px;'
    ) {{countLike}}
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    classElement: String,
    count: String,
    idElement: String,
  },
  computed: {
    ...mapState('likeQuantity', ['countLike']),
  },
}
</script>
