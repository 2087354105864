function saver() {
  window.addEventListener('pagehide', () => {
    if (-1 !== window.location.href.indexOf('/catalog/') && -1 !== window.location.href.indexOf('/filters')) {
      sessionStorage.setItem('url', window.location.href)
    }
  })
  // if (-1 !== window.location.href.indexOf('/profile/')) {
  //   const links = document.getElementsByClassName('changer-link')
  //   links.forEach(el => {
  //     if ('en' === window.language) {
  //       el.setAttribute('href', `${window.location.origin}/find-retailer/`)
  //     } else {
  //       el.setAttribute('href', `${window.location.origin}/fr/find-retailer/`)
  //     }
  //   })
  // }
}

export const saveLinkOfCatalog = () => {
  saver()
}
