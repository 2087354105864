<template lang='pug'>
  include /mixins.pug
  +b.video-wrap
    +e.item.IFRAME(:src="videoUrl" width='80%' height='450px')
</template>

<script>
import { getVideoId } from '@utils/video'

export default {
  name: 'VideoModal',
  props: {
    video: {},
  },
  data() {
    return {
      videoUrl: '',
    }
  },
  mounted() {
    this.generateVideoUrl(this.video)
  },
  methods: {
    generateVideoUrl(el) {
      this.videoUrl = `//www.youtube.com/embed/${getVideoId(el)}?autoplay=1?mute=1`
    },

  },
}
</script>
