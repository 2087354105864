<template>
  <resource-loader-controller
    :resource="resource"
    v-slot="{ receive, loading, result }"
  >
    <delayed-executor-controller
      :delay="300"
      :receiver="receive"
      v-slot="{ receive: delayedReceive }"
    >
      <view-component
        v-bind="$attrs"
        multiple
        :value="internal"
        @input="input"
        @close="close"
        @open="open"
        :filter="filter"
        :options="result && result.items || []"
        :loading="loading"
        :receive="delayedReceive"
      />
    </delayed-executor-controller>
  </resource-loader-controller>
</template>

<script>
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import { createResource } from '@resource/resource'
import { filterAPIMiddleware } from '../../transformers'
import ViewComponent from './Component'
import OpenableMixin from '../OpenableMixin'

export default {
  name: 'filter-autocomplete-multiselect-widget-controller',
  props: ['value', 'filter'],
  mixins: [OpenableMixin],

  defaultResource: createResource('/').middleware(filterAPIMiddleware),

  components: {
    ViewComponent,
  },

  computed: {
    resource() {
      const { url } = this.filter.props

      return this.$options.defaultResource.url(makeTemplateGetter(url))
    },
  },
}

</script>
