<template lang='pug'>
  include /mixins
  div
    .relative
      slim-ui(
        :label='label'
        @input="handleInputFilter"
      )
      //- ui-loader(v-if='loading')
    +b.info-row
      +e.cell--2
        search-chips(
          :all="all"
          :filters="prepared"
          @update:filters="handleInputFilter"
          @input="handleInputFilter"
        )
      +e.cell
        //- +e.select
        //-   sorting(
        //-     :values='sortlist'
        //-     :value="value.order || ['-date']"
        //-     @input="handleInputFilter({ ...value.filters, 'order': $event })"
        //-   )
        +e.cell
          +e.view(
            v-for='tile in tiles'
            :class='[`icon-${tile.text_size}`, {"is-active": view == tile.size}]'
            @click.prevent='setTiles(tile.size)'
          )
</template>

<script>

import ControllerMixin from '@app/Filters/mixins/ControllerMixin'
import SlimUi from '@app/Filters/Slim/Ui'

export default {
  mixins: [ControllerMixin],
  components: {
    SlimUi,
  },
}

</script>
