import {
  route,
} from '../../Routing/index.js'

const CardProfile = () => import('../ComponentsRouting/CardProfile.vue')
const ShippingRequestForm = () => import('../ComponentsRouting/CatalogLK.vue')
const ProductSearchForm = () => import('../LK/PersonalInfo.vue')
// const FavoritesProducts = () => import('../ComponentsRouting/FavoritesProducts.vue')
const MyOrders = () => import('../ComponentsRouting/MyOrders.vue')
// const CatalogProducts = () => import('../ComponentsRouting/Catalog.vue')
const OrderLK = () => import('../ComponentsRouting/OrderLK.vue')

const SHIPPING_REQUEST = 'profile/employees/'
const PRODUCT_SEARCH = 'profile/personal-info/'
// const FAVORITES = 'profile/favorites/'
const CARD = 'profile/cart/'
const ORDERS = 'profile/orders/'
// const CATALOG = 'profile/catalog/'
const ORDER = 'profile/order/:id/'
const CATCH_ALL = '/:catchAll(.*)'

export const CHIPPING_REQUEST_NAME = 'user:vacancies'
export const PRODUCT_SEARCH_NAME = 'user:brands'
export const CARD_PROFILE = 'user:card'
export const MY_ORDERS = 'user:orders'
export const CATALOG_PRODUCTS = 'user:catalog'
export const MY_ORDER = 'user:order'

export function createRoutes(options) {
  return [
    route(SHIPPING_REQUEST, ShippingRequestForm, CHIPPING_REQUEST_NAME, options),
    route(PRODUCT_SEARCH, ProductSearchForm, PRODUCT_SEARCH_NAME, options),
    route(CARD, CardProfile, CARD_PROFILE, options),
    route(ORDERS, MyOrders, MY_ORDERS, options),
    route(ORDER, OrderLK, MY_ORDER, options),
    route(CATCH_ALL, ProductSearchForm, PRODUCT_SEARCH_NAME, options),
  ]
}
