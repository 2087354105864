<template lang='pug'>
  include /mixins
  div#content-parent
    //- portal-target(name='plase-for-result')
    #content-view
    renderer(
      :result='items',
      v-if='items'
    )
    slot(v-else)
    //- +b.ds-panel--space_3xl.UI-LIMIT-PAGINATION(
    //-   :pagination='pagination'
    //-   @input='handlePaginate'
    //- )
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import Renderer from '../Helpers/Renderer'

export default {
  props: ['loading', 'items', 'pagination'],
  components: {
    Renderer,
  },
  computed: {
    // ...mapState('vendor', [
    //   'view',
    // ]),
    back() {
      if (this.items) {} // eslint-disable-line no-empty
      return `${window.location.pathname}${window.location.search}`
    },
  },
  watch: {
    items() {
      this.$nextTick(() => this.trigger_view(this.view))
    },
  },
  methods: {
    // ...mapActions('vendor', [
    //   'trigger_view',
    // ]),
    // scroll() {
    //   const el = document.getElementById('content-view')
    //   console.dir(el)
    //   el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    // },
    handlePaginate({ offset }) {
      if (this.loading) {
        return
      }
      const init = 0
      const { limit = init } = this.pagination
      this.$emit('update:pagination', { limit, offset })
      this.scroll()
    },
  },
}

</script>
