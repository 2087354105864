<template lang="pug">
  include /mixins
  div
    +b.g-row.--justify_around.--justify_start-sm(v-for='item, key in attributesVariant')
      +b.g-cell.g-cols--12.g-cols--3-sm.g-cols--3-md.g-cols--2-xl
        +b.av_space_mt--7(v-if='key !== 0')
          +b.title.ds-caption.--demi-bold.--size_xs.--size_sm-md {{ item.title }}
        +b.title.ds-caption.--demi-bold.--size_xs.--size_sm-md(v-else) {{ item.title }}
      +b.g-cell.g-cols--12.g-cols--7-sm.g-cols--9-md.g-cols--10-xl
        +b.wrap-collections
          +b.av_space_mt--7(v-if='key !== 0')
            +b.A.block-link-back.--without-line(v-for='it in item.choices')
              +e.SPAN.text.ds-caption.--size_sm  {{ it.title }}
          +b.A.block-link-back.--without-line(
            v-for='it,key in item.choices'
            v-else
          )
            +e.SPAN.text.ds-caption.--size_sm  {{ it.title }}
            +e.SPAN.text.ds-caption.--size_sm(v-if='key !== item.choices.length - 1') ,
  //- +b.wrap-info-component
  //-   +b.block-characteristic(v-for='item in attributesVariant')
  //-     +e.SPAN.title.ds-caption.--demi-bold.--size_xs.--size_sm-md {{ item.title }}:
  //-     +e.SPAN.text.ds-caption.--size_xs.--size_sm-md(v-for='it in item.choices')  {{ it.title }}
</template>

<script>

import {
  getColors,
} from '@api/getItems.service'

export default {
  props: {
    id: Number,
  },
  data() {
    return {
      arrayWithInfo: [],
      attributesVariant: [],
    }
  },
  mounted() {
    this.getDataInfo()
  },
  methods: {
    getDataInfo() {
      return getColors.execute({ id: this.id }).then(res => {
        this.arrayWithInfo = res.data.item.group_attrs.group_attributes
        this.getAttributes(this.arrayWithInfo)
      })
    },
    getAttributes(array) {
      array.forEach(element => {
        element.attrs.forEach(elem => {
          this.attributesVariant.push(elem)
        })
      });
    },
  },
}
</script>
