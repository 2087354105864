import {
  getQuantityProducts,
} from '@api/getItems.service'

export const cartQuantity = {
  namespaced: true,
  state: {
    countCart: null,
  },
  actions: {
    getQuantityOfCart({ commit }) {
      getQuantityProducts.execute({}).then(({ data: { item: { quantity } } }) => {
        commit('SET_QUANTITY', quantity)
      })
    },
  },
  mutations: {
    /* eslint-disable no-return-assign */
    SET_QUANTITY: (state, quantity) => state.countCart = quantity,
  },
}
