<template lang='pug'>
  include /mixins
  +b.g-row--appearance_spaced.--space_sm
    +b.g-cell.--align_center.g-cols.row(v-for="item in tags")
      +b.filter-current.row.relative(v-for="choice in item.choices")
        +b.color-block-current.shape(
          v-if='choice.texture'
          :style="{background: `url(${choice.texture})`}"
        )
          +e.SPAN.ic.icon-krestik(@click.prevent="remove(item, choice)")
        +b.color-block-current(
          v-if='choice.color'
          :style='{background: choice.color}'
        )
          +e.SPAN.ic.icon-krestik(@click.prevent="remove(item, choice)")
        +e.P.titles.ds-caption.--size_sm(v-else-if='!choice.texture && !choice.color && choice.title') {{ choice.title }}
          +e.SPAN.ic.icon-krestik(@click.prevent="remove(item, choice)")
        +b.hits-for-chips.ds-caption.--size_sm {{item.title}}
    +b.g-cell.--align_center.g-cols(v-if='tags && tags.length')
      +b.product-variants--free(@click.prevent='clear')
        +e.el--red
        +b.reset-all-filter.ds-caption.--hover.--pointer
          +e.SPAN.ic.icon-reset
          +e.SPAN.text.ds-caption.--appearance_underline.--hover {{ _('Reset filters') }}
    //- +b.g-cell.g-cols(
    //-   v-for='filter in simpleFilters',
    //-   :key='filter.id'
    //- )

      +b.product-variants--free
        +e.wrap
          tag(
            :tag='$options.renderersMap[filter.props.filter_widget_type]',
            :filter='filter',
            :value='filters[filter.title]'
          )
          +e.close.um-link.A(
            @click.prevent='remove(filter.title)'
          )
            +e.icon.mdi.mdi-close
</template>
<script>
// import { omit, remove } from 'ramda';
import { mapState, mapActions } from 'vuex'
// import { isEmpty } from '@aspectus/vue-utils';
// import { FILTERS_ALL } from '../const';
import {
  MultiselectRenderer,
  SelectRenderer,
  SliderRenderer,
  // DateRangeRenderer,
  CombinedInputRenderer,
  AutocompleteMultiselectRenderer,
} from './renders';

// const EXCLUSIVE_FILTER_TYPES = [
//   'combined-input',
// ];

export default {
  renderersMap: {
    select: SelectRenderer,
    checkbox: MultiselectRenderer,
    'int-range': SliderRenderer,
    // 'date-range': DateRangeRenderer,
    'combined-input': CombinedInputRenderer,
    'autocomplete-multiselect': AutocompleteMultiselectRenderer,
  },

  data() {
    return {
      tags: [],
    }
  },

  watch: {
    chips: {
      deep: true,
      immediate: true,
      handler() {
        this.generateTags()
      },
    },
  },

  computed: {
    ...mapState('catalog', [
      'chips',
    ]),
  },

  methods: {
    ...mapActions('catalog', [
      'setChangedChipsData',
    ]),

    generateTags() {
      if (this.chips.filters.category) {
        delete this.chips.filters.category
      }
      if (!this.chips.all) return
      /* eslint-disable camelcase */
      const { item: { filter_attrs } } = this.chips.all
      this.tags = Object.keys(this.chips.filters).reduce((acc, k) => {
        const initialAttr = filter_attrs[k]
        const selectedAtts = this.chips.filters[k]
        const preperaTagMethods = {
          range: this.prepareTagRange,
          text: this.prepareTagText,
          checkbox: this.prepareTagList,
          radio: this.prepareTagList,
          select: this.prepareTagList,
        }
        const preparedTag = preperaTagMethods[initialAttr.input_widget](initialAttr, selectedAtts)
        acc.push(preparedTag)
        return acc
      }, [])
    },

    // update(value) {
    //   this.$emit('update:filters', value);
    // },
    clear() {
      this.setChangedChipsData({})
    },
    remove(item, choice) {
      if ('price' !== item.slug) {
        const one = 1
        const choices = this.chips.filters[item.slug]
        const choiceIndex = choices.indexOf(choice.slug)
        choices.splice(choiceIndex, one)
        console.log(this.chips.filters)
        this.setChangedChipsData(this.chips.filters)
        // console.log(this.chips.filters);
      } else if (this.chips.filters.price) {
        this.chips.filters.price = []
        this.setChangedChipsData(this.chips.filters)
      }
      console.log(this.chips.filters);
      // this.chips.filters
      // this.update(omit([id], this.chips.filters));
    },

    // removeCombinedInputValue(filter, value) {
    //   const current = this.filters[filter.id];
    //   const index = current.indexOf(value);

    //   this.update({ ...this.filters, [filter.id]: remove(index, 1, current) });
    // },

    prepareTagRange(initial, selected) {
      const val = Array.isArray(selected) ? selected[0] : selected
      return {
        title: initial.title,
        slug: initial.slug,
        choices: [{ title: val }],
      }
    },

    prepareTagText(initial, selected) {
      return {
        title: initial.title,
        slug: initial.slug,
        choices: [{ title: selected[0] }],
      }
    },

    prepareTagList(initial, selected) {
      const tag = {
        title: initial.title,
        slug: initial.slug,
        choices: [],
      }
      initial.choices.forEach(choice => {
        if (selected.includes(choice.slug)) {
          tag.choices.push(choice)
        }
      })
      return tag
    },
  },
};

// </script>
