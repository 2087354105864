import {
  createResource,
  postResource,
  createReceiver,
  deleteResource,
  patchResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const SUBSCRIBE = prefixAPI('/subscribers/create/')
const MESSAGE = prefixAPI('/feedback/message/create/')
const WARRANTY_CREATE = prefixAPI('/warranty_request/create/')
const SEARCH_COUNTRIES = prefixAPI('/location/subdivision/list/{?search,page}')
const SEARCH_CITY = prefixAPI('/location/city/list/{?search,subdivision,page}')
const SEARCH_ZIP = prefixAPI('/location/zip/list/{?search,city,page}')
const SEARCH_GEO = prefixAPI('/location/by/zipcode/{?search,zip}')
const LOGIN = prefixAPI('api/v1/auth/login/')
const PASSWORD_RESET = prefixAPI('/auth/rest/password/reset/')
const LOGOUT = prefixAPI('api/v1/auth/logout/')
const RETAILERS = prefixAPI('/retailer/location/find/list{?find}')
const VACANCIES = prefixAPI('/vacancies/feedback/create/')
const CHANGE_FIRST_PASSWORD = prefixAPI('/auth/rest/password/reset/confirm/')
const CHANGE_PASSWORD_IN_CABINET = prefixAPI('/auth/password/change/')
const ADDRESS = prefixAPI('/retailer/location/create/')
const DELETE_ADDRESS = prefixAPI('/retailer/retailer/location/{id}/destroy/')
const CONTEXT = prefixAPI('/context/list/')
const EMPLOYEE = prefixAPI('/profile/employee/create/')
const LIKES = prefixAPI('/like/turn/generic/')
const ADD_TO_CARD = prefixAPI('/cart/change/')
const ADD_TO_CARD_PLUS = prefixAPI('/cart/integration-change/')
const GET_CARD = prefixAPI('/cart/retrieve/')
const GET_CARD_CHANGE = prefixAPI('/cart/integration-retrieve/')
const ORDER = prefixAPI('/orders/create/')
const INFO_ORDER = prefixAPI('/orders/retrieve/{id}/')
const CHANGE_INFORMATION_RETAILER = prefixAPI('/profile/retailer/{id}/data/retrieve/')
const CHANGE_INFORMATION_USER = prefixAPI('/profile/user/personal-data/retrieve/')
const SEARCH_HEADER = prefixAPI('/search/search-header/')
const PAYMENT = prefixAPI('/payment/order/{id}/create/')
const CURRENT_USER = prefixAPI('/profile/current/user-data/generic/')
const GET_FINDE_RETAILER = prefixAPI('/retailer/location/search/list/{?lat,lon,rad}')

export const subscribe = createResource(SUBSCRIBE, postResource)
export const sendOrder = createResource(ORDER, postResource)
export const feedback = createResource(MESSAGE, postResource)
export const warrantyCreateResource = createResource(WARRANTY_CREATE, postResource)
export const login = createResource(LOGIN, postResource)
export const passwordReset = createResource(PASSWORD_RESET, postResource)
export const logoutUser = createResource(LOGOUT, postResource)
export const findRetailers = createResource(RETAILERS)
export const searchCountry = createReceiver(SEARCH_COUNTRIES)
export const city = createReceiver(SEARCH_CITY)
export const searchZip = createReceiver(SEARCH_ZIP)
export const searchGeo = createReceiver(SEARCH_GEO)
export const feedbackVacancies = createResource(VACANCIES, postResource)
export const changePasswordFirst = createResource(CHANGE_FIRST_PASSWORD, postResource)
export const changePassword = createResource(CHANGE_PASSWORD_IN_CABINET, postResource)
export const addAddress = createResource(ADDRESS, postResource)
export const deleteAddress = createResource(DELETE_ADDRESS, deleteResource)
export const getContext = createResource(CONTEXT)
export const addEmployee = createResource(EMPLOYEE, postResource)
export const favouriteProduct = createResource(LIKES, postResource)
export const buyProduct = createResource(ADD_TO_CARD, postResource)
export const productAddToCard = createResource(ADD_TO_CARD_PLUS, postResource)
export const getCard = createResource(GET_CARD)
export const getCardChange = createResource(GET_CARD_CHANGE)
export const getOrders = createResource(INFO_ORDER)
export const changeInfoRetailer = createResource(CHANGE_INFORMATION_RETAILER, patchResource)
export const changeInfoUser = createResource(CHANGE_INFORMATION_USER, patchResource)
export const searchHeader = createResource(SEARCH_HEADER, postResource)
export const payOrder = createResource(PAYMENT, postResource)
export const currentUser = createResource(CURRENT_USER)
export const geoSearch = createResource(GET_FINDE_RETAILER)
