<template lang="pug">
  include /mixins
  +b.form-change-wrap
    +b.g-row.--justify_end
      +b.g-cell.g-cols
        +e.text.ds-caption.--size_2md.--hover.--pointer(@click='closeForm') &#10006;
    +b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag="form"
      )
      +b.av_space_mt--7(v-for='field in fields')
        +b.D-CONTROL-INPUT(
          :disabled='field.disabled'
          :key='field.name'
          :name='field.name'
          :type='field.type || "text"'
          :placeholder='field.placeholder'
          v-model='form[field.name]')
      +b.g-row.--justify_center
        +b.g-cell.g-cols--12
          +b.av_space_mt--7
            +b.BUTTON.av_button--main.--size-second.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('Change information')}}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  changeInfoRetailer,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  props: ['retailer', 'promise', 'title', 'email'],
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          name: 'title',
          placeholder: this.title,
          disabled: false,
        },
        {
          name: 'email',
          disabled: true,
          placeholder: this.email,
        },
      ],
      form: {
        title: this.title,
        email: this.email,
        // recaptcha: 'dummy_value',
      },
    }
  },
  methods: {
    closeForm() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.$refs.validator.reset()
      return changeInfoRetailer.execute({ id: this.retailer }, data).then(res => {
        this.closeForm()
        this.promise(true)
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        AnswerMessage(message, this)
      })
    },
  },
}
</script>
