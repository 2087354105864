<template lang="pug">
  include /mixins
  +b.wrapper-for-form
    +b.VALIDATION-OBSERVER(
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      +b.form-wrap
        +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
        +b.TAG.follow-input(v-for='field in fields'
          v-bem:d-control-input="{'variant': 'dark'}"
          :key='field.name'
          :name='field.name'
          :tag='field.tag || "d-control-input"'
          :rules='field.rules'
          :type='field.type || "text"'
          :placeholder='field.placeholder'
          v-model='form[field.name]'
        )
        +b.BUTTON.btn-icon.icon-send(@click.prevent='prepareData(valid)')
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  subscribe,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: '',
      fields: [
        {
          name: 'email',
          placeholder: this._('Enter your email'),
        },
      ],
      form: {
        email: '',
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.nonFieldErrors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send() {
      this.$refs.validator.reset()
      return subscribe.execute({}, this.form).then(res => {
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        AnswerMessage(message, this)
      })
    },
  },
}
</script>
