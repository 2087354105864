<template>
  <div>
    <filter-controller
      :receive="allProps.receive"
      :receiveAvailable="availableProps.receive"
      :receivePrices="pricesProps.receive"
      :all="allProps.result"
      :prices="pricesProps.result"
      :available="availableProps.result"
      :displayed="displayedFilters"
      :pagination='pagination'
      :loading="allProps.loading"
      :value="value"
      :label="label"
      @input="handleFilter"
      :state='state'
      :sortlist='sortlist'
    />
  </div>
</template>

<script>

import ViewMixin from '@app/Filters/mixins/ViewMixin'

export default {
  props: ['state'],
  mixins: [ViewMixin],
}

</script>
