import SearchForm from './SearchForm'
import SendForm from './SendForm'
import FormSubmit from './FormSubmit'
import FormFindRetailer from './FormFindRetailer'
import FormSendEmail from './FormSendEmail'
import SignInForm from './SignInForm'
import FormResetPassword from './FormResetPassword'
import FormCreatePassword from './FormCreatePassword'
import LogoutButton from './LogoutButton'
import FormRetailerSecond from './FormRetailerSecond'
import FormVacancies from './FormVacancies'
import FormChangePassword from './FormChangePassword'
import FormAddAddress from './FormAddAddress'
import FormSubmitModal from './FormSubmitModal'
import FormChangeContactsRetailer from './FormChangeContactsRetailer'
import FormChangeContactsUser from './FormChangeContactsUser'
import FormWarranty from './FormWarranty'
import FormConfirmOrderShippingFee from './FormConfirmOrderShippingFee'

export default function install(Vue) {
  Vue.component('FormChangeContactsRetailer', FormChangeContactsRetailer)
  Vue.component('FormChangeContactsUser', FormChangeContactsUser)
  Vue.component('SignInForm', SignInForm)
  Vue.component('FormSendEmail', FormSendEmail)
  Vue.component('SearchForm', SearchForm)
  Vue.component('FormFindRetailer', FormFindRetailer)
  Vue.component('SendForm', SendForm)
  Vue.component('FormSubmit', FormSubmit)
  Vue.component('FormResetPassword', FormResetPassword)
  Vue.component('FormCreatePassword', FormCreatePassword)
  Vue.component('LogoutButton', LogoutButton)
  Vue.component('FormRetailerSecond', FormRetailerSecond)
  Vue.component('FormVacancies', FormVacancies)
  Vue.component('FormChangePassword', FormChangePassword)
  Vue.component('FormAddAddress', FormAddAddress)
  Vue.component('FormSubmitModal', FormSubmitModal)
  Vue.component('FormWarranty', FormWarranty)
  Vue.component('FormConfirmOrderShippingFee', FormConfirmOrderShippingFee)
}
