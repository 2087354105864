<template lang="pug">
  include /mixins
  +b.form-in-img.vacncies-form
    +b.g-row.--justify_end
      +b.g-cell.g-cols
        +e.text.ds-caption.--size_2md.--hover.--pointer(@click='closeForm') &#10006;
    +b.wrap-title-form
      +b.H1.ds-caption.--appearance_uppercase.--letter-space-small.--size_3xl.--weight_regular {{ _('Register for vacancies')}}
    +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
    +b.VALIDATION-OBSERVER(
      @submit.prevent='send',
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      +b.wrap-input-with-icon.relative(v-for='field in fields')
        +b.D-CONTROL-INPUT.follow-input(
          v-bem:d-control-input="{'variant': 'black'}"
          :key='field.name'
          :name='field.name'
          :ref='field.name'
          :rules='field.rules'
          :type='field.type || "text"'
          :placeholder='field.placeholder'
          v-model='form[field.name]')
      +b.av_space_mt--9
        +b.wrap-button-form.--second
          +b.g-row.--justify_center
            +b.g-cell.g-cols--12
              +b.BUTTON.av_button--main.--size-second.ds-caption.--variant_uppercase.--color_white.--size_normal(@click.prevent='prepareData(valid)') {{ _('Send your resume') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  feedbackVacancies,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

import {
  masking,
} from '@utils/input-mask'

export default {
  props: {
    info: {
      id: Object,
    },
  },
  name: 'FormVacancies',
  mixins: [FormMixin],
  data() {
    return {
      nonFieldErrors: [],
      fields: [
        {
          name: 'name',
          placeholder: this._('Enter your Name'),
          rules: 'required',
        },
        {
          name: 'phone',
          placeholder: this._('Enter your phone'),
          rules: 'required|phone',
        },
        {
          name: 'email',
          placeholder: this._('Enter your new Email'),
          rules: 'required',
        },
        {
          name: 'cv_url',
          placeholder: this._('Enter Link'),
          rules: 'required',
        },
      ],
      form: {
        vacancy: this.info.id.id,
        name: '',
        phone: '',
        email: '',
        cv_url: '',
        // recaptcha: 'dummy_value',
      },
    }
  },
  async mounted() {
    masking('phone', this)
  },
  methods: {
    closeForm() {
      this.$emit('close')
    },
    focus() {},
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.$refs.validator.reset()
      return feedbackVacancies.execute({}, data).then(res => {
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        AnswerMessage(message, this)
        this.$emit('close')
        this.promise(true)
      })
    },
  },
}
</script>
