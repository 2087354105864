import {
  logoutUser,
} from '@api/communication.service'
/* eslint-disable */
function checkActivityUser() {
  let timeout = 1800000;
  let lastActiveTimestamp = 0;
  let userIsActive = false;

  window.addEventListener('mousemove', active);
  window.addEventListener('keypress', active);
  window.addEventListener('click', active);
  window.addEventListener('touchstart', active);

  setInterval(checkUserIsActive, 20000)
  
  active();
  function checkUserIsActive() {
    if (userIsActive && new Date().getTime() - lastActiveTimestamp > timeout){
      logoutUser.execute({}).then(res => {
        window.location.pathname = res.data.item.redirect
      })
      userIsActive = false;
    }
  }

  function active() {

    lastActiveTimestamp = new Date().getTime();
    if (!userIsActive) {
      userIsActive = true;
    }
  }
}

export const blockUser = () => {
  if ('true' === window.isAuthenticated) {
    checkActivityUser()
  }
}
