<template lang="pug">
  include /mixins
  +b.block-colors-product(
    :style="{ pointerEvents: colorsArrayDeep.length === 1 ? 'none' : 'auto' }"
  )
    +b.wrap-colors-component(
      v-for='item in colorsArrayDeep'
      v-if='colorsArrayDeep.length'
    )
      +b.A.block-color-product(
        v-if='!show'
        :data-id='id'
        :href='item.url'
        :class='{border: item.select}'
        :style='{background: `linear-gradient(45deg, ${item.color1}  49.99%, ${item.color2} 50%, ${item.color2} 100%)`}'
        @mouseenter='item.focus = true'
        @click.prevent="$emit('change-color', item.url)"
      )
      +b.A.block-color-product(
        v-else
        :data-id='id'
        :href='item.url'
        :class='{border: item.select}'
        :style='{background: `linear-gradient(45deg, ${item.color1}  49.99%, ${item.color2} 50%, ${item.color2} 100%)`}'
        @mouseenter='item.focus = true'
      )
      +b.hint-for-info
        +e.ds-caption.--size_sm.--demi-bold {{item.hint1}} / {{item.hint2}}
    +b.wrap-colors-component(
      v-for='colors in oneColorArray'
    )
      +b.wrapper-for-color(
        v-for='color in colors'
      )
        div(v-for='item in color')
          div(v-for='i in item.values')
            +b.A.block-color-product(
              v-if='!show'
              :data-id='id'
              :href='colors.url'
              :class='{border: colors.is_selected}'
              :style='{background: i.color}'
              @mouseenter='item.focus = true'
              @click.prevent="$emit('change-color', colors.url)"
            )
            +b.A.block-color-product(
              v-else
              :data-id='id'
              :href='colors.url'
              :class='{border: colors.is_selected}'
              :style='{background: i.color}'
              @mouseenter='item.focus = true'
            )
            +b.hint-for-info
              +e.ds-caption.--size_sm.--demi-bold {{i.title}}
            //- div {{colors.url}}
      //- @click.prevent='changeCard(item.url)'
      //- +b.block-item(
      //-   v-for='item in colorsVariant'
      //-   :class='{border: item.is_selected}'
      //- ) asdasdyy
</template>

<script>
// import {
//   getColors,
// } from '@api/getItems.service'

import { mapActions, mapState } from 'vuex'

export default {
  props: {
    typeColor: String,
    id: Number,
    link: String,
  },
  data() {
    return {
      show: false,
      card: false,
      colorsArray: [],
      colorsArrayDeep: [],
      hints: [],
      urlColors: [],
      linkProduct: '',
      oneColorArray: [],
      selectedColors: [],
      isUpdated: false,
    }
  },
  mounted() {
    if ('undefined' === typeof this.typeColor) {
      this.show = false
    } else {
      this.show = true
    }

    if (!this.allColors[this.id]) {
      this.checkId({ id: this.id })
    }
  },

  computed: {
    ...mapState('colors', [
      'allColors',
    ]),
  },
  watch: {
    allColors: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.setColorsData(nval)
      },
    },
  },
  methods: {
    ...mapActions('colors', ['checkId']),

    setColorsData(nval) {
      this.$nextTick(() => {
        if (nval[this.id] && !this.isUpdated) {
          this.workWithColors(nval[this.id])
          this.isUpdated = true
        }
      })
    },

    workWithColors(array) {
      if (1 < array[0].attrs.length) {
        array.forEach(element => {
          if (-1 === window.location.href.indexOf('profile/favorites')) {
            this.urlColors.push(element.url)
          } else {
            this.urlColors.push(element.card_url)
          }
          this.selectedColors.push(element.is_selected)
          element.attrs.forEach(elem => {
            // this.hints.push(elem.title)
            elem.values.forEach(el => {
              this.hints.push(el.title);
              this.colorsArray.push(el.color)
            })
          })
        });
        this.colorGrouping(this.colorsArray)
      } else {
        this.oneColorArray = array
      }
    },
    colorGrouping(array) {
      for (let i = 0; i < array.length; i++) {
        array[i] = { color2: array[i], color1: array[i + 1], hint1: this.hints[i], hint2: this.hints[i + 1] }
        i++
      }
      for (let i = 0; i < array.length; i++) {
        if ('object' !== typeof array[i]) {
          array.splice(i, 1)
        }
      }
      for (let i = 0; i < array.length; i++) {
        array[i].url = this.urlColors[i]
        array[i].select = this.selectedColors[i]
        array[i].focus = false
      }
      this.colorsArrayDeep = array
    },
    changeCard(link) {
      if (-1 !== window.location.href.indexOf('profile/favorites')) {
        this.card = true
        this.linkProduct = link
      }
    },
  },
}
</script>
