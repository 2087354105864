<template lang="pug">
  include /mixins
  +b.personal-information
    +b.wrap-navigation-product.--without-margin_2.--ml-left
      button-back(
        :link='lang === "eng" ? "/catalog/" : "/fr/catalog/"'
      )
    +b.information-about-user
      +b.main-caption-profile-info
        +b.H1.ds-caption.--size_3xl.--variant_uppercase.--weight_regular {{ _('Profile data')}}
      +b.information-reailer
        +b.wrapper-for-padings
          +b.g-row.--space_none
            +b.g-cell.g-cols.g-cols--12.--4-sm.--4-xl(v-if='arrayInfoPerson.first_name')
              +b.small-info-people
                +e.H4.title.ds-caption.--size_2xs.--light_gray.--appearance_uppercase.--demi-bold.--opacity-half {{ _('Business Name')}}
                +b.av_space_mt--4
                  +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.retailer_title}}
            +b.g-cell.g-cols.g-cols--12.--4-sm.--4-xl(v-if='arrayInfoRetailer.phone && arrayInfoRetailer.phone.length')
              +b.small-info-people
                +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Telephone')}}
                +b.av_space_mt--4
                  +e.A.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.phone}}
            +b.g-cell.g-cols.g-cols--12.--4-sm.--4-xl
              +b.small-info-people
                +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Balance')}}
                +b.av_space_mt--4
                  +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold(v-if='arrayInfoRetailer.balance.length') {{arrayInfoRetailer.balance}}$
                  +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold(v-else) 0$
          +b.g-row.--space_none
            +b.g-cell.g-cols.g-cols--12.--4-sm.--4-xl
              +b.av_space_mt--10-xl
                +b.av_space_pt--2-xl
                  +b.small-info-people
                    +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Authorized Representative')}}
                    +b.av_space_mt--4
                      +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoPerson.first_name}}
            +b.g-cell.g-cols.g-cols--12.--4-sm.--4-xl
              +b.av_space_mt--10-xl
                +b.av_space_pt--2-xl
                  +b.small-info-people
                    +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('E-Mail')}}
                    +b.av_space_mt--4
                      +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.email}}
            +b.g-cell.g-cols.g-cols--12.--4-sm.--4-xl
              +b.av_space_mt--10-xl
                +b.av_space_pt--2-xl
                  +b.small-info-people
                    +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Website')}}
                    +b.av_space_mt--4
                      +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold(:href='arrayInfoRetailer.website') {{arrayInfoRetailer.website}}
          +b.g-row.--space_none
            +b.g-cell.g-cols.g-cols--12.--4-sm.--4-xl
              +b.av_space_mt--10-xl
                +b.av_space_pt--2-xl
                  +b.small-info-people
                    +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Sales Rep')}}
                    +b.av_space_mt--4
                      +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.sales_rep}}
        //- +b.av_space_mt--9
          +b.wrap-button-form.--second
            +b.g-row.--justify_center.--justify_end-lg.--space_none
              +b.g-cell.g-cols
                //- +b.BUTTON.av_button--main.--size-fourth.ds-caption.--variant_uppercase.--color_white(@click.prevent='newInfoUser(arrayInfoPerson.first_name, arrayInfoPerson.phone)') {{ _('Change contacts')}}
      +b.wrap-lines
        +b.block-line-gray.--second
        +b.block-line-gray.--second
    +b.information-about-retailer
      +b.main-caption-profile-info.--second
        +b.H1.ds-caption.--size_3xl.--variant_uppercase.--weight_regular {{ _('Shop')}}
      +b.information-reailer
        +b.small-info-people.--without-mt
        +b.wrapper-for-padings
          //- +b.g-row.--justify_between.--space_none
            +b.g-cell.--align_end.g-cols--12.--6-sm.--2-xly
              +b.av_space_pl--2
                +b.av_space_mb--2
                  +e.SPAN.text.ds-caption.--size_xl.--demi-bold {{arrayInfoRetailer.retailer_title}} asdasdasd asd
          +b.g-row.--space_none
            +b.g-cell.g-cols--12.--8-sm(v-if='arrayInfoRetailer && arrayInfoRetailer.billing_addresses || arrayInfoRetailer.shipping_addresses')
              +b.small-info-people
                +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Addresses')}}
                +b.av_space_mt--5
                  +e.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold(
                    v-for='item in arrayInfoRetailer.billing_addresses'
                  ) {{item.full_address}}
                  +e.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold(
                    v-for='item in arrayInfoRetailer.shipping_addresses'
                  ) {{item.full_address}}
            +b.g-cell.g-cols--12.--3-sm(v-if='arrayInfoRetailer.sales_rep.length')
              +b.small-info-people
                +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Sales Rep')}}
                +b.av_space_mt--5
                  +e.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.sales_rep}}
          +b.g-row.--space_none
            +b.g-cell.--align_end.g-cols--12.--5-sm.--4-xl(v-if='arrayInfoRetailer.pricing_scheme')
              +b.small-info-people
                +b.av_space_mt--10-xl
                  +b.av_space_pt--2-xl
                    +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Pricing scheme')}}
                    +b.av_space_mt--5
                      +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.pricing_scheme}}
            +b.g-cell.g-cols--12.--5-sm.--4-xl(v-if='arrayInfoRetailer.payment_term')
              +b.small-info-people
                +b.av_space_mt--10-xl
                  +b.av_space_pt--2-xl
                    +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Payment terms')}}
                    +b.av_space_mt--5
                      +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.payment_term}}
            +b.g-cell.g-cols--12.--2-sm.--4-xl
              +b.small-info-people
                +b.av_space_mt--10-xl
                  +b.av_space_pt--2-xl
                    +e.H4.title.ds-caption.--size_2xs.--demi-bold.--light_gray.--appearance_uppercase.--opacity-half {{ _('Discount')}}
                    +b.av_space_mt--5
                      +e.SPAN.text.ds-caption.--size_md.--size_2xs-sm.--size_md-md.--size_lg-xl.--size_xl-mac.--demi-bold {{arrayInfoRetailer.discount}}%
      //- +b.av_space_mt--9.av_space_mb--9
      //-   +b.wrap-button-form.--second(v-if='arrayInfoRetailer.type !== "employee"')
      //-     +b.g-row.--justify_center.--justify_end-lg.--space_none
      //-       +b.g-cell.g-cols
      //-         +b.av_space_mr--3-lg.av_space_mr--0-xl
                //- +b.BUTTON.av_button--main.--size-fourth.ds-caption.--variant_uppercase.--color_white(@click.prevent='newInfoRetailer(arrayInfoRetailer.retailer_title, arrayInfoRetailer.email)') {{ _('Change contacts')}}
      //- +b.block-addresses
        +b.wrapper-for-padings
          +b.g-row.--justify_between.--space_none
            +b.g-cell.g-cols--12.--5-md.--5-xl(v-if='addressRetailers.shipping_addresses')
              +b.small-info-people
                +b.av_space_mb--4
                  +e.H4.title.ds-caption.--size_2xs.--light_gray.--appearance_uppercase.--demi-bold.--opacity-half(v-if='addressRetailers.shipping_addresses') {{ _('Shipping address')}}
                +b.H3.text.ds-caption.--size_md.--size_xl-xl.--demi-bold(
                  v-for='item in addressRetailers.shipping_addresses'
                  v-if='addressRetailers.shipping_addresses'
                )
                  +e.H4.address-retailer {{item.full_address}}
                    //- +e.SPAN.block.delete-address.ds-caption.--hover(
                    //-   @click='deleteAddress(item.id)'
                    //-   v-if='arrayInfoRetailer.type !== "employee"'
                    //-   )
            +b.g-cell.g-cols.--6-md
              +b.small-info-people
                +b.av_space_mb--4
                  +e.H4.title.ds-caption.--size_2xs.--light_gray.--appearance_uppercase.--demi-bold.--opacity-half(v-if='addressRetailers.billing_addresses') {{ _('Billing address')}}
                +b.H3.text.ds-caption.--size_md.--size_xl-xl.--demi-bold(
                  v-for='item in addressRetailers.billing_addresses'
                  v-if='addressRetailers.billing_addresses'
                )
                  +e.H4.address-retailer
                    +e.SPAN.text {{item.full_address}}
                    //- +e.SPAN.block.delete-address.ds-caption.--hover(
                    //-   @click='deleteAddress(item.id)'
                    //-   v-if='arrayInfoRetailer.type !== "employee"'
                    //-   )
      +b.av_space_mt--9
        +b.wrap-button-form.--second(v-if='arrayInfoRetailer.type !== "employee"')
          +b.g-row.--justify_center.--justify_end-lg.--space_none
            +b.g-cell.g-cols
              +b.av_space_mr--3-lg.av_space_mr--0-xl
                +b.BUTTON.av_button--main.--size-fourth.ds-caption.--variant_uppercase.--color_white(@click.prevent='newAddress') {{ _('Change info')}}
    +b.FORM-CHANGE-PASSWORD
</template>

<script>

import {
  getRetailerInformation,
  getProfileInformation,
} from '@api/getItems.service'

import {
  getContext,
  deleteAddress,
} from '@api/communication.service'

import FormAddAddress from '../Forms/FormAddAddress.vue'
// import FormChangeContactsRetailer from '../Forms/FormChangeContactsRetailer.vue'
// import FormChangeContactsUser from '../Forms/FormChangeContactsUser.vue'

export default {
  props: ['type'],
  data() {
    return {
      cont: {},
      arrayInfoPerson: {},
      arrayInfoRetailer: {},
      lang: window.language,
      idRetailer: 0,
      addressRetailers: [],
      typeAddresses: ['shipping', 'billing'],
    }
  },
  mounted() {
    this.$emit('state', false)
    this.getRetailerInfo()
    this.peopleInformation()
    return getContext.execute({}).then(res => {
      this.cont = res
    })
  },
  methods: {
    // newInfoRetailer(title, email) {
    //   const pr = arg => new Promise((resolve, reject) => {
    //     if (arg) {
    //       resolve(arg)
    //     } else {
    //       reject(new Error('error'))
    //     }
    //   }).then(() => {
    //     this.getRetailerInfo()
    //   }).catch(() => {
    //     this.$emit('error')
    //     // const newErr = new Error('error')
    //     // newErr()
    //   })
    //   this.$modal.show(FormChangeContactsRetailer,
    //     {
    //       retailer: this.idRetailer,
    //       promise:
    //       pr,
    //       title,
    //       email,
    //     },
    //     {
    //       height: 'auto',
    //       minWidth: 300,
    //       adaptive: true,
    //     })
    // },
    // newInfoUser(name, phone) {
    //   const pr = arg => new Promise((resolve, reject) => {
    //     if (arg) {
    //       resolve(arg)
    //     } else {
    //       reject(new Error('error'))
    //     }
    //   }).then(() => {
    //     this.getRetailerInfo()
    //     this.peopleInformation()
    //   }).catch(() => {
    //     this.$emit('error')
    //     // const newErr = new Error('error')
    //     // newErr()
    //   })
    //   this.$modal.show(FormChangeContactsUser,
    //     {
    //       promise:
    //       pr,
    //       name,
    //       phone,
    //     },
    //     {
    //       height: 'auto',
    //       minWidth: 300,
    //       adaptive: true,
    //     })
    // },
    newAddress() {
      const pr = arg => new Promise((resolve, reject) => {
        if (arg) {
          resolve(arg)
        } else {
          reject(new Error('error'))
        }
      }).then(() => {
        getRetailerInformation.execute({}).then(res => {
          this.addressRetailers = res.data
        })
      }).catch(() => {
        this.$emit('error')
        // const newErr = new Error('error')
        // newErr()
      })
      this.$modal.show(FormAddAddress,
        {
          retailer: this.idRetailer,
          promise: pr,
          add: this.cont.common.address_types.choices,
        },
        {
          height: 'auto',
          minWidth: 300,
          adaptive: true,
        })
    },
    deleteAddress(item) {
      deleteAddress.execute({ id: item }).then(() => {
        this.getRetailerInfo()
      })
    },
    getRetailerInfo() {
      getRetailerInformation.execute({}).then(res => {
        this.arrayInfoRetailer = res.data
        this.addressRetailers = res.data
        this.idRetailer = this.arrayInfoRetailer.retailer
      })
    },
    peopleInformation() {
      getProfileInformation.execute({}).then(res => {
        this.arrayInfoPerson = res.data
      })
    },
  },
}
</script>
