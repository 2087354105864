<template lang="pug">
  d-control-date-range(
    :input-label='_("Get at")',
    @input='submit'
    :range='filter && filter.values'
    v-model="checked"
  )
</template>

<script>
export default {
  name: 'DateRangeWidget',
  props: ['value', 'filter', 'available'],
  data() {
    return {
      checked: '',
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },
  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },
  methods: {
    submit() {
      this.$emit('input:multiple', { range_after: this.checked[0], range_before: this.checked[1] })
      // this.$emit('input', this.normalizeTo(this.checked))
    },
    normalizeTo(value) {
      if (!value) return []
      return this.checked
    },
    normalizeFrom(value) {
      if (!value) return []
      const checked = []
      value.forEach(el => {
        if (Array.isArray(el)) {
          checked.push(el[0])
          return
        }
        checked.push(el)
      })
      return checked
    },
  },
}
</script>
