<template lang="pug">
  include /mixins
  +b.block-wrap-btn
    slot(
      name='btn'
      :open='() => showMenu()',
      :is-active='isActive'
      :class='{active: isActive}'
    )
    transition(name='fade')
      .is-blured(
        v-if='isActive'
        @click.prevent='showMenu()'
      )
    transition(name='slide')
      +b.mobile-menu(
        v-if='isActive'
        :class='{active: isActive}'
      )
        slot(
          :close='() => isActive = false'
          name='sidebar'
        )
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    showMenu() {
      this.isActive = !this.isActive;
      this.$body.dataset.blocked = this.isActive
    },

    close() {
      this.isActive = false
      this.$body.dataset.blocked = this.isActive
    },
  },
}
</script>
