export default {
  data() {
    return {
      internal: this.value,
      opened: false,
      changed: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.internal = value
      },
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    },
    input(value) {
      this.changed = true
      this.internal = value
    },
    open() {
      this.opened = true
    },
    close() {
      this.opened = false

      if (this.changed) {
        this.update(this.internal)
      }

      this.changed = false
    },
  },
}
