<template lang="pug">
  include /mixins
  //- v-if='type === "retailer"'
  +b.employees-lk
    +b.H1.ds-caption.--variant_uppercase.--size_3xl.--weight_regular(v-if='employees.length < 1') {{ _("You don't have any employees.")}}
    +b.g-container.--variant_tiny
      +b.wrap-title-blog.relative
        +b.block-user-error(v-if='error') {{error}}
        +b.H1.ds-caption.--variant_uppercase.--size_3xl.--weight_regular(v-if='employees.length !== 0') {{ _('Employees') }}
      +b.ds-table.--view_cards-till-lg.--styling_default.table-employees.TABLE(v-if='employees.length !== 0')
        +e.head.THEAD
          +e.row.TR
            +e.cell.--head.th-without-padding.TH(v-for='item, key in labels')
              +b.ds-caption.--size_md.--demi-bold.--dark-third.SPAN {{item.string}}
          +e.TR
            - var tableHeadData = [{string: "Vendor code"}, {string: "Product Name"}, {string: "Price"}, {string: "Retailer Discount  price"}]
            each i in tableHeadData
              +e.cell.--head.th-with-padding.TH(height='12')
                +b.block-line-black
        +e.body.TBODY
          +e.row.--gray.TR(v-for='(item, index) in employees')
            +e.TD.cell.tc-without-padding
              row(
                :justify='["between", "center-lg"]'
                :space='["none"]'
              )
                cell
                  +b.caption-help
                    +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Name of Employee') }}
                cell
                  div
                    +b.ds-caption.--size_sm.--color_1.SPAN {{item.fullname}}
            +e.TD.cell.tc-without-padding
              row(
                :justify='["between", "center-lg"]'
                :space='["none"]'
              )
                cell
                  +b.caption-help
                    +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Date of Registration') }}
                cell
                  div
                    +b.ds-caption.--size_sm.--color_1.SPAN {{item.date_joined_repr}}
            +e.TD.cell.tc-without-padding
              row(
                :justify='["between", "center-lg"]'
                :space='["none"]'
              )
                cell
                  +b.caption-help
                    +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Date of last authorization') }}
                cell
                  div
                    +b.ds-caption.--size_sm.--color_1.SPAN(v-if='item.last_login_repr') {{item.last_login_repr}}
                    +b.ds-caption.--size_sm.--color_1.SPAN(v-else) {{ _('Form Title') }}
            +e.TD.cell.tc-without-padding
              row(
                :justify='["between", "center-lg"]'
                :space='["none"]'
              )
                cell
                  +b.caption-help
                    +b.ds-caption.--size_sm.--color_1.--demi-bold.SPAN {{ _('Block') }}
                cell
                  div
                    +b.INPUT.checkbox-emoployer(
                        @click='blockUser(item.is_blocked, item.employee, item.last_login_repr), item.is_blocked =! item.is_blocked'
                        type='checkbox'
                        :checked='item.is_blocked'
                        :class='[{disabled: type === "retailer" ? false : true}]'
                      )
                    //- +b.INPUT.checkbox-emoployer(
                    //-     @click='blockUser(item.is_blocked, item.employee, item.last_login_repr), item.is_blocked =! item.is_blocked'
                    //-     type='checkbox'
                    //-     :checked='item.is_blocked'
                    //-     :class='[{disabled: type === "retailer" ? false : true}, {disabled: item.is_allow_switch ? false : true}]'
                    //-   )
              //- +b.block(
              //-   @click='blockUser(item.is_blocked, item.employee)'
              //-   :class='item.is_blocked ? "icon-close-pass" : "icon-open-pass"'
              //- )
      //- +b.card-empty(v-else)
      //-   +b.H1.ds-caption.--variant_uppercase.--appearance_center.--size_3xl {{ _("You don't have any employees.")}}
      +b.g-row.--justify_center.--justify_end-sm
        +b.g-cell.g-cols
          +b.BUTTON.av_button--main.--size-fourth.ds-caption.--variant_uppercase.--color_white(
            @click.prevent='addEmployee'
            v-if='type === "retailer"'
          ) {{ _('add new employer') }}
</template>

<script>
import {
  getRetailerInformation,
  getEmployees,
  doBlockPersonal,
  // getContext,
} from '@api/getItems.service'
import FormAddEmployee from '../Forms/FormAddEmployee.vue'

export default {
  data() {
    return {
      error: null,
      type: '',
      idRetailer: 0,
      employees: {},
      active: false,
      labels: [
        { string: this._('Name of Employee') },
        { string: this._('Date of Registration') },
        { string: this._('Date of last authorization') },
        { string: this._('Block') },
      ],
    }
  },
  mounted() {
    this.$emit('state', false)
    this.infoRetailer()
  },
  methods: {
    infoRetailer() {
      getRetailerInformation.execute({}).then(res => {
        this.idRetailer = res.data.retailer
        this.type = res.data.type
        this.infoPersonal()
      })
    },
    addEmployee() {
      const pr = arg => new Promise((resolve, reject) => {
        if (arg) {
          resolve(arg)
        } else {
          reject(new Error('error'))
        }
      }).then(() => {
        getEmployees.execute({ retailer: this.idRetailer }).then(res => {
          this.employees = res.data.items
        })
      }).catch(() => {
        this.$emit('error')
        // const newErr = new Error('error')
        // newErr()
      })
      this.$modal.show(FormAddEmployee,
        {
          retailer: this.idRetailer,
          promise: pr,
        },
        {
          height: 'auto',
          minWidth: 300,
          adaptive: true,
        })
    },
    infoPersonal() {
      return getEmployees.execute({ retailer: this.idRetailer }).then(res => {
        this.employees = res.data.items
      })
    },
    blockUser(status, employee) {
      return doBlockPersonal.execute({ id: employee }, status)
        .then(() => {})
        .catch(res => {
          const error = res.json()
          error.then(e => {
            this.error = e.errors[0].state.non_field_errors[0].message
            setTimeout(() => {
              this.error = null
            }, 3000)
          })
        })
    },
  },
}
</script>
