<template lang="pug">
  include /mixins
  div
    +b.g-row.--justify_end
      +b.g-cell.g-cols
        +e.H3.title.ds-caption.--size_2md.--pointer.--hover(@click='close') &#10006;
    +b.av_space_pb--6
      +b.H3.ds-caption.--size-xl.--appearance_center {{ _('Manager will contact with you!')}}
      //- +b.form-submit
        +b.H1.ds-caption.--color_white.--variant_uppercase.--weight_regular {{shipping, billing}}
        +b.VALIDATION-OBSERVER(
          ref="validator"
          v-slot="{ valid }",
          tag="form")
          +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
          row(
            appearance='spaced'
            space='xl'
            justify='center'
          )
            cell(
              v-for='field in fields'
              :cols='field.cols'
              :key='field.name'
            )
              +b.av_space_mt--5
                +b.TAG.follow-input(
                  v-bem:d-control-input="{'variant': 'dark-second'}"
                  :key='field.name'
                  :name='field.name'
                  :tag='field.tag || "d-control-input"'
                  :rules='field.rules'
                  :type='field.type || "text"'
                  :ref='field.name'
                  :input-label='field.label'
                  :placeholder='field.placeholder'
                  v-model='form[field.name]'
                  v-on='field.events'
                  v-bind='[field.props]'
                  :string='field.string'
                )
                +b.clear-button.ds-caption.--color_white.--pointer.--hover(@click='clearInput(field.name)')(v-if='field.string == "province" || field.string == "city" || field.string == "zip"') ×
                +b.UL.block-results-search(v-if='provinces.length > 1 && field.string == "province"')
                  +b.LI.block(v-for='items in provinces.slice(0, 10)')
                    +e.fdgdf.ds-caption.--color_white.--hover.--pointer(@click='getGeoClick(field.link, field.string, items.title, {  page: 1}, items.id)') {{items.title}}
                +b.UL.block-results-search(v-if='cities.length > 1 && field.string == "city"')
                  +b.LI.block(v-for='items in cities.slice(0, 10)')
                    +e.fdgdf.ds-caption.--color_white.--hover.--pointer(@click='getGeoClick(field.link, field.string, items.title, {  page: 1, subdivision: subdId,}, items.id)') {{items.title}}
                +b.UL.block-results-search(v-if='zips.length > 1 && field.string == "zip"')
                  +b.LI.block(v-for='items in zips.slice(0, 10)')
                    +e.fdgdf.ds-caption.--color_white.--hover.--pointer(@click='getGeoClick(field.link, field.string, items.title, {  page: 1, city: cityId}, items.id)') {{items.title}}
          +b.av_space_mt--8
            +b.g-row.--justify_center.--justify_end-sm
              +b.g-cell.g-cols
                +b.BUTTON.av_button--main.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('send') }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  searchCountry,
  addAddress,
  city,
  searchZip,
  searchGeo,
  // getContext,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/message-modal'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  props: {
    retailer: Number,
    promise: Function,
    add: Array,
  },
  data() {
    return {
      typeAddress: '',
      shipping: '',
      billing: '',
      text: 'text',
      context: {},
      nonFieldErrors: '',
      provinces: [],
      arrayForItems: [],
      cities: [],
      fal: 'false',
      zips: [],
      nameProvince: '',
      nameCity: '',
      nameInp: '',
      subdId: 0,
      cityId: 0,
      id: 0,
      fields: [
        {
          name: 'address_one',
          placeholder: this._('Street Address'),
          cols: '6-sm',
          label: '',
          events: '',
        },
        {
          name: 'address_two',
          placeholder: this._('Street Address Line 2'),
          cols: '6-sm',
          label: '',
          events: '',
        },
        {
          link: searchCountry,
          name: 'subdivision',
          placeholder: this._('Province'),
          cols: '4-sm',
          type: 'text',
          label: '',
          tag: 'd-control-input',
          string: 'province',
          events: {
            click: $event => this.getData($event,
              searchCountry,
              {
                search: this.form.subdivision,
                page: 1,
              },
              'province'),
            keyup: $event => this.getGeo($event,
              this.provinces,
              this.form.subdivision,
              searchCountry,
              {
                search: this.form.subdivision,
                page: 1,
              },
              'province'),
            // keyup: $event => this.getCountry($event),
          },
          value: '',
        },
        {
          link: city,
          name: 'city',
          placeholder: this._('City'),
          cols: '4-sm',
          label: '',
          type: 'text',
          tag: 'd-control-input',
          string: 'city',
          events: {
            click: $event => this.getData($event,
              city,
              {
                subdivision: this.subdId,
                search: this.form.city,
                page: 1,
              },
              'city'),
            keyup: $event => this.getGeo($event,
              this.cities,
              this.form.city,
              city,
              {
                subdivision: this.subdId,
                search: this.form.city,
                page: 1,
              },
              'city'),
          },
        },
        {
          link: searchZip,
          name: 'postal_code',
          placeholder: this._('Postal Code'),
          cols: '4-sm',
          label: '',
          type: 'text',
          tag: 'd-control-input',
          string: 'zip',
          events: {
            click: $event => this.getData($event,
              searchZip,
              {
                city: this.cityId,
                search: this.form.postal_code,
                page: 1,
              },
              'zip'),
            keyup: $event => this.getGeo($event,
              this.zips,
              this.form.postal_code,
              searchZip,
              {
                city: this.cityId,
                search: this.form.postal_code,
                page: 1,
              },
              'zip'),
          },
        },
        {
          placeholder: this._('type of delivery'),
          name: 'type',
          cols: '12',
          tag: 'd-control-multiselect',
          props: {
            options: this.add,
            trackBy: 'display_name',
            label: 'display_name',
          },
        },
      ],
      form: {
        type: '',
        retailer: this.retailer,
        address_one: '',
        address_two: '',
        postal_code: this.nameInp,
      },
    }
  },
  // watch: {
  //   provinces(nval) {
  //     if (nval.length === 0) {
  //       this.cities = []
  //       this.zips = []
  //       this.form.subdivision = ''
  //       this.form.city = ''
  //       this.form.postal_code = ''
  //     }
  //   },
  //   cities(nval) {
  //     if (nval.length === 0) {
  //       this.zips = []
  //       this.form.city = ''
  //       this.form.postal_code = ''
  //     }
  //   },
  // },
  methods: {
    close() {
      this.$emit('close')
    },
    clearInput(name) {
      if ('subdivision' === name) {
        this.form.subdivision = ''
        this.form.city = ''
        this.form.postal_code = ''
      } else if ('city' === name) {
        this.form.city = ''
        this.form.postal_code = ''
      } else {
        this.form.postal_code = ''
      }
    },
    /* eslint-disable no-empty-pattern */
    getLocation(value) {
      const {} = (searchGeo.execute({
        search: value,
      }).then(res => {
        this.nameCity = res.data[0].city.title
        this.nameProvince = res.data[0].subdivision.title
      }))
    },
    getData(event, link, toSend, to) {
      const {} = (link.execute({
        ...toSend,
      }).then(res => {
        if ('province' === to) {
          this.provinces = res.data.items
        } else if ('city' === to) {
          this.cities = res.data.items
        } else {
          this.zips = res.data.items
          // if (1 === this.zips.length) {
          //   this.id = this.zips[0].id
          //   this.form.postal_code = this.zips[0].title
          //   this.getLocation(this.zips[0].title)
          // }
        }
      }))
    },
    /* eslint-disable no-constant-condition */
    getGeo(event, array, input, link, toSend, to) {
      if ('ArrowRight' || 'ArrowUp' || 'ArrowLeft' || 'ArrowDown' !== event.code) {
        this.arrayForItems = array
        if (0 === input.length) {
          this.arrayForItems = []
        }
        if (0 < input.length) {
          const {} = (link.execute({
            ...toSend,
          }).then(res => {
            if ('province' === to) {
              this.provinces = res.data.items
            } else if ('city' === to) {
              this.cities = res.data.items
            } else {
              this.zips = res.data.items
            }
            this.arrayForItems = res.data.items
            if (1 === this.arrayForItems.length) {
              if ('province' === to) {
                this.subdId = this.arrayForItems[0].id
                this.provinces = res.data.items
              } else if ('city' === to) {
                this.cityId = this.arrayForItems[0].id
                this.cities = res.data.items
              } else {
                this.id = this.arrayForItems[0].id
                this.zips = res.data.items
                this.getLocation(this.arrayForItems[0].title)
              }
            }
          }))
        }
      }
    },
    getGeoClick(link, string, name, toSend, id) {
      if ('province' === string) {
        this.form.subdivision = name
        const {} = (link.execute({
          ...toSend,
          search: this.form.subdivision,
        }).then(res => {
          this.provinces = res.data.items
          this.subdId = id
        }))
      } else if ('city' === string) {
        this.form.city = name
        const {} = (link.execute({
          ...toSend,
          search: this.form.city,
        }).then(res => {
          this.cities = res.data.items
          this.cityId = id
        }))
      } else {
        this.form.postal_code = name
        const {} = (link.execute({
          ...toSend,
          search: this.form.city,
        }).then(res => {
          this.zips = res.data.items
          this.id = id
        }))
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.nonFieldErrors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.typeAddress = data.type.value
      delete data.type
      data.type = this.typeAddress
      // закинуть data.type.value
      // удалить объкт тайп
      // добавить ключ значение тайп
      this.$refs.validator.reset()
      return addAddress.execute({}, data).then(res => {
        this.promise(true)
        this.$emit('close')
        const message = {
          message: {
            title: res.meta.message.header,
            text: res.meta.message.text,
          },
        }
        AnswerMessage(message, this)
      })
    },
  },
}
</script>
