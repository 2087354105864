<template lang="pug">
  include /mixins
  div(
    v-bem=''
    :class='inputClass'
  )
    component(
      :is="element",
      :value.prop='value',
      ref='input'
      v-bem.element=''
      v-on="listeners"
      :id='id',
      v-bind='$attrs'
      :type='type'
      v-model='model',
    )
      template(
        v-slot:[name]="binded"
        v-for="(name, key) in eSlots"
      )
        slot(
          v-bind="binded"
          :name="key"
        )
    component(
      :is='labelComponent',
      :id='id',
      :value='value',
      :labelText='labelText',
      :input-label='inputLabel',
      :is-required='required'
    )
    +b.change-type.mdi.A(
      href='',
      tabindex='-1'
      v-if='type == "password" '
      @click.prevent='changeType()',
      :class='show ? "icon-close-pass" : "icon-open-pass"'
    )
</template>

<script>

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'inputClass',
    'labelComponent',
    'id',
    'value',
    'labelText',
    'inputLabel',
    'required',
    'providerContext',
    'type',
    'element',
  ],
  block: 'd-control-input',
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
    },
  },
  data() {
    return {
      input: '',
      show: false,
    }
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
    listeners() {
      return {
        ...this.$listeners,
        input: $event => this.$emit('input', this.getEvent($event)),
      }
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  methods: {
    getEvent($event) {
      if ($event.timeStamp) {
        return $event.target[this.getKey()]
      }
      return $event
    },
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
  },
}
</script>
