<template lang="pug">
  include /mixins
  +b.form-in-img
    +b.block-close
      //- +b.g-row.--justify_end
      //-   +b.g-cell.g-cols
      //-     +e.SPAN.text.ds-caption.--size_sm.--letter-space-small {{ _('Close')}}
    +b.wrap-title-form
      +b.H1.ds-caption.--appearance_uppercase.--letter-space-small.--size_3xl.--weight-regular {{ _('Create your password')}}
    +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
    +b.VALIDATION-OBSERVER(
      @submit.prevent='send',
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      +b.wrap-input-with-icon.relative.another-error(v-for='field in fields')
        +b.D-CONTROL-INPUT.follow-input(
          v-bem:d-control-input="{'variant': 'black'}"
          :key='field.name'
          :name='field.name'
          :rules='field.rules'
          :type='field.type || "text"'
          :placeholder='field.placeholder'
          v-model='form[field.name]')
      +b.av_space_mt--9
        +b.wrap-button-form.--second
          +b.g-row.--justify_center
            +b.g-cell.g-cols--12
              +b.BUTTON.av_button--main.--size-second.ds-caption.--variant_uppercase.--color_white(@click.prevent='prepareData(valid)') {{ _('save password') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  changePasswordFirst,
} from '@api/communication.service'

export default {
  name: 'SearchForm',
  mixins: [FormMixin],
  data() {
    return {
      code: '',
      token: '',
      nonFieldErrors: [],
      fields: [
        {
          name: 'new_password1',
          placeholder: this._('Enter your password'),
          rules: 'required',
          type: 'password',
        },
        {
          name: 'new_password2',
          placeholder: this._('Repeat your password'),
          rules: 'required',
          type: 'password',
        },
      ],
      form: {
        new_password1: '',
        new_password2: '',
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      console.log(parsed);
      this.nonFieldErrors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send() {
      this.$refs.validator.reset()
      this.form.uid = this.code
      this.form.token = this.token
      return changePasswordFirst.execute({}, this.form).then(res => {
        if (200 === res.code) {
          window.location.pathname = res.data.item.redirect_url
        }
      })
    },
  },
  mounted() {
    const link = window.location.href
    const splitLink = link.split('/')
    this.token = splitLink[splitLink.length - 3]
    this.code = splitLink[splitLink.length - 4]
  },
}
</script>
