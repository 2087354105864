var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({class:[{'inner-zoom-wrapper': true}, {'zoom': _vm.isZoomed}]},{
      touchstart: _vm.isZoomed ? () => {} : _vm.handleTouchStart,
      click: _vm.handleClick,
      mouseenter: _vm.isTouch ? () => {} : _vm.handleMouseEnter,
      mousemove:
        _vm.currentMoveType === 'drag' || !_vm.isZoomed ? () => {} : _vm.handleMouseMove,
      mouseleave: _vm.isTouch ? () => {} : _vm.handleMouseLeave,
    }),[_c('figure',{ref:"img",staticClass:"iiz",class:{
      [_vm.className]: _vm.className,
      'iiz--drag': _vm.currentMoveType === 'drag',
    },style:({
      width: `${_vm.width}px`,
    })},[_c('div',{style:({
        paddingTop: _vm.createSpacer ? `${(_vm.height / _vm.width) * 100}%` : null,
      })},[(_vm.validSources)?[_c('picture',[_vm._l((_vm.validSources),function(source,i){return _c('source',{key:i,attrs:{"srcSet":source.srcSet,"sizes":source.sizes,"media":source.media,"type":source.type}})}),_c('img',{staticClass:"iiz__img",class:{
              'iiz__img--hidden': _vm.isZoomed,
              'iiz__img--abs': _vm.createSpacer,
            },style:({
              transition: `linear 0ms opacity ${
                _vm.isZoomed ? _vm.fadeDuration : 0
              }ms, linear 0ms visibility ${_vm.isZoomed ? _vm.fadeDuration : 0}ms`,
            }),attrs:{"src":_vm.src,"srcSet":_vm.srcSet,"sizes":_vm.sizes,"alt":_vm.alt}})],2)]:[_c('img',{staticClass:"iiz__img",class:{
            'iiz__img--hidden': _vm.isZoomed,
            'iiz__img--abs': _vm.createSpacer,
          },style:({
            transition: `linear 0ms opacity ${
              _vm.isZoomed ? _vm.fadeDuration : 0
            }ms, linear 0ms visibility ${_vm.isZoomed ? _vm.fadeDuration : 0}ms`,
          }),attrs:{"src":_vm.src,"srcSet":_vm.srcSet,"sizes":_vm.sizes,"alt":_vm.alt}})]],2),(_vm.isActive)?[(_vm.isFullscreen)?[_c('teleport',{attrs:{"to":"body"}},[_c('div',{staticClass:"iiz__zoom-portal"},[_c('img',_vm._g({staticClass:"iiz__zoom-img",class:{ 'iiz__zoom-img--visible': _vm.isZoomed },style:({
                top: `${_vm.top}px`,
                left: `${_vm.left}px`,
                transition: `linear ${
                  _vm.isFullscreen ? 0 : _vm.fadeDuration
                }ms opacity, linear ${
                  _vm.isFullscreen ? 0 : _vm.fadeDuration
                }ms visibility`,
              }),attrs:{"alt":"","draggable":false,"src":_vm.zoomSrc || _vm.src}},{
                load: _vm.handleLoad,
                touchstart: _vm.handleDragStart,
                touchend: _vm.handleDragEnd,
                mousedown: _vm.handleDragStart,
                mouseup: _vm.handleDragEnd,
                click: _vm.handleClick,
              })),(_vm.isTouch && !_vm.hideCloseButton)?_c('button',{staticClass:"iiz__btn iiz__close",class:{ 'iiz__close--visible': _vm.isZoomed },style:({
                transition: `linear ${
                  _vm.isFullscreen ? 0 : _vm.fadeDuration
                }ms opacity, linear ${
                  _vm.isFullscreen ? 0 : _vm.fadeDuration
                }ms visibility`,
              }),attrs:{"type":"button","aria-label":"Zoom Out"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClose.apply(null, arguments)}}}):_vm._e()])])]:[_c('img',_vm._g({staticClass:"iiz__zoom-img",class:{ 'iiz__zoom-img--visible': _vm.isZoomed },style:({
            top: `${_vm.top}px`,
            left: `${_vm.left}px`,
            transition: `linear ${
              _vm.isFullscreen ? 0 : _vm.fadeDuration
            }ms opacity, linear ${
              _vm.isFullscreen ? 0 : _vm.fadeDuration
            }ms visibility`,
          }),attrs:{"alt":"","draggable":false,"src":_vm.zoomSrc || _vm.src}},{
            load: _vm.handleLoad,
            touchstart: _vm.handleDragStart,
            touchend: _vm.handleDragEnd,
            mousedown: _vm.handleDragStart,
            mouseup: _vm.handleDragEnd,
          })),(_vm.isTouch && !_vm.hideCloseButton)?_c('button',{staticClass:"iiz__btn iiz__close",class:{ 'iiz__close--visible': _vm.isZoomed },style:({
            transition: `linear ${
              _vm.isFullscreen ? 0 : _vm.fadeDuration
            }ms opacity, linear ${
              _vm.isFullscreen ? 0 : _vm.fadeDuration
            }ms visibility`,
          }),attrs:{"type":"button","aria-label":"Zoom Out"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClose.apply(null, arguments)}}}):_vm._e()]]:_vm._e(),(!_vm.isZoomed && !_vm.hideHint)?_c('span',{staticClass:"iiz__btn iiz__hint"}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }