import { baseAPI } from '@/js/resource/api.js'

export default {

  CABINET: {
    FAVORITE_LIST: baseAPI('/ajax/favorites/'),
    FAVORITE_PRODUCT: baseAPI('/{label}'),
  },
  CATALOG: {
    CATALOG_LIST: baseAPI('/catalog/{label}/'),
    CATALOG_PRODUCT: baseAPI('/product/'),
  },
}
