import installer from '@aspectus/vue-route-installer'
import { accessGuard } from '@aspectus/vue-router-permissions'
import { prefixLanguage } from '@utils/urls';

import {
  createRoutes as createUserRoutes,
} from './routes'

import {
  route,
  prefixRoutes,
} from '../../Routing/index.js'

const CabinetInstaller = () => import('../../Routing/index')
const Cabinet = () => import('../LK/PersonalArea')

// const BASE_CABINET = 'client/'

export function createRoutes(options) {
  const { Vue } = options
  return [
    prefixRoutes(prefixLanguage(''), createUserRoutes(options), {}),
    route('', installer(Cabinet, CabinetInstaller, Vue), {

      // redirect: {
      //   name: DASHBOARD_VIEW_NAME,
      // },
    }),
  ]
}

export function scrollBehavior(to, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
}

export function createRouter(options = {}) {
  const { Router, Vue } = options;
  const router = new Router({
    mode: 'history',
    props: true,
    base: '/',
    scrollBehavior,
    routes: createRoutes({ Router, Vue }),
  });

  router.beforeEach(accessGuard(options));

  return router;
}
