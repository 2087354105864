import {
  getColorsTest,
} from '@api/getItems.service'

import Vue from 'vue'

export const colors = {
  namespaced: true,
  state: {
    colorsOfProduct: {},
  },
  actions: {
    getAndCheckColors(context, { id }) {
      if (!context.state.colorsOfProduct[id]) {
        getColorsTest.execute({ id }).then(res => {
          const { data: { item } } = res
          context.commit('setCurrentColors', { attrs: item, id })
        })
      }
    },
  },
  mutations: {
    setCurrentColors: (state, { attrs, id }) => {
      Vue.set(state.colorsOfProduct, id, attrs)
    },
  },
}
