import { renderSlim } from '@aspectus/vue-utils';

export default {
  name: 'infinite-template-results-collector',

  props: {
    items: {},
    pagination: {},
  },

  data() {
    return {
      cache: '',
      isSSR: true,
    };
  },

  watch: {
    items(nval) {
      this.itemsReceived(nval);
    },
  },

  computed: {
    collected() {
      return `<div class="row row-for-help">${this.cache}</div>`;
    },
  },

  methods: {
    itemsReceived(items) {
      // const firstPageOffset = Number(window.catalogOffset) * 2
      if (0 === this.pagination.offset) {
        this.isSSR = false
        this.cache = ''
      }

      if (items && items.length) {
        this.cache = `${this.cache} ${items}`
      }
    },
  },

  render(h) {
    return renderSlim([this.$scopedSlots.ssr({
      isSSR: this.isSSR,
    }), this.$scopedSlots.default({
      items: this.collected,
    })], h, 'tag');
  },
};
