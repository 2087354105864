<template lang="pug">
  include /mixins
  +b.A.block-link(
    :href='url'
  )
    +e.SPAN.ic.icon-arrow-long
    +e.SPAN.ic.icon-arrow-small
    +e.SPAN.text.ds-caption.--size_sm {{ _('Back to catalog')}}
</template>

<script>

export default ({
  props: {
    link: String,
  },
  data() {
    return {
      url: this.link,
    }
  },
  mounted() {
    console.log(localStorage.getItem('url'), window.location.origin)
    if (sessionStorage.getItem('url') && -1 !== sessionStorage.getItem('url').indexOf('/catalog/') && -1 !== sessionStorage.getItem('url').indexOf('/filters/')) {
      this.url = sessionStorage.getItem('url')
      if (-1 !== window.location.href.indexOf('/fr/')) {
        console.log(this.url, -1)
        this.url = this.url.replace(window.location.origin, '')
        if (-1 === `${window.location.origin}/fr${this.url}`.indexOf('/fr/fr/')) {
          this.url = `${window.location.origin}/fr${this.url}`
        }
      } else {
        console.log(this.url, -2)
        this.url = `${this.url}`.replace('fr/', '')
      }
    } else {
      /* eslint-disable no-lonely-if */
      if ('en' === window.language) {
        this.url = `${window.location.origin}/catalog/`
      } else {
        this.url = `${window.location.origin}/fr/catalog/`
      }
    }
  },
})
</script>
