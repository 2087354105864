<template lang="pug">
  include /mixins
  div
    +b.g-row(v-for='i in characteristicVariant')
      +b.g-cell.g-cols(v-for='item in i.attrs')
        +b.tech-info
          +e.IMG.img-tech(:src='item.image')
          +e.SPAN.text.ds-caption.--size_2xs
            +e.P(v-for='it in item.choices') {{it.title}}
</template>

<script>

import {
  getColors,
} from '@api/getItems.service'

export default {
  props: {
    id: Number,
  },
  data() {
    return {
      characteristicVariant: [],
      attributesVariant: [],
      imagesVariant: [],
      newArrayAttributes: [],
    }
  },
  mounted() {
    return getColors.execute({ id: this.id }).then(res => {
      this.characteristicVariant = res.data.item.main_group_attrs.group_attributes
      this.getAttributes(this.characteristicVariant)
    })
  },
  methods: {
    getAttributes(array) {
      array.forEach(element => {
        element.attrs.forEach(elem => {
          this.imagesVariant.push(elem.image)
          elem.choices.forEach(el => {
            this.attributesVariant.push(el.title)
          })
        })
      });
      this.attributesGrouping(this.attributesVariant)
    },
    attributesGrouping(array) {
      for (let i = 0; i < array.length; i++) {
        array[i] = { size: array[i] }
      }
      for (let i = 0; i < array.length; i++) {
        array[i].img = this.imagesVariant[i]
      }
      this.newArrayAttributes = array
    },
  },
}
</script>
