<template lang="pug">
  include /mixins
  +b.instafeed#instafeed
</template>

<script>
import Instafeed from 'instafeed.js'

export default {
  name: 'InstagramCards',

  data() {
    return {}
  },
  mounted() {
    console.log(Instafeed);
    const feed = new Instafeed({
      accessToken: 'IGQVJWU2lmcWEyWkRzZAmY4OVRvQXNlaGl0dUZArU1JIRWJKV3FZAMnhlOUl4c0k1WE1lVTQwMWs4UThVSkZAoQ2RFOTdpMzNqSzZAxcjZAiaV9rTEIzSVpnYWNZAdWlXT2pWdHZAYczY5ajdKbHI2WWctSWNGZAAZDZD',
    });
    feed.run();
  },
}
</script>
